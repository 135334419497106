import * as React from "react";
import { Link } from "react-router-dom";
// import { IntrestSubmition } from "./submitionform";
import "./main.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import { MdChevronLeft, MdChevronRight } from "react-icons/md";
//import { Color } from "@progress/kendo-drawing";
// import {facultyapp} from "./MobileApplicationImages/Faculty App.png"


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

// const arrowStyle = {
//   background: "transparent",
//   border: 0,
//   color: "#000",
//   fontSize: "80px",
//   display: "flex",
// };

// const CustomRight = (props: any) => (
//   <button
//     type="button"
//     aria-label="Go to next slide"
//     className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right arrow right"
//     onClick={props?.onClick}
//     style={arrowStyle}
//   >
//     <MdChevronRight style={{ fontSize: "50px" }} />
//   </button>
// );

// const CustomLeft = (props: any) => (
//   <button
//     aria-label="Go to previous slide"
//     className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left arrow left"
//     type="button"
//     onClick={props?.onClick}
//     style={arrowStyle}
//   >
//     <MdChevronLeft style={{ fontSize: "50px" }} />
//   </button>
// );

function MainBody() {
  const [studentShow, setStudentShow] = React.useState(true)
  const [facultyShow, setFacultyShow] = React.useState(false)
  const [parentShow, setParentShow] = React.useState(false)
  const [adminShow, setAdminShow] = React.useState(false)



  function student() {
    setStudentShow(true)
    setFacultyShow(false)
    setParentShow(false)
    setAdminShow(false)
  }
  function faculty() {
    setStudentShow(false)
    setFacultyShow(true)
    setParentShow(false)
    setAdminShow(false)

  }
  function admin() {
    setStudentShow(false)
    setFacultyShow(false)
    setParentShow(false)
    setAdminShow(true)
  }

  function parent() {
    setStudentShow(false)
    setFacultyShow(false)
    setParentShow(true)
    setAdminShow(false)
  }
  // render() {
  const imgWidth = "85%";
  return (
    <div className="mainbodystart2">
      <div className="homePageFirstContainer">
        <div className="mcqMockupTextContainer">
          <h1 id="mcqMockupText">
            <span>
              <u>
                India&apos;s Leading Mobile App for all Competitive Exam Preparation and Digital Learning
              </u>
              <br />
            </span>
          </h1>

          <div>
            {/* <h3>India&apos;s Leading Mobile App for all Competitive Exam Preparation and Digital Learning</h3> */}
            <p id="mcqMockupText1" style={{ marginTop: "25px", fontSize: "18px" }}>
              Prolaskool App is India&apos;s leading mobile e-learning platform for Institutions,
              Coaching Centers, and Corporates to host MCQs mock tests for all kinds of competitive
              exams and other educational digital courses.
            </p>
          </div>
          <div id="makeAppointmentBtnContainer">
            <Link to='/contact' style={{ textDecoration: 'none', paddingLeft:0}}>
              <button id="makeAppointmentBtn"> Try Prolaskool Now </button>
            </Link>
          </div>
        </div>

        <div className="col2">
          {/* src={require("./Assets/carousel/img_1.png")} */}
          {/* images/lappy.jpg */}
          <img
            src={require("./HomepageImage.png").default}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>

      <div>
        <p style={{ textAlign: "center", fontSize: "18px", marginTop: "30px" }}>
          {/* <b> */}
          India&apos;s leading digital learning app, <br/> trusted by
          Institutions and corporates 
          across 
          the world.
          {/* </b> */}
        </p>
      </div>
      {/* <div className="instiLogo" style={{display: "block", marginLeft: "auto", marginRight: "auto"}}> */}
      {/* <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("./pareeksha logo.jpg").default} */}

      
      {/* </div> */}


      {/* <ul
        className="galleries"

      >
        <li id="institutionsLogos">
          <div id="institutionsLogos"></div>
          <img className="InstituteLogo" src={require("./pareeksha logo.jpg").default} width="100%" height="100%" style={{ marginTop: "30px" , marginLeft:"30%" }}
          />
        </li>
        <li id="institutionsLogos">
          <div id="institutionsLogos"></div>
          <img src={require("./logo.jpg")} width="100%" height="100%" style={{ marginTop: "30px" }}
          />
        </li>
      </ul> */}


      {/* <p style={{ textAlign: "center", marginTop: "70px", fontWeight: "bold", fontSize: "30px" }}>How does Prolaskool Work?<br /></p>
      <div className="howItWorks" >
        <p style={{ textAlign: "left", fontSize: "15px" }}>
          <ul style={{listStyleType: "square;", fontFamily:"Quicksand", fontWeight:"bold", padding: "0"}}>
            <li>Signup with Prolaskool and access the dashboard</li>
            <li>Imitate the class practice through different learning methods</li>
            <li>Prepare for MCQs mock test on topic level</li>
            <li>The unit test will be conducted for students through the app on chapter level </li>
            <li>Faculty can schedule tests accessible to candidates based on subject level</li>
            <li>Evaluate results based on easy-to-read statistics</li>
            <li>Career guides offer info on applying, registering, preparing, and more.</li>
          </ul>
          {/* <div id="makeAppointmentBtnContainer" > *
            <Link to='/contact' style={{ textDecoration: 'none' }}>
              <button id="makeAppointmentBtn" > Try Prolaskool Now </button>
            </Link>
          </div>
        </p>
      </div> */}
      
      
      <div id="InstituteLogos">
        {/* <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("./pareeksha logo.jpg").default} /> */}
        <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto", paddingBottom: "3%", paddingTop: "3%"}} src={require("./india.png").default} />
        <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto", paddingBottom: "5%", paddingTop: "7%"}} src={require("./AkshayaEducationLogo.png").default} />
      </div>
      <div className="onlineBookingContent" style={{ padding: "3%" }}>
        <div className="onlineBookingTextContent">
          <h2 style={{ textAlign: "center", fontFamily: 'Quicksand', fontWeight: 700 }}>
            How does Prolaskool Work?
          </h2><br />
          <ul style={{ listStyleType: "square", fontFamily: "Quicksand", fontWeight: "bold", padding: "0" }}>
            <li>Signup with Prolaskool and access the dashboard</li>
            <li>Imitate the class practice through different learning methods</li>
            <li>Prepare for MCQs mock test on topic level</li>
            <li>The unit test will be conducted for students through the app on chapter level </li>
            <li>Faculty can schedule tests accessible to candidates based on subject level</li>
            <li>Evaluate results based on easy-to-read statistics</li>
            <li>Career guides offer info on applying, registering, preparing, and more</li>
          </ul>
          <div id="makeAppointmentBtnContainer2" style={{textAlign: "center"}} >
            <Link to='/contact' style={{ textDecoration: 'none' }}>
              <button id="makeAppointmentBtn" > Try Prolaskool Now </button>
            </Link>
          </div>
        </div>

      </div>


      <p style={{ textAlign: "center", marginTop: "20px", fontWeight: "bold", fontSize: "30px" }}>Why Choose Prolaskool App?<br /></p>
      <p style={{ textAlign: "center", fontSize: "15px" }}>At Prolaskool, we strive to provide a cutting-edge platform for education<br /> enthusiasts
        and make their learning experience interactive<br /> and efficient with a career-oriented approach. </p>


        <div className="main-features">
        
        <div className="row">
        
          <div id="cardAlignment" className="column" style={{paddingTop:"30px",display:'inline'}} >
            <div className="feature-container">
              <div className="feature-card">
                <div className="card-body" style={{textAlign:'left'}}>
                <img className="centerImage" src={require("../../assets/images/mock_test.png").default}  alt="online_exam_test" />
                  <h3 style={{textAlign:'center'}}><strong>One-Stop-Platform for Digital Learning</strong></h3>
                  <hr></hr>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Host self-branded learning materials</strong></h6>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Schedule test many times for students</strong></h6>
                  <h6><strong>&#10003; Evaluate student’s progress and performance</strong></h6>
                  <h6><strong>&#10003; Get statistical reports in easy-to-read graphs</strong></h6>
                </div>
                
              </div>
            </div>
          </div>

          <div id="cardAlignment" className="column" style={{paddingTop:"30px",display:'inline'}} >
            <div className="feature-container">
              <div className="feature-card">
                <div className="card-body" style={{textAlign:'left'}}>
                <img className="centerImage" src={require("../../assets/images/enrollment.png").default}  alt="online_exam_test" />
                  <h3 style={{textAlign:'center'}}><strong>Benefits for Students</strong></h3>
                  <hr></hr>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Access content even when offline</strong></h6>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Get the best course materials for preparation</strong></h6>
                  <h6><strong>&#10003; Monitor time and deliverables </strong></h6>
                  <h6><strong>&#10003; Access content in various languages and improve performance</strong></h6>
                </div>
              	
                
              </div>
            </div>
          </div>

          <div id="cardAlignment" className="column" style={{paddingTop:"30px",display:'inline'}} >
            <div className="feature-container">
              <div className="feature-card">
                <div className="card-body" style={{textAlign:'left'}}>
                <img className="centerImage" src={require("../../assets/images/results.png").default}  alt="online_exam_test" />
                  <h3 style={{textAlign:'center'}}><strong>Excellent User Experience</strong></h3>
                  <hr></hr>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Handy portal for institutions and students</strong></h6>
                  <h6 style={{paddingBottom:"2px"}}><strong>&#10003; Dynamic, efficient, and affordable platform</strong></h6>
                  <h6><strong>&#10003; Offer career guidance with the application</strong></h6>
                  <h6><strong>&#10003; Available on Google Play, Apple App Store, and the Windows Store</strong></h6>
                </div>
                
              </div>
            </div>
          </div>
</div>


</div>
      {/* <div className="FeatureBoxContainerForHome">

        <div className="FeatureBoxContent2ForHome">
          <div className="support12Home">
            <div style={{ width: "50px", height: "50px" }}>
              <img className="centerImage" src={require("../../assets/images/mock_test.png").default} alt="Online Mockup Test" />


            </div>

            <p>
              <br />
              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    // marginLeft: "36%",
                    fontFamily: "Quicksand",
                    textAlign: "center"

                  }}
                >
                  <b>One-Stop-Platform for Digital Learning</b>
                </span>
                <hr style={{ width: "100%" }}></hr>
              </p>
              <span
                style={{
                  fontSize: "17px",
                  // fontWeight: "bold",
                  fontFamily: "Quicksand",
                  lineHeight: "30px"
                }}
              >
                ➔	Host self-branded learning materials<br />
                ➔	Schedule test many times for students <br />
                ➔	Evaluate student’s progress and performance<br />
                ➔	Get statistical reports in easy-to-read graphs<br />

              </span>
            </p>
          </div>
          <div className="support12Home">
            <div style={{ width: "50px", height: "50px" }}>
              <img className="centerImage" src={require("../../assets/images/enrollment.png")} alt="Enroll" />
            </div>
            <p>
              <br />
              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    // marginLeft: "44%",
                    fontFamily: "Quicksand",
                    textAlign: "center"
                  }}
                >
                  <b>Benefits for Students </b>
                </span>
              </p>
              <hr style={{ width: "100%" }}></hr>
              <span
                style={{
                  fontSize: "16px",
                  // fontWeight: "bold",
                  fontFamily: "Quicksand",
                  lineHeight: "30px"
                }}
              >
                ➔	Access content even when offline<br />
                ➔	Get the best course materials for preparation <br />
                ➔	Monitor time and deliverables <br />
                ➔	Access content in various languages and improve performance.

              </span>
            </p>
          </div>
          <div className="support12Home">
            <div style={{ width: "50px", height: "50px" }}>
              <img className="centerImage" src={require("../../assets/images/results.png").default} alt="Results" />
            </div>
            <p>
              <br />

              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    // marginLeft: "41%",
                    fontFamily: "Quicksand",
                    textAlign: "center",
                  }}
                >
                  <b> Excellent User Experience</b>
                </span>
              </p>
              <hr style={{ width: "100%" }}></hr>

              {/* <br /> 
              <br />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
              >
                ➔	Handy portal for institutions and students <br />
                ➔	Dynamic, efficient, and affordable platform<br />
                ➔	Offer career guidance with the application <br />
                ➔	Available on Google Play, Apple App Store, and the Windows Store.

              </span>
            </p>
          </div>
        </div>
      </div> */}

      <div className="onlineBookingContent" style={{ padding: "5%" }}>
        <div className="onlineBookingTextContent">
          <h2 style={{ textAlign: "left", fontFamily: 'Quicksand', fontWeight: 700 }}>
            How to Access Prolaskool App?
          </h2><br />
          <img src={require("./rightmark.png").default} width="4%" height="20px" margin-left="-25px" alt="home" />	After onboarding, Institution will offer details to our admin.<br />
          <img src={require("./rightmark.png").default} width="4%" height="20px"margin-left="-25px" alt="home" />	The app will verify and send the credentials to admin’s email.<br />
          <img src={require("./rightmark.png").default} width="4%" height="20px"margin-left="-25px" alt="home" />	Admin will distribute logins details to students/parents/faculty.<br />
          <img src={require("./rightmark.png").default} width="4%" height="20px"margin-left="-25px" alt="home" />	Institutions can login to dashboard and start uploading content.<br />
          <img src={require("./rightmark.png").default} width="4%" height="20px" margin-left="-25px"alt="home" />	Students/parents/faculty can download the app and start using.
        </div>


        <div className="onlineBookingImage">
          {/* <div> */}
          <img src={require("./WhatClientsSaysAboutUs.png").default} width="100%" height="300px" alt="home" />
          {/* <img src="images/lappy.jpg" style="position:left; border-radius: 10px;"> */}
          {/* </div> */}
          {/* <div className="cards" style={{ float: "right" }}>
              <img src="images/proficon.png"/>
              <div style={{ float: "left", width: "100%" }}>
                <h3>Name Lorem</h3>
                <h3>Name Lorem</h3>
                <h3>Name Lorem</h3>
                <h3>Name Lorem</h3>
              </div>
            </div> */}
        </div>
      </div>

      <div id="memberListContainer">
        <h2 style={{ textAlign: "center" }}>
          Introduction
        </h2>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Catering to end-to-end solutions, Prolaskool Application offers a three-centered approach for institutions, students, faculty and parents.
        </p>
        <div id="memberList">
          <button
            onClick={admin} id={adminShow ? "memberButtonActive" : "memberButton"}>Admin App</button>
          <button
            onClick={student} id={studentShow ? "memberButtonActive" : "memberButton"}>Student App</button>
          <button
            onClick={faculty} id={facultyShow ? "memberButtonActive" : "memberButton"}>Faculty App</button>
          <button
            onClick={parent} id={parentShow ? "memberButtonActive" : "memberButton"}>Parent App</button>
        </div>
      </div>

      <div>
        {
          studentShow ?
            <div id="appDetailsImgContainer">
              <img src={require("./MobileApplicationImages/Student app (1).png").default} id="appDetailsImg" alt="App Image" style={{ marginTop: "40px", marginBottom: "40px" }} />
            </div>
            : parentShow ?
              <div id="appDetailsImgContainer">
                <img src={require("./MobileApplicationImages/Parent app.png").default}
                  //  id="appDetailsImg" 
                  style={{ width: '40%', height: "40%" }}
                  alt="App Image" />
              </div>
              : adminShow ?
                <div id="appDetailsImgContainer">
                  <img src={require("./MobileApplicationImages/Admin app.png").default} style={{ marginTop: "40px", marginBottom: "40px" }}
                    //  style={{marginTop:"50px", marginBottom:"50px",width:'100%',height:"100%"}} 
                    id="appDetailsImg"
                    alt="App Image" />
                </div>
                :
                <div id="appDetailsImgContainer">
                  <img src={require("./MobileApplicationImages/Faculty app (2).png").default} id="appDetailsImg" alt="App Image" style={{ marginTop: "40px", marginBottom: "40px" }} />
                </div>
        }

      </div>

      <div style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div>
          <h3 style={{ textAlign: "center", fontFamily: "Quicksand", fontWeight:"bold"}}>What beta clients say about us?</h3>
        </div>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          centerMode={false}
          // arrows
          additionalTransfrom={10}
          infinite={true}
          autoPlay={true}
          keyBoardControl
          autoPlaySpeed={1000}
          transitionDuration={1000}
          containerClass="carousel-container-1"
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          // customRightArrow={<CustomRight />}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        // customLeftArrow={<CustomLeft />}
        >

          <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
            <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
              <div id='carouselItemContainer' style={{}}>
                <div id='testimonialProfilePicContainer' >
                  <img src={require("./AkshayaEducation_Thanmay.jpeg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                </div>
                <div id="InstNameAndDesc">
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Thanmay</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Student</div>
                </div>
              </div>
              <div style={{ padding: "10px" }}>
                <p id='instituteTestimonialContent'>
                I had trouble getting online because I reside in a distant area. I&apos;m grateful for the Prolaskool platform, which provided me with access to offline learning resources, allowed me to bookmark the MCQs while I studied for the examinations and provided me with performance reports that allowed me to assess my strengths and shortcomings.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="carousel-item active">
            <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
              <div id='carouselItemContainer' style={{}}>
                <div id='testimonialProfilePicContainer' >
                  <img src={require("./AkshayaEducation_AnilKumar.png").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                </div>
                <div id="InstNameAndDesc">
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>N Anil kumar</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Institute Head</div>
                </div>
              </div>
              <div style={{ padding: "10px" }}>
                <p id='instituteTestimonialContent'>
                We were looking for an economical, dependable venue to introduce our challenging courses online. After experimenting with several apps, we discovered Prolaskool. The Prokaskool app provides an intuitive user interface and functions like scheduling multiple-choice questions, obtaining thorough performance data, and starting courses under institute-owned brand names. Prolaskook is not only an inexpensive but also a useful remedy. The platform is fantastic.
                  </p>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
            <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
              <div id='carouselItemContainer' style={{}}>
                <div id='testimonialProfilePicContainer' >
                  <img src={require("./AkshayaEducation_LalithDevi.png").default} width="100%" height="95%" style={{ borderRadius: "50%" }} alt="profile" />
                </div>
                <div id="InstNameAndDesc">
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Lalith Devi</div>
                  <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Faculty</div>
                </div>
              </div>
              <div style={{ padding: "10px" }}>
                <p id='instituteTestimonialContent'>
                The Prolaskool platform&apos;s user-friendly design and reliable app performance are what we appreciate most about it. We have the freedom to assign pupils unit assessments based on their subject-matter expertise to bring out the best in them thanks to the mobile app. Even when they are offline, our students can access recorded films and educational materials. This portal must be used by all students preparing for competitive exams.
                  </p>
              </div>
            </div>
          </div>

        </Carousel>

        <div id="seeMoreDiv">
          <Link to='/testimonial' style={{ textDecoration: 'none' }}>
              see more
          </Link>
        </div>
      </div>
      <div id="firstDivs">
        <div id="lookDiv">
            <p id="divContent"> DOWNLOAD APP</p>
            <h4 style={{paddingTop: "0px", margin: "inherit"}}>Take a Look</h4>
            
        </div>
        
        <div className="vl"></div>
        <div id="lookDiv2">
            
            <div id="widHeig">
                <a href='https://play.google.com/store/apps/details?id=ptapps.prolatech.prolaskool' target="_blank" rel="noreferrer"> 
                <img 
                  className="android" 
                  alt='Get it on Google Play' 
                  src={require("./store image.png").default} />
              </a>
              </div>
            {/* <!-- <hr> --> */}
            <div id="widHeig">
                <a href='https://apps.apple.com/in/app/prolaskool/id1600061221' target='_blank' rel="noreferrer"> 
                <img 
                  className="android" 
                  alt='Get it on Google Play' 
                  src={require("./apple.png").default} />
              </a>
              </div>

              <div id="widHeig">
                 <a href='https://www.microsoft.com/store/productId/9NH1MPZQQ06X' target='_blank' rel="noreferrer"> 
                <img 
                  className="android" 
                  alt='Get it on Google Play' 
                  src={require("./windows.png").default} />
              </a>
              </div>
            {/* <!-- <div id="widHeig">Third Div</div> --> */}
        </div>
    </div>
    </div>
  );
  // }
}
export { MainBody };
