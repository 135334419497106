import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { MdInfoOutline } from "react-icons/md";

import * as testActionCreators from "../../redux/test/test.actions";
import "./examQuestions.scss";
import { Timer } from "./timer";

class ExamHeaderComponent extends React.Component<any> {
  createdDateTime = new Date(this.props.createdDate);
  examTimer = this.props.examTimer;

  date = this.createdDateTime.toLocaleString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  render(): JSX.Element {
    return (
      <div className="test-details-container">
        <div className="exam-title-timer-container">
          <h2 className="exam-title">{this.props.examName} - {this.props.testName}</h2>
          <div className="timer-container">
            <div className="timer-title">
              <Timer
                examTimer={this.props.examTimer}
                history={this.props.history}
                setShowToast={this.props.setShowToast}
                showPageLoader={this.props.showPageLoader}
              />
            </div>
          </div>
        </div>

        <div className="test-details">
          <div className="test-detail">
            <h6 className="test-detail-title">Created Date :</h6>
            <p className="test-detail-value">{this.date}</p>
          </div>
          <div className="test-detail">
            <h6 className="test-detail-title">No. of Users Taken :</h6>
            <p className="test-detail-value">{this.props.noOfUsersTaken}</p>
          </div>
          <div className="test-detail">
            <h6 className="test-detail-title">No. of Questions :</h6>
            <p className="test-detail-value">{this.props.noOfQuestions}</p>
          </div>
        </div>
      </div>
    );
  }
}

const ExamHeader = connect(
  (state: any) => {
    return {
      createdDate: state.test.createdDate,
      noOfUsersTaken: state.test.noOfUsersTaken,
      noOfQuestions: state.test.noOfQuestions,
      timerDate: state.test.timerDate,
      instructions: state.test.instructions,
      isFreeTrial: state.test.isFreeTrial
    };
  },
  (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(ExamHeaderComponent);

export { ExamHeader };
