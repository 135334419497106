//CONTACT US PAGE
import * as  React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
import {Link} from 'react-router-dom'
import './about.scss'
import {   IntrestSubmition  } from '../OnlineMockupMobileAppComponents/submitionform'

class aboutUs extends React.Component<any> {
    public state: any = {};
    constructor(props) {
        super(props);
        this.state = {
          isToggleOn: false};
        this.handleClick=this.handleClick.bind(this);
      }
   
      

      handleClick(){
          this.setState((state: any) =>({
            isToggleOn:!state.isToggleOn
          }));
      }
        render()
        {
    return(
        <div style={{background:"black"}}>
            <div className="firstSheet1">

                <header>
                <a href="/" className="logo5" style={{ color: "white" }}>
                  <img src={require("../../assets/images/site-logo.png").default} height="130%" alt="logo" />
                </a>
                        <div className="menuToggle5"  onClick={this.handleClick}> 
                            <img className="magese5"src={require('../../assets/images/menu.png').default}
 width="80%" style={{paddingBottom:"10px"}}/>
                        </div>
                        <nav className={this.state.isToggleOn ? "active2" : null}>
                             <ul>
                                 <li><a href="#" className="active2"></a></li>
                                 {/* <Link to='/home' style={{textDecoration:'none'}}>
                                 <li><a href="#" style={{color:'black',fontFamily:"Quicksand"}}>Home AU</a></li>
                                 </Link> */}
                                 <li>
                                 <Link to='/home' style={{color:'black',fontFamily:"Quicksand"}}>
                                    Home
                                 </Link>
                                    </li>
                                 <li>
                                 <Link to='/aboutus' style={{color:'black',fontFamily:"Quicksand"}}>
                                  About Us
                                 </Link>
                                  </li>
                                 
                                 <li>
                                 <Link to='/onlineMockUp' style={{color:'black',fontFamily:"Quicksand"}}>
                                  Online Mockup
                                 </Link>
                                 </li>
                                 <li>
                                 <Link to='/testimonial' style={{color:'black',fontFamily:"Quicksand"}}>
                                  Testimonials
                                 </Link >
                                  </li>
                                  <li>
                                 <Link to='/contact' style={{color:'black',fontFamily:"Quicksand"}}>
                                    Contact Us
                              </Link>
                                  </li>
                                 {/* <Link to='/login' style={{textDecoration:'none',fontFamily:"Quicksand"}}>
                                 <li><a href="#" style={{color:'black'}}>Login</a></li>
                                 </Link> */}
                            </ul>
                        </nav>
                        <div className="clearfix5"></div>
                </header>
                <div className="FirstRow2">
          
                <div className="locationContainer1" style={{display:"flex", justifyContent:"center"}}>
                {/* <iframe className="iframecontent"  style={{frameBorder: 0, border:"0", allowFullscreen:"",  ariaHidden:"false", tabIndex:"0" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.7114365355574!2d77.51290771477008!3d12.92625899088559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f5743d79d15%3A0xe4015f44d989c397!2sProlatech!5e0!3m2!1sen!2sin!4v1623391983456!5m2!1sen!2sin" /> */}
            </div>
               
                <div className="backgroundRect">
                <IntrestSubmition  />
                </div>
                    
                 </div>
                
            </div>    
        </div>
    )
        }
}
const About = connect(
    (state: any) => {
      return {
        isAuthed: state.users.isAuthed,
        isFetching: state.users.isFetching,
        error: state.users.error,
      };
    },
    (dispatch) => bindActionCreators(userActionCreators, dispatch)
  )(aboutUs);
export {About}