// THIS IS USED IN HOME PAGE AND TESTIMONIALS
import * as  React from 'react';
// import menu from './Assets/menu.png'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
// import './navbar.scss';

 class NavbarTestimonial extends React.Component<any> {
    public state: any = {};
    constructor(props) {
        super(props);
        this.state = {
          isToggleOn: false};
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick() {
        this.setState((state: any) =>({
          isToggleOn:!state.isToggleOn
        }));
    }

    render() { 
      return (
            <div className="firstSheet123">
            <header>
                <a href="/" className="logo4" style={{ color: "white" }}>
                  <img src={require("../../assets/images/site-logo.png").default} height="130%" alt="logo" />
                </a>
                    <div className="menuToggle4"  onClick={this.handleClick}> 
                        <img className="magese4" src={require('../../assets/images/menu.png').default} width="80%" style={{paddingBottom:'10px'}}/>
                    </div>
                    <nav className={this.state.isToggleOn ? "active4" : null}>
                         <ul>
                             <li><a href="/" className="active4"></a></li>
                             {/* <Link to='/home' style={{textDecoration:'none'}}> */}
                             <li>
                             <Link to='/home' style={{color:"black",fontFamily:"Quicksand"}}>
                              {/* <a href="#" style={{color:"black",fontFamily:"Quicksand"}}> */}
                              Home
                              {/* </a> */}
                             </Link>
                              </li>
                             <li>
                             <Link to='/aboutus' style={{color:"black",fontFamily:"Quicksand"}}>
                              {/* <a href="#" style={{color:"black",fontFamily:"Quicksand"}}> */}
                                About Us
                                {/* </a> */}
                             </Link>
                                </li>

                             <li>
                             <Link to='/onlineMockUp' style={{color:"black",fontFamily:"Quicksand"}}>
                              {/* <a href="#"> */}
                                Online Mockup
                                {/* </a> */}
                             </Link>
                                </li>

                             <li>
                             <Link to='/testimonial' style={{color:"black",fontFamily:"Quicksand"}}>
                              {/* <a href="#" style={{color:"black",fontFamily:"Quicksand"}}> */}
                                Testimonials
                                {/* </a> */}
                             </Link>
                                </li>

                             
                             {/* <Link to='/' style={{textDecoration:'none'}}>
                             <li><a href="#">Contact Us</a></li>
                             </Link> */}
                             <li>
                              <Link to='/contact' style={{color:"black",fontFamily:"Quicksand"}}>
                              {/* <a href="#" style={{color:"black",fontFamily:"Quicksand"}}> */}
                                Contact Us
                                {/* </a> */}
                             </Link>
                                </li>
                             {/* <Link to='/login' style={{textDecoration:'none'}}>
                              <li><a href="#" style={{color:"black",fontFamily:"Quicksand"}}>Login</a></li>
                              </Link> */}
                        </ul>
                    </nav>
                    <div className="clearfix4"></div>
            </header>
            {/* <div className="StoryContainer">
                <p><span style={{color:"black",fontSize:"90px",fontWeight:"bold",fontFamily:"Quicksand"}}>STORY</span><br />
                <span style={{color:"black",fontSize:"30px",fontWeight:"bold",fontFamily:"Quicksand"}}>PROLASKOOL</span>
                </p>
            </div> */}
            </div>
    )
    }
}
const NavbarTestimo = connect(
    (state: any) => {
      return {
        isAuthed: state.users.isAuthed,
        isFetching: state.users.isFetching,
        error: state.users.error,
      };
    },
    (dispatch) => bindActionCreators(userActionCreators, dispatch)
  )(NavbarTestimonial);
export {NavbarTestimo}