import * as React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";

import * as testActionCreators from "../../redux/test/test.actions";
import "./examQuestions.scss";
import { ExamHeader } from "./examHeader";
import MathJax from "react-mathjax-preview";
import {Button, Form, Modal, Toast} from "react-bootstrap";
import axios from "axios";
// import { ITableComponentProps } from "../../components/table/Table.types";
import { SECTION_DIVISIONS } from "./constants";
import ReactStars from "react-rating-stars-component";
import {errors} from "../../utilities/errors";
import { base_url } from '../../config/constants';

class ExamQuestionsPageComponent extends React.Component<any> {
  public numberOfQuestionsAttempted = 0;
  public state: any = {};

  constructor(props){
    super(props);
    /**
     * Generate sectionState and section limit from the config.
     */
    const sections = SECTION_DIVISIONS.find(sec => sec.examId === props.currentExamId)?.subjects;
    const {sectionState, sectionLimit} = sections ? Object.keys(sections).reduce((acc, curr) => {
      const state = sections[curr].reduce((acc2, curr2) => {
        acc2[`${curr2.from}-${curr2.to}`] = curr2.sectionName;
        return acc2;
      }, {})

      const limit = sections[curr].reduce((acc2, curr2) => {
        if (curr2.limit) {
          acc2[`${curr}-${curr2.sectionName}`] = {set: new Set(), limit: curr2.limit, limitExceeded: false};
        }
        return acc2;
      }, {})

      acc.sectionState[curr] = {...acc.sectionState[curr], ...state}
      acc.sectionLimit = {...acc.sectionLimit, ...limit}
      return acc;
    }, {sectionState: {}, sectionLimit: {}}) : {sectionState: null, sectionLimit: null};

    this.state = {
      sectionState: sections ? sectionState : null,
      sectionLimit: sections ? sectionLimit : null,
      quickLookExpanded: false,
      selectedQuickLookGroup: null,
      totalQuickLookGroups: null,
      selectedQuestion: null,
      questionsState: null,
      examTimer: null,
      selectedOption: null,
      selectedAnswers: null,
      subjects: null,
      selectedSubject: null,
      performanceTimer: null,
      t0: null,
      showTimerToast: false,
      disableTimerToast: false,
      attemptedQuestionBatch: new Set(),
      activeSubHeading: null,
      ratings: null,
      comments: '',
      showSpinner: false,
      showWarningModal: false,
      errors: '',
      pageLoader: false
    }
  }

  handleVisibilityChange() {
    if (document.hidden) {
      this.setState({showWarningModal: true});
    }
  }

  async componentDidMount() {
    try {
      const examQuestionsResponse = await this.loadQuestions();
      const examQuestions= examQuestionsResponse.data.Data;
      const examTimer = new Date(new Date().getTime() + examQuestions?.duration - this.props.totalTimeTaken)//examQuestions;
      const questionsState = {};
      const initialSelectedQuestion = {};
      const initialSelectedQuickLookGroup = {};
      const totalGroups = {};
      const initialSelectedAnswers = {};  
      const initialSelectedOption = {};
      const initialQuestionsState = {};
      const initialPerformanceTimer = {};
      const sectionLimit = this.state.sectionLimit ? {...this.state.sectionLimit} : null;

      Object.keys(examQuestions).map((examKey) => {
        if (typeof examQuestions[examKey] === 'object' && examQuestions[examKey] !== null) {
          initialSelectedQuestion[examKey] = 1;
          initialSelectedQuickLookGroup[examKey] = 1;

          totalGroups[examKey] = Math.ceil(
            examQuestions[examKey].questions.length / 30
          );

          if (
            this.props.answers && Object.prototype.hasOwnProperty.call(this.props.answers, examKey)
          ) {
            initialSelectedAnswers[examKey] = this.props.answers[examKey];
            initialSelectedOption[examKey] = this.props.answers[examKey][0];

            //Set exceeded limit in case of resume and answers already taken.
            if (sectionLimit) {
              Object.keys(this.state.sectionState[examKey]).reduce((acc: any, curr: any) => {
                const [from, to] = curr.split('-');
                if (this.state.sectionLimit?.[`${examKey}-${this.state.sectionState[examKey][curr]}`]?.limit) {
                  const answersSet = this.props.answers[examKey]?.slice(from-1, to-1)?.reduce((acc, curr, i) => {
                    if(curr) {acc.push(parseInt(from) + i)}
                    return acc;
                  }, []);
                  sectionLimit[`${examKey}-${this.state.sectionState[examKey][curr]}`].set = new Set(answersSet);

                  if (sectionLimit[`${examKey}-${this.state.sectionState[examKey][curr]}`].set.size >= sectionLimit[`${examKey}-${this.state.sectionState[examKey][curr]}`].limit) {
                    sectionLimit[`${examKey}-${this.state.sectionState[examKey][curr]}`].limitExceeded = true;
                  }
                }
              }, {})
            }
          } else {
            initialSelectedAnswers[examKey] = Array(
              examQuestions[examKey].questions.length
            ).fill(null);
            initialSelectedOption[examKey] = null;
          }

          if (
            this.props.performanceTimer && Object.prototype.hasOwnProperty.call(this.props.performanceTimer, examKey)
          ) {
            initialPerformanceTimer[examKey] = this.props.performanceTimer[
              examKey
            ];
          } else {
            initialPerformanceTimer[examKey] = Array(
              examQuestions[examKey].questions.length
            ).fill(null);
          }

          questionsState[examKey] = examQuestions[examKey].questions.map(
            (question) => {
              question.answered = false;
              question.visited = false;

              return {
                ...question,
              };
            }
          );

          if ( this.props.questionsState && Object.prototype.hasOwnProperty.call(this.props.questionsState, examKey)) {
            initialQuestionsState[examKey] = this.props.questionsState[examKey];
          } else {
            initialQuestionsState[examKey] = questionsState[examKey];
          }
        }
      });

      const subjects = Object.keys(initialQuestionsState);
      const t0 = Number(performance.now().toFixed(2));
      this.props.updateInitialState(initialSelectedAnswers, initialQuestionsState, initialPerformanceTimer,
          examQuestions.isLastsession, examQuestions.sessionId);
      this.setState({
        selectedQuickLookGroup: initialSelectedQuickLookGroup,
        selectedQuestion: initialSelectedQuestion,
        selectedSubject: 1,
        totalQuickLookGroups: totalGroups,
        questionsState: initialQuestionsState,
        examTimer: examTimer,
        selectedAnswers: initialSelectedAnswers,
        selectedOption: initialSelectedOption,
        subjects: subjects,
        performanceTimer: initialPerformanceTimer,
        t0: t0,
        sectionLimit
      }, () => {
        this.updateActiveSubHeading();
        document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this), false);
      });
    } catch (error) {
      if (error.response) {
        const responseStatus = error.response.data.ResponseStatus;
        const errorMessage: any = errors.testQuestions[responseStatus].msg;
        this.setState({
          errors: <p style={{ color: "red" }}>{errorMessage}</p>,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.questionsState !== null &&
      // prevState not null
      prevState &&
      // If previous question number is not equal to the new question number and if the subjects are different
      prevState.selectedQuestion &&
      (prevState.selectedQuestion[
        prevState.subjects[prevState.selectedSubject - 1]
      ] !==
        this.state.selectedQuestion[
          this.state.subjects[this.state.selectedSubject - 1]
        ] ||
        prevState.subjects[prevState.selectedSubject - 1] !==
          this.state.subjects[this.state.selectedSubject - 1])
    ) {
      const t0 = Number(performance.now().toFixed(2));
      this.setState({
        t0: t0,
      });
    }

    //Redirect to exam submitted once rating submitted.
    if (this.props.ratingsSubmitted) {
      this.redirectToExamSubmitted();
    }

    if (this.props.isLastSession === 0 && this.props.examSubmitted) {
      this.redirectToExamSubmitted();
    }

    if (this.props.submitFailedMsg && prevProps.submitFailedMsg !== this.props.submitFailedMsg) {
      this.setState({
        errors: <p style={{ color: "red" }}>{this.props.submitFailedMsg}</p>,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange, false);
  }

  showPageLoader = () => {
    this.setState({
      pageLoader: true
    })
  }

  loadQuestions() {
    const currentTestId = this.props.currentTestId;
    const currentExamId = this.props.currentExamId;
    const authToken = this.props.user.authToken;
    const userId = this.props.user.uid;

    return axios.post(`${base_url}/api/Client/GetTestQuestionsDetails`,
        {
          AuthToken: authToken,
          ExamId: currentExamId,
          UserId: userId,
          TestId: currentTestId
        }
    );
  }

 
  checkRadioButtonChecked = (optionNumber, subject) => {
    if (this.state.selectedOption[subject] === optionNumber) {
       return true;
    } else if (this.state.selectedOption[subject] === null) {
      return false;
    } else {
      return false;
    }
  };

  setShowToast = (val) => {
    if(!this.state.disableTimerToast) {
      this.setState({
        showTimerToast: val,
        disableTimerToast: true
      });
    }
  };

  renderToast = () => {
    return (
      <Toast
        onClose={() =>
          this.setState({
            showTimerToast: false,
          })
        }
        show={this.state.showTimerToast}
        delay={8000}
        autohide
        className="toast"
        style={{
          position: 'absolute',
          top: '10%',
          left: '50%'
        }}
      >
        <Toast.Header style={{
          background: '#F2A81D', 
          }}>
          <strong className="mr-auto" style={{fontSize: "1.2em", color: '#fff'}}>25% Time left</strong>
        </Toast.Header>
        <Toast.Body style={{
          fontSize: "1.2em",
          fontWeight: 500,
          color: "#000",
        }}>You have 25% of Time left to complete the exam!!</Toast.Body>
      </Toast>
    );
  };
  
  renderWarningModal = () => {
    return (
        <Modal show={true}>
          <Modal.Header>
            <Modal.Title>Browser Tab Switched</Modal.Title>
          </Modal.Header>
          <Modal.Body>We are submitting your test.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() =>
                this.setState({
                  showWarningModal: false,
                }, () => {
                  console.log("Test Submitted...");
                })
            }>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }

  renderRatingsModal = () => {
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show = {true}
        >
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <h4 className="mb-2">How was the test experience ?</h4>
              <ReactStars
                  count={5}
                  onChange={this.ratingChanged}
                  size={40}
                  activeColor="#ffd700"
                  color="lightgray"
                  isHalf={true}
              />
              <p className="bad-rating">Very bad</p>
              <p className="good-rating">Very good</p>
              <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  className="mt-4 w-75"
                  value={this.state.comments}
                  onChange={(e) => this.setState({comments: e.target.value})}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.showSpinner && <Button disabled={!this.state.ratings}
                                                onClick={() => {
                                                  this.setState({showSpinner: true})
                                                  this.props.submitRatings(this.state.ratings, this.state.comments)
                                                }}
            >Submit</Button>}
            {this.state.showSpinner && <div className="spinner-btn">
              <Button>
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            </div>}
          </Modal.Footer>
        </Modal>
    )
  }

  renderTabs = (tabItems) => {
    return (
      <ul className="nav nav-pills subject-nav">
        {tabItems.map((tab, index) => {
          return (
            <li
              className="nav-item"
              key={tab}
              onClick={() => {
                this.setState({
                  selectedSubject: index + 1,
                },() => this.updateActiveSubHeading());
              }}
            >
              <a
                className={`nav-link ${
                  this.state.selectedSubject === index + 1 ? "active" : ""
                }`}
                data-toggle="pill"
                href={`#${tab}`}
              >
                {tab}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  updateActiveSubHeading = () => {
    let activeSubHeading = null;
    if (this.state.sectionLimit) {
      const {sectionName} = this.getSubHeading() ?? {};
      const selectedSubject = this.state.subjects[this.state.selectedSubject - 1];
      activeSubHeading = `${selectedSubject}-${sectionName}`;
    }

    if (this.state.activeSubHeading !== activeSubHeading) {
      this.setState({activeSubHeading});
    }
  }

  getSubHeading = () => {
    const selectedSubject = this.state.subjects[this.state.selectedSubject - 1];
    if(selectedSubject) {
      const selectedQuesIndex = this.state.selectedQuestion[selectedSubject];
      const response: any = {};
      const sectionData = Object.keys(this.state.sectionState[selectedSubject]).reduce((acc: any, curr: any) => {
        const [from, to] = curr.split('-');
        if (from <= selectedQuesIndex && to >= selectedQuesIndex) {
          acc = this.state.sectionState[selectedSubject][curr];
          response.sectionName = this.state.sectionState[selectedSubject][curr];
          response.totalQuestion = to && from ? (to - from) + 1 : 0;
        }
        return response;
      }, '');
      return sectionData;
    }

    return null;
  }

  renderSubSections = () => {
    if (this.state.sectionState) {
      const {sectionName, totalQuestion} = this.getSubHeading();

      return (<span className="sub-heading"><span>{sectionName}</span><br /><span> Total Number Of Questions: {totalQuestion}</span> </span>)
    } else {
      return null;
    }
  }

  updateAttemptedQuestionBatch = (e, subject) => {
    //Update redux state with selected answers
    const newSelectedAnswers = this.state.selectedAnswers[subject];
    newSelectedAnswers[
    this.state.selectedQuestion[subject] - 1
        ] = parseInt(e.target.value);
    this.props.updateAnswers(newSelectedAnswers, subject);

    const attemptedQuestionBatch = new Set(this.state.attemptedQuestionBatch);

    //if it's a 5th attempted question.
    if (attemptedQuestionBatch.size === 4) {
      this.props.submitTest(false);
      attemptedQuestionBatch.clear();
    } else {
      attemptedQuestionBatch.add(subject + this.state.selectedQuestion[subject]);
    }

    const questions = this.state.questionsState[subject];
    questions[this.state.selectedQuestion[subject] - 1].visited = true;
    questions[this.state.selectedQuestion[subject] - 1].answered = true;

    const sectionLimit = this.state.sectionLimit ? {...this.state.sectionLimit} : null;
    if (this.state.sectionLimit?.[this.state.activeSubHeading]?.limit) {
      sectionLimit[this.state.activeSubHeading].set.add(this.state.selectedQuestion[subject]);

      if (sectionLimit[this.state.activeSubHeading].set.size >= sectionLimit[this.state.activeSubHeading].limit) {
        sectionLimit[this.state.activeSubHeading].limitExceeded = true;
      }
    }

    this.setState({
      selectedOption: {
        ...this.state.selectedOption,
        [subject]: parseInt(e.target.value),
      },
      selectedAnswers: {
        ...this.state.selectedAnswers,
        [subject]: [...newSelectedAnswers],
      },
      questionsState: {
        ...this.state.questionsState,
        [subject]: [...questions],
      },
      attemptedQuestionBatch,
      sectionLimit
    }, () => this.props.updateQuestionsState(questions, subject))
  }

  renderQuestions = (subject): JSX.Element => {
    if (this.state.questionsState === null || this.state.pageLoader) {
      return (
        <div className="container-fluid questions-options-container">
          <div className="spinner-table">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>

            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else {
      const isQuestionImg = this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].question?.indexOf('<img') !== -1;
      const isQuestionBlank =  this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].question === '';

      const doesQuestionHaveMathType= this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].question.includes("<math");
     const doesOption1HaveMathType= this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[0].option.includes("<math");
     const doesOption2HaveMathType= this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[1].option.includes("<math");
     const doesOption3HaveMathType= this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[2].option.includes("<math");
     const doesOption4HaveMathType= this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[3].option.includes("<math");

     const isOption1Image=this.state.questionsState[subject][this.state.selectedQuestion[subject]-1].options[0].option?.indexOf('<img')!==-1;
     const isOption2Image=this.state.questionsState[subject][this.state.selectedQuestion[subject]-1].options[1].option?.indexOf('<img')!==-1;
     const isOption3Image=this.state.questionsState[subject][this.state.selectedQuestion[subject]-1].options[2].option?.indexOf('<img')!==-1;
     const isOption4Image=this.state.questionsState[subject][this.state.selectedQuestion[subject]-1].options[3].option?.indexOf('<img')!==-1; 
     return (
        <div className={`container-fluid questions-options-container ${this.isDisabled(subject)}`}>
          <div className="container-fluid questions-container">
          <div className="question-number" style={{ fontWeight: 600}}>{`${this.state.selectedQuestion[subject]})`}</div>
            <div className="question-title">
              {!isQuestionImg && !isQuestionBlank && doesQuestionHaveMathType &&

                      <MathJax
                        math={String.raw`${this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].question}`}
                      />
              }

              {!isQuestionImg && !isQuestionBlank && !doesQuestionHaveMathType && <p dangerouslySetInnerHTML={{__html: this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].question}}>
              </p>}
              {(isQuestionBlank || isQuestionImg) && <div className="container-fluid img-container">
                {this.state.questionsState[subject][
                this.state.selectedQuestion[subject] - 1
                    ].img ? (
                    <img
                        className="img-fluid"
                        src={
                          this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                              ].img
                        }
                        alt="Question Image"
                    />
                ) : null}
              </div>}
            </div>
          </div>
          <div className="container-fluid content-container">
            <div className="container-fluid form-check options-container">
              <div className="options-sub-container-left">
                <div className="options-input-img-container d-flex">
                  <div className="option-input-container">
                   
                    <div className="d-flex">
                      <input
                        className="option-input"
                        type="radio"
                        name={`radioOptions${subject}`}
                        id="radioOption1"
                        value={1}
                        onChange={e => this.updateAttemptedQuestionBatch(e, subject)}
                        checked={this.checkRadioButtonChecked(1, subject)}
                      />
                    </div>
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600, marginLeft:"10px"}}>
                      A)
                    </div>&nbsp;&nbsp;
                    <label className="form-check-label" htmlFor="radioOption1">
                      { doesOption1HaveMathType && <MathJax
                        math={String.raw`${
                          this.state.questionsState[subject][
                            this.state.selectedQuestion[subject] - 1
                          ].options[0].option
                        }`}
                      />}

                      {!isOption1Image && !doesOption1HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[0].option}}>
                    </p>  }
                    </label>
                  </div>
                  {this.state.questionsState[subject][
                    this.state.selectedQuestion[subject] - 1
                  ].options[0].img ? (
                    <img
                      className="img-fluid"
                      src={
                        this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                        ].options[0].img
                      }
                      alt="Question Image"
                    />
                  ) : null}
                </div>
                <div className="options-input-img-container d-flex">
                  <div className="option-input-container">
                   
                    <div className="d-flex">
                      <input
                        className="option-input"
                        type="radio"
                        name={`radioOptions${subject}`}
                        id="radioOption2"
                        value={2}
                        onChange={e => this.updateAttemptedQuestionBatch(e, subject)}
                        checked={this.checkRadioButtonChecked(2, subject)}
                      />
                    </div>
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600 ,marginLeft:"10px"}}>
                      B)
                    </div>&nbsp;&nbsp;
                    <label className="form-check-label" htmlFor="radioOption2">
                    {doesOption2HaveMathType && <MathJax
                        math={String.raw`${
                          this.state.questionsState[subject][
                            this.state.selectedQuestion[subject] - 1
                          ].options[1].option
                        }`}
                      />}

                      {!isOption2Image && !doesOption2HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[1].option}}>
                    </p>  }
                    </label>
                  </div>
                  {this.state.questionsState[subject][
                    this.state.selectedQuestion[subject] - 1
                  ].options[1].img ? (
                    <img
                      className="img-fluid"
                      src={
                        this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                        ].options[1].img
                      }
                      alt="Question Image"
                    />
                  ) : null}
                </div>
              </div>

              <div className="options-sub-container-right">
                <div className="options-input-img-container d-flex">
                  <div className="option-input-container">
                    
                    <div className="d-flex">
                      <input
                        className="option-input"
                        type="radio"
                        name={`radioOptions${subject}`}
                        id="radioOption3"
                        value={3}
                        onChange={e => this.updateAttemptedQuestionBatch(e, subject)}
                        checked={this.checkRadioButtonChecked(3, subject)}
                      />
                    </div>
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600, marginLeft:"10px"}}>
                      C)
                    </div>&nbsp;&nbsp;
                    <label className="form-check-label" htmlFor="radioOption3">
                    {doesOption3HaveMathType && <MathJax
                        math={String.raw`${
                          this.state.questionsState[subject][
                            this.state.selectedQuestion[subject] - 1
                          ].options[2].option
                        }`}
                      />}

                      {!isOption3Image && !doesOption3HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[2].option}}>
                    </p>  }
                    </label>
                  </div>
                  {this.state.questionsState[subject][
                    this.state.selectedQuestion[subject] - 1
                  ].options[2].img ? (
                    <img
                      className="img-fluid"
                      src={
                        this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                        ].options[2].img
                      }
                      alt="Question Image"
                    />
                  ) : null}
                </div>

                <div className="options-input-img-container d-flex">
                  <div className="option-input-container">
                    
                    <div className="d-flex">
                      <input
                        className="option-input"
                        type="radio"
                        name={`radioOptions${subject}`}
                        id="radioOption4"
                        value={4}
                        onChange={e => this.updateAttemptedQuestionBatch(e, subject)}
                        checked={this.checkRadioButtonChecked(4, subject)}
                      />
                    </div>
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600, marginLeft:"10px"}}>
                      D)
                    </div>&nbsp;&nbsp;
                    <label className="form-check-label" htmlFor="radioOption4">
                    {doesOption4HaveMathType && <MathJax
                        math={String.raw`${
                          this.state.questionsState[subject][
                            this.state.selectedQuestion[subject] - 1
                          ].options[3].option
                        }`}
                      />}

                      {!isOption4Image && !doesOption4HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: this.state.questionsState[subject][this.state.selectedQuestion[subject] - 1].options[3].option}}>
                    </p>  }
                    </label>
                  </div>
                  {this.state.questionsState[subject][
                    this.state.selectedQuestion[subject] - 1
                  ].options[3].img ? (
                    <img
                      className="img-fluid"
                      src={
                        this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                        ].options[3].img
                      }
                      alt="Question Image"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {!isQuestionBlank && !isQuestionImg && <div className="container-fluid img-container">
                {this.state.questionsState[subject][
                this.state.selectedQuestion[subject] - 1
                    ].img ? (
                    <img
                        className="img-fluid"
                        src={
                          this.state.questionsState[subject][
                          this.state.selectedQuestion[subject] - 1
                              ].img
                        }
                        alt="Question Image"
                    />
                ) : null}
              </div>}
          </div>
        </div>
      );
    }
  };

  renderButtons = (subject) => {
    return (
      <div className="container-fluid final-button-container">
        <button
          type="button"
          className={`btn btn-sm btn-success button back-button ${
            this.state.selectedQuestion[subject] < 2 ? "disabled" : null
          } `}
          disabled={this.state.selectedQuestion[subject] === 1}
          onClick={() => {
            if (this.state.selectedQuestion[subject] > 1) {
              const questions = this.state.questionsState[subject];
              if (
                !questions[this.state.selectedQuestion[subject] - 1]?.visited
              ) {
                questions[
                  this.state.selectedQuestion[subject] - 1
                ].visited = true;
              }

              let nextSelectedOption = null;

              if (
                this.state.selectedQuestion[subject] - 2 >= 0 &&
                this.state.selectedAnswers[subject][
                  this.state.selectedQuestion[subject] - 2
                ] != null
              ) {
                nextSelectedOption = this.state.selectedAnswers[subject][
                  this.state.selectedQuestion[subject] - 2
                ];
              }

              // To calculate time taken to answer each question
              const newPerformanceTimer = this.state.performanceTimer[subject];
              const t1 = Number(performance.now().toFixed(2));
              const timeTaken = Number((t1 - this.state.t0).toFixed(2));
              newPerformanceTimer[
                this.state.selectedQuestion[subject] - 1
              ] = timeTaken;

              if (this.state.selectedOption[subject] !== null) {
                questions[
                  this.state.selectedQuestion[subject] - 1
                ].answered = true;

                this.props.updateQuestionsState(questions, subject);
                this.props.updatePerformanceMetrics(
                  newPerformanceTimer,
                  subject,
                  this.state.selectedQuestion[subject] - 1
                );

                this.setState({
                  questionsState: {
                    ...this.state.questionsState,
                    [subject]: [...questions],
                  },
                  selectedQuestion: {
                    ...this.state.selectedQuestion,
                    [subject]: this.state.selectedQuestion[subject] - 1,
                  },
                  selectedOption: {
                    ...this.state.selectedOption,
                    [subject]: nextSelectedOption,
                  },
                  performanceTimer: {
                    ...this.state.performanceTimer,
                    [subject]: [...newPerformanceTimer],
                  },
                }, () => this.updateActiveSubHeading());
              } else {
                this.props.updateQuestionsState(questions, subject);
                this.props.updatePerformanceMetrics(
                  newPerformanceTimer,
                  subject,
                  this.state.selectedQuestion[subject] - 1
                );

                this.setState({
                  questionsState: {
                    ...this.state.questionsState,
                    [subject]: [...questions],
                  },
                  selectedQuestion: {
                    ...this.state.selectedQuestion,
                    [subject]: this.state.selectedQuestion[subject] - 1,
                  },
                  selectedOption: {
                    ...this.state.selectedOption,
                    [subject]: nextSelectedOption,
                  },
                  performanceTimer: {
                    ...this.state.performanceTimer,
                    [subject]: [...newPerformanceTimer],
                  },
                }, () => this.updateActiveSubHeading());
              }
            }
          }}
        >
          Back
        </button>
        <button
            type="button"
            className={`btn btn-sm btn-success button back-button ${
              !this.props.answers?.[subject]?.[this.state.selectedQuestion[subject] - 1] ? "disabled" : null
            } `}
            onClick={() => {
              const sectionLimit = this.state.sectionLimit ? {...this.state.sectionLimit} : null;
              if (this.state.sectionLimit?.[this.state.activeSubHeading]?.limit) {
                sectionLimit[this.state.activeSubHeading].set.delete(this.state.selectedQuestion[subject]);
                sectionLimit[this.state.activeSubHeading].limitExceeded = false;
              }

              const answers = [...this.state.selectedAnswers[subject]];
              answers[this.state.selectedQuestion[subject] - 1] = null;

              const attemptedQuestionBatch = new Set(this.state.attemptedQuestionBatch);
              attemptedQuestionBatch.delete(`${subject}${this.state.selectedQuestion[subject]}`)

              const questionsState = [...this.state.questionsState[subject]];
              questionsState[this.state.selectedQuestion[subject] - 1].answered = false;

              this.setState({
                sectionLimit,
                selectedAnswers: {
                  ...this.state.selectedAnswers,
                  [subject]: answers
                },
                selectedOption: {
                  ...this.state.selectedOption,
                  [subject]: null
                },
                questionsState: {
                  ...this.state.questionsState,
                  [subject]: questionsState
                },
                attemptedQuestionBatch
              });
              this.props.resetAnswer(subject, this.state.selectedQuestion[subject] - 1);
            }}
        >
          Clear
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success button next-button"
          onClick={() => {
            // To go to the next question
            const questions = this.state.questionsState[subject];
            if (!questions[this.state.selectedQuestion[subject] - 1]?.visited) {
              questions[
                this.state.selectedQuestion[subject] - 1
              ].visited = true;
            }

            let nextSelectedOption = null;
            let nextSelectedQuestion = null;

            // To calculate time taken to answer each question
            const newPerformanceTimer = this.state.performanceTimer[subject];
            const t1 = Number(performance.now().toFixed(2));
            const timeTaken = Number((t1 - this.state.t0).toFixed(2));
            newPerformanceTimer[
              this.state.selectedQuestion[subject] - 1
            ] = timeTaken;

            if (
              this.state.selectedQuestion[subject] <
                this.state.questionsState[subject].length &&
              this.state.selectedAnswers[subject][
                this.state.selectedQuestion[subject]
              ] != null
            ) {
              nextSelectedOption = this.state.selectedAnswers[subject][
                this.state.selectedQuestion[subject]
              ];
            }

            // If it is the last question in the subject and not for the last subject
            if (
              this.state.selectedQuestion[subject] ===
                this.state.questionsState[subject].length &&
              this.state.selectedSubject <= this.state.subjects.length
            ) {
              nextSelectedQuestion = 1;
            } else {
              nextSelectedQuestion = this.state.selectedQuestion[subject] + 1;
            }

            if (this.state.selectedOption[subject] !== null) {
              questions[
                this.state.selectedQuestion[subject] - 1
              ].answered = true;

              this.props.updateQuestionsState(questions, subject);
              this.props.updatePerformanceMetrics(newPerformanceTimer, subject, this.state.selectedQuestion[subject] - 1);

              this.setState({
                questionsState: {
                  ...this.state.questionsState,
                  [subject]: [...questions],
                },
                selectedQuestion: {
                  ...this.state.selectedQuestion,
                  [subject]: nextSelectedQuestion,
                },
                selectedOption: {
                  ...this.state.selectedOption,
                  [subject]: nextSelectedOption,
                },
                performanceTimer: {
                  ...this.state.performanceTimer,
                  [subject]: [...newPerformanceTimer],
                },
              }, () => this.updateActiveSubHeading());
            } else {
              this.props.updateQuestionsState(questions, subject);
              this.props.updatePerformanceMetrics(newPerformanceTimer, subject, this.state.selectedQuestion[subject] - 1);

              this.setState({
                questionsState: {
                  ...this.state.questionsState,
                  [subject]: [...questions],
                },
                selectedQuestion: {
                  ...this.state.selectedQuestion,
                  [subject]: nextSelectedQuestion,
                },
                selectedOption: {
                  ...this.state.selectedOption,
                  [subject]: nextSelectedOption,
                },
                performanceTimer: {
                  ...this.state.performanceTimer,
                  [subject]: [...newPerformanceTimer],
                },
              }, () => this.updateActiveSubHeading());
            }

            // When it reaches the end
            if (
              this.state.selectedQuestion[subject] ===
                this.state.questionsState[subject].length &&
              this.state.selectedSubject === this.state.subjects.length
            ) {
              this.showPageLoader();
              this.props.submitTest(true);
            }
            if (
              this.state.selectedQuestion[subject] ===
                this.state.questionsState[subject].length &&
              this.state.selectedSubject <= this.state.subjects.length
            ) {
              this.setState({
                selectedSubject: this.state.selectedSubject + 1,
              });
            }
          }}
        >
          {this.state.selectedQuestion[subject] ===
            this.state.questionsState[subject].length &&
          this.state.selectedSubject === this.state.subjects.length
            ? "Finish"
            : "Next"}
        </button>
      </div>
    );
  };



  generateGroupButtons = (subject) => {
    const checkEnd = () => {
      if (
        this.state.selectedQuickLookGroup[subject] ===
        this.state.totalQuickLookGroups[subject]
      ) {
        return this.state.questionsState[subject].length;
      } else {
        return 30 * this.state.selectedQuickLookGroup[subject];
      }
    };

    const buttons = [];
    for (
      let start = 30 * (this.state.selectedQuickLookGroup[subject] - 1) + 1;
      start <= checkEnd();
      start++
    ) {
      buttons.push(
        <button
          className={`question-button ${
            this.state.questionsState[subject][start - 1]?.visited == false
              ? "not-visited"
              : ""
          }
          ${
            this.state.questionsState[subject][start - 1]?.visited == true &&
            this.state.questionsState[subject][start - 1]?.answered == true
              ? "visited-and-answered"
              : ""
          }
          ${
            this.state.questionsState[subject][start - 1]?.visited == true &&
            this.state.questionsState[subject][start - 1]?.answered == false
              ? "visited-but-unanswered"
              : ""
          }

          ${start == this.state.selectedQuestion[subject] ? "selected" : ""} 
          `}
          key={start}
          onClick={() => {
            const questions = this.state.questionsState[subject];
            if (!questions[this.state.selectedQuestion[subject] - 1]?.visited) {
              questions[
                this.state.selectedQuestion[subject] - 1
              ].visited = true;
            }

            let nextSelectedOption = null;

            if (
              start - 1 >= 0 &&
              start - 1 < this.state.questionsState[subject].length &&
              this.state.selectedAnswers[subject][start - 1] != null
            ) {
              nextSelectedOption = this.state.selectedAnswers[subject][
                start - 1
              ];
            }

            // To calculate time taken to answer each question
            const newPerformanceTimer = this.state.performanceTimer[subject];
            const t1 = Number(performance.now().toFixed(2));
            const timeTaken = Number((t1 - this.state.t0).toFixed(2));
            newPerformanceTimer[
              this.state.selectedQuestion[subject] - 1
            ] = timeTaken;

            if (this.state.selectedOption[subject] !== null) {
              questions[
                this.state.selectedQuestion[subject] - 1
              ].answered = true;

              this.props.updateQuestionsState(questions, subject);
              this.props.updatePerformanceMetrics(newPerformanceTimer, subject, this.state.selectedQuestion[subject] - 1);

              this.setState({
                questionsState: {
                  ...this.state.questionsState,
                  [subject]: [...questions],
                },
                selectedQuestion: {
                  ...this.state.selectedQuestion,
                  [subject]: start,
                },
                selectedOption: {
                  ...this.state.selectedOption,
                  [subject]: nextSelectedOption,
                },
                performanceTimer: {
                  ...this.state.performanceTimer,
                  [subject]: [...newPerformanceTimer],
                },
              }, () => this.updateActiveSubHeading());
            } else {
              this.props.updateQuestionsState(questions, subject);
              this.props.updatePerformanceMetrics(newPerformanceTimer, subject, this.state.selectedQuestion[subject] - 1);

              this.setState({
                questionsState: {
                  ...this.state.questionsState,
                  [subject]: [...questions],
                },
                selectedQuestion: {
                  ...this.state.selectedQuestion,
                  [subject]: start,
                },
                selectedOption: {
                  ...this.state.selectedOption,
                  [subject]: nextSelectedOption,
                },
                performanceTimer: {
                  ...this.state.performanceTimer,
                  [subject]: [...newPerformanceTimer],
                },
              }, () => this.updateActiveSubHeading());
            }
          }}
        >
          <div className="question-button-text">{start}</div>
        </button>
      );
    }
    return buttons;
  };

  quickLookContentRender = (subject): JSX.Element => {
    return (
      <>
        <div className="container-fluid quick-look-content-container">
          <div className="container-fluid button-container">
            {this.generateGroupButtons(subject)}
          </div>
        </div>
        <div className="container-fluid pagination-container">
          <div className="container-fluid pagination-group">
            <button
              className={`pagination-button ${
                this.state.selectedQuickLookGroup[subject] == 1
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (this.state.selectedQuickLookGroup[subject] > 1) {
                  this.setState({
                    selectedQuickLookGroup: {
                      ...this.state.selectedQuickLookGroup,
                      [subject]: this.state.selectedQuickLookGroup[subject] - 1,
                    },
                  });
                }
              }}
              disabled={this.state.selectedQuickLookGroup == 1}
            >
              <MdKeyboardArrowLeft className="pagination-icon" />
            </button>
            <button
              className={`pagination-button ${
                this.state.selectedQuickLookGroup[subject] ==
                this.state.totalQuickLookGroups[subject]
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (
                  this.state.selectedQuickLookGroup[subject] <
                  this.state.totalQuickLookGroups[subject]
                ) {
                  this.setState({
                    selectedQuickLookGroup: {
                      ...this.state.selectedQuickLookGroup,
                      [subject]: this.state.selectedQuickLookGroup[subject] + 1,
                    },
                  });
                }
              }}
              disabled={
                this.state.selectedQuickLookGroup[subject] ==
                this.state.totalQuickLookGroups[subject]
              }
            >
              <MdKeyboardArrowRight className="pagination-icon" />
            </button>
          </div>
        </div>
        <div className="legend-container quick-look-content-container" style={{
          marginTop: '1rem',
          borderTop: "3px solid #e2e2e2",
          paddingLeft: 15,
          background: '#fff'
          }}>
            <div className="button-container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h6 style={{
                width: "250px",
                fontWeight: 600,
                fontSize: "1.1rem"
                }}>Not Visited</h6>
              <button style={{margin: '5px 10px 1rem'}} className="question-button not-visited">
                <div className="question-button-text">N</div>
              </button>
            </div>
{/*             <div className="button-container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h6 style={{
                width: "250px",
                fontWeight: 600,
                fontSize: "1.1rem"
                }}>Answered &amp; Selected</h6>
              <button style={{margin: '5px 10px 1rem'}} className="question-button visited-and-answered selected 
              ">
                <div className="question-button-text">N</div>
              </button>
            </div> */}
            <div className="button-container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h6 style={{
                width: "250px",
                fontWeight: 600,
                fontSize: "1.1rem"
                }}>Answered</h6>
              <button style={{margin: '5px 10px 1rem'}} className="question-button visited-and-answered">
                <div className="question-button-text">N</div>
              </button>
            </div>
            <div className="button-container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h6 style={{
                width: "250px",
                fontWeight: 600,
                fontSize: "1.1rem"
                }}>Unanswered</h6>
              <button style={{margin: '5px 10px 1rem'}} className="question-button visited-but-unanswered">
                <div className="question-button-text">N</div>
              </button>
            </div>
            <div className="button-container" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h6 style={{
                width: "250px",
                fontWeight: 600,
                fontSize: "1.1rem"
                }}>Current Selected</h6>
              <button style={{margin: '5px 10px 1rem'}} className="question-button not-visited selected">
                <div className="question-button-text">N</div>
              </button>
            </div>
        </div>
      </>
    );
  };

  redirectToExamSubmitted = () => {
    const {
      history: { replace },
    } = this.props;

    replace("/examSubmitted");
  }

  renderQuickLook = (subject): JSX.Element => {
    return (
      <div
        className={`quick-look-box ${
          this.state.quickLookExpanded ? "expand-quick-look-box" : ""
        }`}
      >
        <button
          className={`quick-look-button ${
            this.state.quickLookExpanded ? "expanded-button" : ""
          }`}
          onClick={() => {
            this.setState({
              quickLookExpanded: !this.state.quickLookExpanded,
            });
          }}
        >
          <div className="quick-look-text-content">
            <div
              className={`quick-look-text ${
                this.state.quickLookExpanded ? "expanded-quick-look" : ""
              }`}
            >
              Quick Look
            </div>
            <div>
              {this.state.quickLookExpanded ? (
                <MdKeyboardArrowDown
                  className={`quick-look-icon ${
                    this.state.quickLookExpanded ? "expanded-quick-look" : ""
                  }`}
                />
              ) : (
                <MdKeyboardArrowUp
                  className={`quick-look-icon ${
                    this.state.quickLookExpanded ? "expanded-quick-look" : ""
                  }`}
                />
              )}
            </div>
          </div>
        </button>
        <div
          className={`quick-look-content ${
            this.state.quickLookExpanded ? "expand" : ""
          }`}
        >
          {this.quickLookContentRender(subject)}
        </div>
      </div>
    );
  };

  isDisabled = (subject) => {
   const selectedQuestion = this.state.selectedQuestion[subject] - 1;
   if (this.state.sectionLimit?.[this.state.activeSubHeading]?.limitExceeded && !this.state.selectedAnswers[subject]?.[selectedQuestion]) {
      return 'question-disabled';
    } else {
      return '';
    }
  }

  ratingChanged = (newRating) => {
    this.setState({ratings : newRating})
  };

  render(): JSX.Element {
    if(this.state.showWarningModal) {
      return (<div>{this.renderWarningModal()}</div>)
    }else if(this.props.examSubmitted && this.props.isLastSession !== 'False') {
      return (<div>{this.renderRatingsModal()}</div>)
    }else if(this.state.errors) {
      return (<div className="container-fluid questions-options-container">{this.state.errors}</div>)
    }else if (this.state.questionsState === null || this.state.pageLoader) {
      return (
        <div className="container-fluid questions-options-container">
          <div className="spinner-table">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>

            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="container-fluid examQuestions"
          onContextMenu={(e) => e.preventDefault()}
        >

          <div className="container-fluid examQuestions-content-container">
            <ExamHeader
              testName={this.props.testName}
              examName={this.props.examName}
              examTimer={this.state.examTimer}
              history={this.props.history}
              setShowToast={this.setShowToast}
              showPageLoader={this.showPageLoader}
            />

            {this.renderTabs(this.state.subjects)}
            {this.renderSubSections()}
            <div className="tab-content">
              {this.state.subjects.map((subject, index) => {
                return (
                  <div
                    className={`tab-pane container ${
                      this.state.selectedSubject === index + 1
                        ? "active"
                        : "fade"
                    }`}
                    id={`${subject}`}
                    key={subject}
                  >
                    {this.renderToast()}
                    {this.renderQuestions(subject)}
                    {this.renderButtons(subject)}
                    {this.renderQuickLook(subject)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}

const ExamQuestions = connect(
  (state: any) => {
    return {
      currentTestId:state.test.currentTestId,
      currentExamId:state.test.currentExamId,
      testName: state.test.testName,
      examName: state.test.examName,
      createdDate: state.test.createdDate,
      noOfUsersTaken: state.test.noOfUsersTaken,
      noOfQuestions: state.test.noOfQuestions,
      timerDate: state.test.timerDate,
      instructions: state.test.instructions,
      answers: state.test.answers,
      questionsState: state.test.questionsState,
      correctAnswers: state.test.correctAnswers,
      performanceTimer: state.test.performanceData,
      examTimer: state.test.examTimer,
      examSubmitted: state.test.submitted,
      submitFailedMsg: state.test.submitFailedMsg,
      ratingsSubmitted: state.test.ratingsSubmitted,
      isLastSession: state.test.isLastSession,
      isTestResumed: state.test.isTestResumed,
      totalTimeTaken: state.test.totalTimeTaken,
      user: state.users.user,
    };
  },
  (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(ExamQuestionsPageComponent);

export { ExamQuestions };
