// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/svg/success_check_check_circle_icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,body,#app,#app>div{height:100vh;scroll-behavior:smooth}.success-logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),none}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA,wBAIE,YAAA,CACA,sBAAA,CAIF,cACE,kDAAA,CACA,6DAAA","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Quicksand&display=swap\");\r\n\r\nhtml,\r\nbody,\r\n#app,\r\n#app > div {\r\n  height: 100vh;\r\n  scroll-behavior: smooth;\r\n  // width:107%;\r\n}\r\n\r\n.success-logo {\r\n  background: url(./assets/svg/success_check_check_circle_icon.svg);\r\n  background-image: url(./assets/svg/success_check_check_circle_icon.svg), none;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
