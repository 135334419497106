import { authenticate } from "../../components";
import { User } from "../../models";
import {
  fetchingUser,
  fetchingUserSuccess,
  fetchingUserFailure,
  authUser,
  unauthUserType,
  updateUserProfileType,
  setProcessing,
} from "./users.action-types";
import {errors} from "../../utilities/errors";
export const fetchAndHandleAuthentication = (
  history: any,
  email: any,
  password: any,
) => {
  return (dispatch: any) => {
    if (password && password?.trim()?.length) {
      dispatch(fetchingUser());
      authenticate(email, password)
        .then((userResp: any) => {
          const user = userResp.data;
          const userData: User = {
            uid: user.UserId,
            firstName: user.FirstName,
            lastName: user.LastName,
            ProfileImageURL: user.ProfileImageURL,
            mobileNumber: user.MobileNumber,
            emailId: user.Email,
            authToken: user.AuthToken,
          };
          dispatch(fetchingUserSuccess(userData));
          dispatch(authUser(user.UserId));
          history.push("/dashboard");
        })
        .catch((error) => {
          const responseStatus = error?.response?.data?.ResponseStatus ?? 500;
          let errorMessage: any = errors.login[responseStatus].msg;
          if (responseStatus && responseStatus === 500 && error?.response?.data?.Message) {
            errorMessage = error?.response?.data?.Message;
          }
          dispatch(fetchingUserFailure(errorMessage));
        });
    } else {
      dispatch(fetchingUserFailure('Invalid Email Id or Password'));
    }
  };
};

export const unauthUser = () => {
  return (dispatch: any) => {
    dispatch(unauthUserType());
  };
};

export const updateProfilePic = (imageUrl: string) => {
  return (dispatch: any) => {
    dispatch(updateUserProfileType(imageUrl));
  };
};

export const showProcessing = (isProcessing: boolean) => {
  return (dispatch: any) => {
    dispatch(setProcessing(isProcessing));
  };
};
