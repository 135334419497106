import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
/* import {
  ICardComponentProps,
  ICardsComponentProps,
  ICardsComponentState,
} from "./cards.types"; */
import ReactStars from "react-rating-stars-component";
import { base_url } from '../../config/constants';
import "./cards.scss";
import { MdLock } from "react-icons/md";
// import { MdStar, MdLock } from "react-icons/md";
/*import { dashboardCardsApi } from "../../../mockApi/api";
import { ExamType } from "../../modules/dashboard/dashboard.types"; */
import axios from "axios";
import {errors} from "../../utilities/errors";

class Card extends React.Component<any> {
  overlay = () => {
    return (
      <div className="overlay-custom">
        <div className="content-container">
          <MdLock className="lock-icon" />
          <h5 className="get-enrolled-text">Get Enrolled</h5>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className={`card ${
          this.props.cardSelected === this.props.title ? "card-selected" : null
        }`}
        onClick={() => {
          if (!this.props.overlay) {
            this.props.setCardSelected(this.props.title);
            this.props.setExamId(this.props.examId);
          } else {
            // Write code to handle get enrolled for cards
          }
        }}
      >
        {this.props.overlay ? this.overlay() : null}
        <div className="card-body">
          <h2 className="card-title">{this.props.title}</h2>
          <div className="card-text-container">
            <div className="card-text-block">
              <p className="card-text">Number of Mockups</p>
              <p className="card-number">{this.props.numberOfMockups}</p>
            </div>
            <div className="card-text-block">
              <p className="card-text">Number of People Taken</p>
              <p className="card-number">{this.props.numberOfPeopleTaken}</p>
            </div>
          </div>
          <ReactStars
              count={5}
              value={this.props.rating}
              size={18}
              activeColor="#ffd700"
              color="lightgray"
              isHalf={true}
              edit={false}
          />
        </div>
      </div>
    );
  }
}

class CardsComponent extends React.Component<any> {
  public state: any = {
    cardsData: [],
  };

  async componentDidMount() {
    try {
      const cardsResponse = await axios.post(
        `${base_url}/api/Client/GetDashboardCards`,
        {
          AuthToken: this.props.user.authToken,
          UserId: this.props.user.uid,
        }
      );
      this.setState({
        cardsData: cardsResponse.data.Data,
      });
      this.props.setExamId(cardsResponse.data.Data[0].examId);

      this.props.setCardSelected(cardsResponse.data.Data[0].name);
    } catch (error) {
      if (error.response) {
        const responseStatus = error.response.data.ResponseStatus;
        const errorMessage: any = errors.dashboard[responseStatus].msg;
        this.setState({
          errors: <p style={{ color: "red" }}>{errorMessage}</p>,
        });
      }
      alert("Error Fetching Cards Data!!");
    }
  }

  render() {
    if(this.state.errors) {
      return (<div><strong>{this.state.errors}</strong></div>)
    }else if (this.state.cardsData.length === 0) {
      return (
        <div className="spinner-cards">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>

          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>

          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return this.state.cardsData.map((cardItem) => {
        return (
          <Card
            key={cardItem.name}
            title={cardItem.name}
            numberOfMockups={cardItem.numberOfMockups}
            numberOfPeopleTaken={cardItem.numberOfPeopleTaken}
            rating={cardItem.rating}
            overlay={cardItem.locked}
            cardSelected={this.props.cardSelected}
            setCardSelected={this.props.setCardSelected}
            setExamId={this.props.setExamId}
            examId={cardItem.examId}
          />
        );
      });
    }
  }
}

const Cards = connect(
  (state: any) => {
    return {
      state,
      user: state.users.user,
    };
  },
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(CardsComponent);

export { Cards };
