import * as React from "react";
import "../results/results.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as testActionCreators from "../../redux/test/test.actions";

class ExamSubmittedComponent extends React.Component<any> {
    render() {
        return (
                <div className="container-fluid results-container">
                    <div className="container-fluid congratulations-container" style={{marginBottom: '4em', paddingTop: "2em"}}>
                        <h3 className="question-paper-name" style={{color: '#333'}}>
                        {this.props.examName} - {this.props.testName}
                        </h3>
                        <div className="congratulations-text" style={{color: '#333'}}>{
                            this.props.isLastSession === 1 ? <div style={{textAlign:'center',fontSize:'1.2rem',padding:'6%',boxShadow:"7px 7px 7px 7px #f1f1f1", marginTop:"3.5%",}}><p style={{textAlign:'center',fontSize:'1.2rem'}} >You have successfully completed the exam.</p><p style={{textAlign:'center',fontSize:'1.2rem'}}>Please wait for some time to view the results, as we are currently evaluating the test scores of several students who have taken this test along with you.</p><p style={{textAlign:'center',fontSize:'1.2rem'}}>Thank you for your patience. </p></div> :
                                <div  style={{textAlign:'center',fontSize:'1.2rem',padding:'6%',boxShadow:"7px 7px 7px 7px #f1f1f1", marginTop:"3.5%",}}><p style={{textAlign:'center',fontSize:'1.2rem'}}>This Session has been completed, Next Session will start soon!!!</p></div>
                        } </div>
                    </div>
                    <div className="return-button-container">
                        <button
                            type="button"
                            className="btn btn-lg btn-success button return-button"
                            onClick={() => {
                                this.props.resetTestData();
                                const {
                                    history: { replace },
                                } = this.props;

                                replace("/dashboard");
                            }}
                        >
                            Return
                        </button>
                    </div>
                </div>
            );
    }
}

const ExamSubmitted = connect(
    (state: any) => {
        return {
            testName: state.test.testName,
            examName: state.test.examName,
            isLastSession: state.test.isLastSession,
        };
    },
    (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(ExamSubmittedComponent);

export { ExamSubmitted };
