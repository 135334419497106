export enum TestActionTypes {
  ADD_TEST_DETAILS = "ADD_TEST_DETAILS",
  ADD_RESUME_TEST_DETAILS = "ADD_RESUME_TEST_DETAILS",
  ADD_INITIAL_DATA = "ADD_INITIAL_DATA",
  ADD_ANSWERS = "ADD_ANSWERS",
  UPDATE_QUESTION_STATE = "UPDATE_QUESTION_STATE",
  UPDATE_CORRECT_ANSWERS = "UPDATE_CORRECT_ANSWERS",
  UPDATE_PERFORMANCE_DATA = "UPDATE_PERFORMANCE_DATA",
  SUBMITTED_SUCCESSFULLY = "SUBMITTED_SUCCESSFULLY",
  SUBMIT_FAILURE = "SUBMIT_FAILURE",
  RATINGS_SUBMITTED = "RATINGS_SUBMITTED",
  RESET_ANSWER = "RESET_ANSWER",
  RESET_DATA = "RESET_DATA",
  UPDATE_IS_FREE_TRIAL = "UPDATE_IS_FREE_TRIAL",
  UPDATE_TOTAL_TIME_TAKEN = "UPDATE_TOTAL_TIME_TAKEN",
  ADD_PDF_TEST_DETAILS = "ADD_PDF_TEST_DETAILS"
}
