const loopOverQuestions = (questions, answers, questionsState, performanceTimer, correctAnswers) => {
    return questions.forEach((ques, i) => {
        answers.push(ques.selectedAnswer ? parseInt(ques.selectedAnswer) : null);
        questionsState.push({questionNo: i + 1, question: ques.question, img: ques.img, options: ques.options});
        performanceTimer.push(ques.TimeTakenToAnswer);
        correctAnswers.push({option: ques.correctAnswer, elaboration: ques.elaboration, avgTimeTaken: ques.AverageTime});
    })
}

export const formatResponseData = (testData) => {
    const {subjects, ...rest} = testData.data.Data;
    return {...subjects?.reduce((acc, sub) => {
        if (!acc.answers[sub.name]) {
            acc.answers[sub.name] = [];
            acc.questionsState[sub.name] = [];
            acc.performanceTimer[sub.name] = [];
            acc.correctAnswers[sub.name] = [];
            acc.subjectsChart[sub.name] = {}
        }
        loopOverQuestions(sub.questions, acc.answers[sub.name], acc.questionsState[sub.name], acc.performanceTimer[sub.name], acc.correctAnswers[sub.name]);
        acc.subjectsChart[sub.name] = sub.subjectsChart;
        return acc;
    }, {answers: {}, questionsState: {}, performanceTimer: {}, correctAnswers: {}, subjectsChart: {}}), ...rest}
}
