import * as React from 'react'
// import {Link} from 'react-router-dom'
import {IntrestSubmition} from '../OnlineMockupMobileAppComponents/submitionform'
import './main.scss'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  MdChevronLeft,
  MdChevronRight
} from "react-icons/md";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  }
};

const arrowStyle = {
  background: "transparent",
  border: 0,
  color: "#000",
  fontSize: "80px",
  display: 'flex'
};

const CustomRight = (props: any) => (
  <button type="button" aria-label="Go to next slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right arrow right" onClick={props?.onClick} style={arrowStyle}>
    <MdChevronRight style={{ fontSize: "50px" }} />
  </button>
);

const CustomLeft = (props: any) => (
  <button aria-label="Go to previous slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left arrow left" type="button" onClick={props?.onClick} style={arrowStyle}>
    <MdChevronLeft style={{ fontSize: "50px" }} />
  </button>
);

 class MainBodyOnlineMockUp extends React.Component
{
    render()
    {
      const imgWidth = "85%";
    return(
        <div className="mainbodystart2">
          {/* <div style={{
            padding: "0 10%"}}>
    <div id="firstDiv">
        <div id="clientSays">
            <div id="clientSaysTextDiv">
            <h1><u>What clients<br/>says about us</u></h1>
            <p style={{color:"grey",fontSize:"14px",width: "80%"}}>
                Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor Loreum ipsum dolor 
            </p>
        </div>
        </div>
        <div id="testimonialFirstImage" >
            <div>

            </div>
        </div>
    </div>
    <div>
        <div style={{marginTop: "50px"}}>
            <p style={{textAlign: "center",fontSize: "18px"}}>Trusted by <span style={{color: "dodgerblue"}}>hundreds</span> of the world best<br/>institute <span style={{color: "dodgerblue"}}>organizations</span></p>
        </div>
        <div >
            <div id="instituteLogo" style={{width: "150px",height:"60px",backgroundColor: "lightgray",marginBottom:"50px"}}></div>

            <Carousel
                swipeable={false}
                draggable={false}
                showDots
                responsive={responsive}
                centerMode={false}
                arrows
                additionalTransfrom={0}
                infinite={true}
                autoPlay={true}
                keyBoardControl
                autoPlaySpeed={1000}
                transitionDuration={1000}
                containerClass="carousel-container-1"
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customRightArrow={<CustomRight />}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                customLeftArrow={<CustomLeft />}
              >
                <div style={{marginTop:"50px"}} className="carousel-item active">
                  <div  className="d-block w-90" style={{height:"250px",backgroundColor:"white",width: imgWidth,borderRadius:"20px",boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"}}  >
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"70%"}}>
                    <div style={{ width:"80px",height:"80px",borderRadius:"40px",backgroundColor:"blue",marginTop:"30px"}}></div>
                    <div style={{marginTop:"auto",marginBottom:"auto"}}> Institute Name</div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item active">
                  <div  className="d-block w-90" style={{height:"250px",backgroundColor:"white",width: imgWidth,borderRadius:"20px",boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"}}  >
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"70%"}}>
                    <div style={{ width:"80px",height:"80px",borderRadius:"40px",backgroundColor:"blue",marginTop:"30px"}}></div>
                    <div style={{marginTop:"auto",marginBottom:"auto"}}> Institute Name</div>
                    </div>
                  </div>
                </div>
                <div style={{marginTop:"50px"}} className="carousel-item active">
                  <div  className="d-block w-90" style={{height:"250px",backgroundColor:"white",width: imgWidth,borderRadius:"20px",boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"}}  >
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"70%"}}>
                    <div style={{ width:"80px",height:"80px",borderRadius:"40px",backgroundColor:"blue",marginTop:"30px"}}></div>
                    <div style={{marginTop:"auto",marginBottom:"auto"}}> Institute Name</div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item active">
                  <div  className="d-block w-90" style={{height:"250px",backgroundColor:"white",width: imgWidth,borderRadius:"20px",boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)"}}  >
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"70%"}}>
                    <div style={{ width:"80px",height:"80px",borderRadius:"40px",backgroundColor:"blue",marginTop:"30px"}}></div>
                    <div style={{marginTop:"auto",marginBottom:"auto"}}> Institute Name</div>
                    </div>
                  </div>
                </div>
                </Carousel>

                
        </div>


    </div>
    </div> */}
            
            <div className="AmcqueeContainer2">
                <p><span style={{fontSize:"40px", fontWeight:"bold", textAlign:"center",fontFamily:"Quicksand",color:"#042A9A"}}>About PROLASKOOL</span><br /><br />
                <div style={{textAlign:"justify"}}>
                {/* <span style={{fontSize:"20px", color:"grey",fontFamily:"Quicksand"}}>An Desktop App</span> <br /><br /> */}
                <p style={{fontWeight:"bold",fontSize:"20px",fontFamily:"Quicksand",textAlign:'center',paddingBottom:'35px'}}>
                <span style={{color:'#042A9A'}}>Welcome to Prolaskool!</span> Your online learning partner in helping you achieve your educational goals. 
                If you are looking for learning resources to practice and prepare in advance for any of the competitive exams, then you are at the right place!
                </p>
                <span style={{paddingTop:"10px",fontSize:"25px", fontWeight:"bold", textAlign:"center",fontFamily:"Quicksand"}}>Features:</span>
                <hr></hr>
                <p className='bulletPara'>
                •	Prolaskool is a one-stop learning platform for test practice and preparation. </p>
                <p className='bulletPara'>
                •	You can access mock tests for various competitive exams, such as NEET, CET, CAT, banking, IAS, etc. </p>
                <p className='bulletPara'>
                •	These mock test series are curated by highly qualified experts and professionals from IITs, NITs, premier universities and institutions with decades of experience in the educational sector. 
                </p>
                <p className='bulletPara'>
                •	Our online mock tests help you to get used to the test pattern, evaluate your test scores, find areas of improvement, and prepare well to get higher scores and ranking in the main competitive exam. 
                </p>
                <p className='bulletPara'>
                •	These mock tests are conducted in the same manner as the main competitive exams in terms of evaluation, scoring, and ranking. 
                </p>
                <p className='bulletPara'>
                •	You can enroll for any mock test of your choice and take the test along with other students from different regions. 
                </p>
                <p className='bulletPara'>
                •	You can also view your test scores and overall ranking among all the candidates who have taken the specific mock test. 
                </p>
                <p className='bulletPara'>
                •	You can either take the free online mock tests or enrol for the scheduled mock tests.

                <br/>
                <br />
                <span style={{ font: "italic 1.4em Georgia, Times New Roman, Times, serif", color:"darkblue",fontWeight:"bolder",textAlign:"justify",fontFamily:"Quicksand"}}>&quot;To succeed, work hard, never give up and above all, cherish a magnificent obsession.&quot; —Walt Disney</span>
                </p></div></p>
            </div>
            <div className="FeatureBoxContainer2ForHome">
                <div className="FeatureBox2">
                  
                </div>
                <div className="FeatureBoxContent2ForHome">
                    <div className="support12Home">
                    
                    <img className="centerImage" src={require("../../assets/images/mock_test.png").default}  alt="Online Mockup Test" />
                   
                    <p><span style={{fontSize:"17px", fontWeight:"bolder",marginLeft:"36%",fontFamily:"Quicksand"}}>Mock Tests</span><br /><br />
                   
                     <span style={{fontSize:"16px", fontWeight:"bold",fontFamily:"Quicksand"}}>You can access mock tests for various competitive exams, such as NEET, CET, banking, IAS, etc. These mock tests are curated by experts and professionals with decades of experience and expertise in the educational sector.</span>
                    
                    </p>
                    </div>
                    <div className="support12Home">
                    <img className="centerImage" src={require("../../assets/images/enrollment.png").default}   alt="Enroll" />
                    <p><span style={{fontSize:"17px", fontWeight:"bolder",marginLeft:"44%",fontFamily:"Quicksand"}}>Enroll</span><br /><br />
                     <span style={{fontSize:"16px", fontWeight:"bold",fontFamily:"Quicksand"}}>You can enrol for any mock test of your choice and take the test along with other students from different regions. These mock tests are conducted in the same manner as the main competitive exams in terms of evaluation, scoring, and ranking.</span>
                    </p>
                    </div>
                    <div className="support12Home">
                    <img className="centerImage" src={require("../../assets/images/results.png").default}  alt="Results" />
                    <p><span style={{fontSize:"17px", fontWeight:"bolder",marginLeft:"41%",fontFamily:"Quicksand"}}>Results</span><br /><br />
                     <span style={{fontSize:"16px", fontWeight:"bold",fontFamily:"Quicksand"}}>Candidates can view their test scores and overall ranking among all the candidates who have taken the specific mock test.</span>
                    </p>
                    </div>
                </div>
            </div>
            <p style={{textAlign:'center',marginTop:"6%"}}><span style={{fontSize:"40px", fontWeight:"bold", textAlign:"center",fontFamily:"Quicksand",color:"#042A9A"}}>Gallery</span><br /><br /></p>
            <div className="MultiScreenImageContainer2">
                <div className="MultiScreenImage2">
                  <img src={require('../../assets/images/multiscreen.png').default} alt="multi" width="70%"></img>  
                </div>
                    {/* <div className="notchimage2">
                    <img src={require('../../assets/images/better.png')} alt="better" width="100%"></img>
                    
                    <div className="BetterExperienceContent2">
                        <p style={{fontFamily:"Quicksand"}}><span style={{fontFamily:"Quicksand"}}>We are committed to give a</span><br />
                        better experience<br />
                        <span style={{fontSize:"1vw",fontWeight:"bold",fontFamily:"Quicksand"}}>Prolaskool is a learning platform for students to prepare for various competitive exams. </span><br />
                        <Link to='/aboutus' style={{textDecoration:'none'}}>
                        <button style={{fontSize:"1.3vw",fontFamily:"Quicksand"}} className="LoginButton2" >About Us</button>
                        </Link>
                        </p>
                        </div>
                </div> */}
            </div>


            <div className="carousel-container-custom" style={{
                  paddingTop: "4em",
                  marginBottom: "4em"
            }}>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots
                responsive={responsive}
                centerMode={false}
                arrows
                additionalTransfrom={0}
                infinite={true}
                autoPlay={true}
                keyBoardControl
                autoPlaySpeed={1000}
                transitionDuration={1000}
                containerClass="carousel-container-1"
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customRightArrow={<CustomRight />}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                customLeftArrow={<CustomLeft />}
              >
                <div className="carousel-item active">
                  <img  className="d-block w-100" src={require("./Assets/carousel/img_1.png").default} style={{width: imgWidth}} alt="carousel image 1" />
                </div>
                <div className="carousel-item active">
                  <img className="d-block w-100" src={require("./Assets/carousel/img_2.png").default} style={{width: imgWidth}} alt="carousel image 2" />
                </div>
                <div className="carousel-item active">
                  <img className="d-block w-100" src={require("./Assets/carousel/img_3.png").default} style={{width: imgWidth}} alt="carousel image 3" />
                  </div>
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={require("./Assets/carousel/img_4.png").default} style={{width: imgWidth}} alt="carousel image 4" />
                  </div>
                </Carousel>
            </div>

            {/* <div className="halfscreenImageContainer2">
                <div className="halfImagecontent2">
                    <p style={{fontSize:"40px",fontWeight:"bold"}}>One Stop for All <span style={{color:"aqua"}}>MCQ</span><br />
                    Mockups <br />
                    <span style={{fontSize:"14px",fontWeight:"bold"}}>An app which makes up of focus of uncovered topic......</span>
                    <br />
                    <Link to='/login' style={{textDecoration:'none'}}>
                    <button className="halfScreenLogiinButton2"><span style={{fontSize:"20px", fontWeight:'bold'}}>Login</span></button>
                    </Link>
                     </p>
                </div>
                <div className="halfScreenImage2">
                   <img src={require('../../assets/images/systemhalfimage.png')} alt="SDFGHJ" width="100%"></img> 
                </div>
            </div> */}
            <div className="neetpage1">
          <div className="upperimage1"></div>
          {/* <div className="conatainer31">
            <div className="neetpageconetent1">
              <p className="contentlet1">
                <strong style={{fontFamily:"Quicksand"}}>
                  Let&apos;s see what people
                  <br />
                  says about us who
                  <br />
                  joined their hands
                  <br />
                  with us.
                </strong>
              </p>
            </div>
            <div className="neetpageimage1">
              <img
                src={require("../../assets/images/neetEra.png")}
                width="70%"
                style={{marginLeft:"30%"}}
              />
            </div>
          </div> */}
        </div>
        {/* <div className="conatainerr31">
          <div className="conatainerbackgroundimage1">
            <img
              src={require("../../assets/images/convacat.png")}
              width="100%"
            />
            <div className="conatainercontent1">
              <p style={{ fontWeight: "bold" }}>
                We are committed to give a<br />
                better experience.
                <br />
                <span style={{ fontSize: "1vw" }}>
                  Lorem ipsum dolar sit amet, consectectur incididunt ut labore
                  et dolore magna
                </span>
                <br />
                <Link to="/aboutus" style={{ textDecoration: "none" }}>
                  <button
                    
                  className="HomePageAboutUsButton"
                  >
                    About Us
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="featureCarosuel1"> 
          <div className="blueBackground">
            <div className="blue"> */}
              {/* <div className="">
                <div className="carousel-container-custom" style={{
                    paddingTop: "4em",
                  }}>
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots
                    responsive={responsive}
                    centerMode={false}
                    arrows
                    additionalTransfrom={0}
                    infinite={true}
                    autoPlay={true}
                    keyBoardControl
                    autoPlaySpeed={1000}
                    transitionDuration={1000}
                    containerClass="carousel-container-2"
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    customRightArrow={<CustomRight />}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    customLeftArrow={<CustomLeft />}
                  >
                    <div className="carousel-item active FeatureBorder1">
                      <div className="FeatureBox11">
                        <img
                          className="avataraimg1"
                          src={require("../../assets/images/Avatar.png").default}
                          width="20%"
                        />
                        <p>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "blue",
                              fontFamily:"Quicksand"
                            }}
                          >
                            Feature 1
                          </span>
                          <br />
                          <span style={{ fontSize: "8px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Founder, CEO
                          </span>
                          <br />
                          <span style={{ fontSize: "14px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Lorem ipsum dolar sit amet, consectectur
                            <br />
                            adipiscing elit, sed do eiusumond tempor
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item active FeatureBorder1">
                      <div className="FeatureBox11">
                        <img
                          className="avataraimg1"
                          src={require("../../assets/images/Avatar.png").default}
                          width="20%"
                        />
                        <p>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "blue",
                              fontFamily:"Quicksand"
                            }}
                          >
                            Feature 1
                          </span>
                          <br />
                          <span style={{ fontSize: "8px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Founder, CEO
                          </span>
                          <br />
                          <span style={{ fontSize: "14px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Lorem ipsum dolar sit amet, consectectur
                            <br />
                            adipiscing elit, sed do eiusumond tempor
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item active FeatureBorder1">
                      <div className="FeatureBox11">
                        <img
                          className="avataraimg1"
                          src={require("../../assets/images/Avatar.png").default}
                          width="20%"
                        />
                        <p>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "blue",
                              fontFamily:"Quicksand"
                            }}
                          >
                            Feature 1
                          </span>
                          <br />
                          <span style={{ fontSize: "8px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Founder, CEO
                          </span>
                          <br />
                          <span style={{ fontSize: "14px", fontWeight: "bold",fontFamily:"Quicksand" }}>
                            Lorem ipsum dolar sit amet, consectectur
                            <br />
                            adipiscing elit, sed do eiusumond tempor
                          </span>
                        </p>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div> */}
               <div style={{paddingBottom:"2%",backgroundColor:"#e8e6e6"}}>
                 
                 <IntrestSubmition  />
                 </div>           





              {/* <div className="RectangleFlexBox1">
                <div className="RectangleBox11"></div>
                <div className="RectangleBox11"></div>
                <div className="RectangleBox11"></div>
                <div className="RectangleBox11"></div>
                <div className="RectangleBox11"></div>
                <div className="RectangleBox11"></div>
              </div> */}
              {/* <div className="upComingImageSection1">
                <img
                  src={require("../../assets/images/upcoming1.png")}
                  alt="up"
                ></img>
              </div> */}
              {/* </div>
             </div>
            </div> */}
          {/* </div>
        </div> */}
        
                {/* <div className="completedImage2">
                    <img src={require('../../assets/images/complition.png')} alt="djhg" width="100%" height="100%"></img>
                    <div className="completedContent2">
                    <p><span>You are not</span></p>
                    </div>
                </div>
                <div className="PaymentAndSupport2">
            <div className="payment2">
                <img src={require('../../assets/images/upcoming.png').default} width="30%" style={{marginRight:"7%"}}></img>
                <div className="contamination2">
                    <p ><span style={{fontWeight:"bolder", color:"blue", fontSize:"20px"}}>Payment</span>
                    <br />
                    <br />
                    <span style={{fontSize:"small",color:"blue", fontWeight:"bold"}}>
                    Lorem ipsum dolor sit amet, consecteur<br />
                    adiicing eli, sed to elusmod tempor incididunt<br />
                    ut labore et dolore magna aliqua. Ut enim add </span></p>
                    
                </div>
            </div>
            <div className="support2">
                <img src={require('../../assets/images/clock.png').default} width="30%" style={{marginRight:"7%"}}></img>
                <div className="contamination2">
                    <p><span style={{fontWeight:"bolder", color:"blue", fontSize:"20px"}}>24/7 support</span>
                    <br />
                    <br />
                    <span style={{fontSize:"small",color:"blue", fontWeight:"bold"}}>Lorem ipsum dolor sit amet, consecteur<br />
                    adiicing eli, sed to elusmod tempor incididunt<br />
                    ut labore et dolore magna aliqua. Ut enim add</span></p>
                </div>
            </div>
        </div> */}
        </div>
    )
    }
}
export {MainBodyOnlineMockUp}