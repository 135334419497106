import * as React from "react";
import "./style.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as testActionCreators from "../../redux/test/test.actions";
import {Button, Card, Modal} from "react-bootstrap";
import axios from "axios";
import { base_url } from '../../config/constants';

class TestEnrollComponent extends React.Component<any> {
    state = {
        initializeErr: false,
        paymentSuccess: false
    }

    initializePayment = async () => {
        const userId = this.props.user.uid;
        const examId = this.props.currentExamId;
        const testId = this.props.currentTestId;

        const response = await axios.post(
            `${base_url}/api/PaymentGateway/InitializePayment`,
            {userId, examId, testId}
        );

        if (response.data.InitializeStatus) {
            const options = {
                order_id: response.data.OrderId,
                amount: response.data.Amount,
                currency: "INR",
                name: 'ProlaSkool',
                description: 'ProlaSkool',
                prefill: {
                    name: (`${this.props.user?.firstName ?? ''} ${this.props.user?.lastName ?? ''}`).trim(),
                    contact: this.props.user?.mobileNumber ?? '',
                    email: this.props.user?.emailId ?? ''
                },
                handler: async (resp) => {
                    const paymentResp = await axios.post(
                        `${base_url}/api/PaymentGateway/CompletePayment`,
                        {
                            userId,
                            examId,
                            testId,
                            "OrderId": resp.razorpay_order_id,
                            "PaymentId": resp.razorpay_payment_id,
                            "Signature": resp.razorpay_signature,
                            "IsPaymentSuccessful": 1
                        }
                    );

                    if (paymentResp.data.CompletionStatus) {
                        this.setState({paymentSuccess: true})
                    }
                },
                theme: {
                    "color": "#3399cc"
                }
            }

            const rzp1 = new (window as any).Razorpay(options);
            rzp1.on('payment.failed', function(rzpResponse) {
                axios.post(
                    `${base_url}/api/PaymentGateway/CompletePayment`,
                    {
                        userId,
                        examId,
                        testId,
                        "OrderId": response.data.OrderId,
                        "IsPaymentSuccessful": 0,
                        "FailedReason": rzpResponse.error.description
                    }
                );
            });
            rzp1.open();
        } else {
          this.setState({initializeErr: true})
        }
    }
    render() {
        return (
            <div className="container-fluid results-container">
                {
                    this.state.paymentSuccess && <Modal show={true}>
                        <Modal.Body>Payment Successful</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => {
                                const {
                                    history: { replace },
                                } = this.props;

                                replace("/dashboard");
                            }
                            }>
                                Okay
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
                <div className="test-enroll">
                    <Card className="text-center">
                        <Card.Header><strong>Test Enrollment</strong></Card.Header>
                        <Card.Body>
                            {this.state.initializeErr && <div className="alert alert-danger" role="alert">
                                <p>Having some issue with initializing payment, Please try again later.</p>
                            </div>}
                            <form>
                                <div className="form-group row">
                                    <label htmlFor="testName" className="col-sm-6 col-form-label"><strong>Test Name</strong></label>
                                    <div className="col-sm-6">
                                        <input type="text" readOnly className="form-control-plaintext" id="testName"
                                               value={this.props.testName} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="amount" className="col-sm-6 col-form-label"><strong>Amount</strong></label>
                                    <div className="col-sm-6">
                                        <input type="text" readOnly className="form-control-plaintext" id="amount"
                                               value={`₹ ${this.props.testAmount}`} />
                                    </div>
                                </div>
                            </form>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Button
                                    type="button"
                                    variant="outline-danger"
                                    onClick={() => {
                                        const {
                                            history: { replace },
                                        } = this.props;
                                        replace("/dashboard");
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button variant="success" onClick={this.initializePayment}>Pay Now</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

const TestEnroll = connect(
    (state: any) => {
        return {
            testName: state.test.testName,
            examName: state.test.examName,
            testAmount: state.test.testAmount,
            currentTestId: state.test.currentTestId,
            currentExamId: state.test.currentExamId,
            user: state.users.user
        };
    },
    (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(TestEnrollComponent);

export { TestEnroll };
