import * as React from "react";
import "./navbar.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
import { Link } from "react-router-dom";
// import { MdDrafts } from "react-icons/md";

class NavbarBody extends React.Component<any> {
  public state: any = {};
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((state: any) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }
  render() {
    return (
      <div>
        <div className="firstSheet1">
          <header>
            <a href="/" className="logo5" style={{ color: "white" }}>
              <img
                src={require("../../assets/images/site-logo.png").default}
                height="130%"
                alt="logo"
              />
            </a>
            <div className="menuToggle5" onClick={this.handleClick}>
              <img
                className="magese5"
                src={require("../../assets/images/menu.png").default}
                width="80%"
                style={{ paddingBottom: "10px" }}
              />
            </div>
            <nav className={this.state.isToggleOn ? "active2" : null}>
              <ul>
                <li>
                  <a href="#" className="active2"></a>
                </li>
                <li>
                  <Link
                    to="/home"
                    style={{ color: "black", fontFamily: "Quicksand" }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/aboutus"
                    style={{ color: "black", fontFamily: "Quicksand" }}
                  >
                    About Us
                  </Link>
                </li>

                <li>
                  <Link
                    to="/onlineMockUp"
                    style={{ color: "black", fontFamily: "Quicksand" }}
                  >
                    Online Mockup
                  </Link>
                </li>

                <li>
                  <Link
                    to="/testimonial"
                    style={{ color: "black", fontFamily: "Quicksand" }}
                  >
                    Testimonials
                  </Link>
                </li>
                {/* <Link to='/' style={{textDecoration:'none'}}>
                                  <li><a href="#" style={{color:'black'}}>Contact Us</a></li>
                                  </Link > */} 
                <li>
                  <Link
                    to="/contact"
                    style={{ color: "black", fontFamily: "Quicksand" }}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <Link to='/login' style={{textDecoration:'none'}}>
                                  <li><a href="#" style={{color:'black',fontFamily:"Quicksand"}}>Login</a></li>
                                  </Link> */}
              </ul>
            </nav>
            <div className="clearfix5"></div>
          </header>
          <div className="responsive-container">
            <div className="row-col-1">
              <div className="FirstRow2">
                <div className="conent12">
                  <p style={{ padding: 0, margin: 0 }}>One Stop for All</p>
                  <p style={{ padding: 0, margin: 0 }}>
                    <div>
                      <span style={{ color: "#042A9A" }}>MCQ</span>
                      <span>Mockups</span>
                    </div>
                    <span
                      style={{
                        fontSize: "16px",
                        margin: 0,
                        fontFamily: "Quicksand",
                      }}
                    >
                      Your learning partner in helping you achieve your
                      educational goals..
                    </span>
                  </p>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <button
                      type="submit"
                      className="LoginButton2"
                      style={{
                        margin: 0,
                        padding: "3%",
                        fontFamily: "Quicksand",
                      }}
                    >
                      Get started
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row-col-2" style={{ maxWidth: "48%" }}>
              <img
                src={require("./Assets/home_1.jpg").default}
                alt="hero"
                width="70%"
              />
            </div>
          </div>

          {/* <div className="FirstImage2" style={{maxWidth: '100%'}}>
                      <img src={require('../../assets/images/SystemImage.png')} alt="hero" width="70%"/>
                  </div> */}
        </div>

        {/* <div className="emailContentContainer">
            <div className="content">
              <p className="contentpara">LET US CONNECT EACH OTHER</p> 
            </div>
              <div className="content1">
                  <input className="inputcontent" type="text" placeholder="Enter the Text....."></input>
              </div>
                <div className="submitEmail">
                  <span>SEND</span> 
              </div>
          </div>  */}

        <p style={{ textAlign: "center", paddingTop: "4em" }}>
          <span
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "Quicksand",
              color: "#042A9A",
            }}
          >
            Why PROLASKOOL?
          </span>
        </p>
        <div className="main-features">
          <div className="row">
            <div
              id="cardAlignment"
              className="column"
              style={{ paddingTop: "30px", display: "inline" }}
            >
              <div className="feature-container">
                <div className="feature-card">
                  <div className="card-body" style={{ textAlign: "left" }}>
                    <img
                      className="centerImage"
                      src={
                        require("../../assets/images/online_test_icon_old.png")
                          .default
                      }
                      alt="online_exam_test"
                    />
                    <h3 style={{ textAlign: "center" }}>
                      <strong>All-India online mock test</strong>
                    </h3>
                    <hr></hr>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Understand the main exam paper pattern
                      </strong>
                    </h6>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Compare your overall scores and ranking with
                        other candidates
                      </strong>
                    </h6>
                    <h6>
                      <strong>
                        &#10003; Identify the challenges you could face in the
                        actual exam
                      </strong>
                    </h6>
                    <h6>
                      <strong>
                        &#10003; Include a balanced proportion of easy, medium,
                        and difficult questions from all subjects
                      </strong>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="cardAlignment"
              className="column"
              style={{ paddingTop: "30px", display: "inline" }}
            >
              <div className="feature-container">
                <div className="feature-card">
                  <div className="card-body" style={{ textAlign: "left" }}>
                    <img
                      className="centerImage"
                      src={
                        require("../../assets/images/online_test_benefits.png")
                          .default
                      }
                      alt="benefits of online_exam_test"
                    />
                    <h3 style={{ textAlign: "center" }}>
                      <strong>Benefit from the tests</strong>
                    </h3>
                    <hr></hr>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Identify your strengths and areas of
                        improvement in each subject
                      </strong>
                    </h6>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Help you evaluate your subject knowledge{" "}
                      </strong>
                    </h6>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Improve your time management skills
                      </strong>
                    </h6>
                    <h6>
                      <strong>
                        &#10003; Reduce the mistakes and improve your test
                        scores and ranking in the main exam
                      </strong>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="cardAlignment"
              className="column"
              style={{ paddingTop: "30px", display: "inline" }}
            >
              <div className="feature-container">
                <div className="feature-card">
                  <div className="card-body" style={{ textAlign: "left" }}>
                    <img
                      className="centerImage"
                      src={
                        require("../../assets/images/online_test_curating.png")
                          .default
                      }
                      alt="benefits of online_exam_test"
                    />
                    <h3 style={{ textAlign: "center" }}>
                      <strong>How we do it?</strong>
                    </h3>
                    <hr></hr>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Simulation of the main competitive exams,
                        including test pattern, timing, scoring, and overall
                        ranking
                      </strong>
                    </h6>
                    <h6 style={{ paddingBottom: "2px" }}>
                      <strong>
                        &#10003; Include a balanced proportion of easy, medium,
                        and difficult questions from all subjects
                      </strong>
                    </h6>
                    <h6>
                      <strong>
                        &#10003; Curated by educational experts with decades of
                        experience in teaching
                      </strong>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const NavbarMobile = connect(
  (state: any) => {
    return {
      isAuthed: state.users.isAuthed,
      isFetching: state.users.isFetching,
      error: state.users.error,
    };
  },
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(NavbarBody);
export { NavbarMobile };
