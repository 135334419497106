import { User } from "../../models";

export enum UserActionTypes {
  UNAUTH_USER = "UNAUTH_USER",
  AUTH_USER = "AUTH_USER",
  FETCHING_USER = "FETCHING_USER",
  FETCHING_USER_FAILURE = "FETCHING_USER_FAILURE",
  FETCHING_USER_SUCCESS = "FETCHING_USER_SUCCESS",
  UPDATE_USER_IMAGE_URL = "UPDATE_USER_IMAGE_URL",
  UPDATE_IS_PROCESSING = "UPDATE_IS_PROCESSING"
}

export const authUser = (uid: string) => {
  return {
    type: UserActionTypes.AUTH_USER,
    uid,
  };
};

export const unauthUserType = () => {
  return {
    type: UserActionTypes.UNAUTH_USER,
  };
};

export const fetchingUser = () => {
  return {
    type: UserActionTypes.FETCHING_USER,
  };
};

export const fetchingUserFailure = (error: string) => {
  return {
    type: UserActionTypes.FETCHING_USER_FAILURE,
    error: error
  };
};

export const fetchingUserSuccess = (user: User) => {
  return {
    type: UserActionTypes.FETCHING_USER_SUCCESS,
    user
  };
};

export const updateUserProfileType = (imageUrl: string) => {
  return {
    type: UserActionTypes.UPDATE_USER_IMAGE_URL,
    imageUrl,
  };
}

export const setProcessing = (isProcessing: boolean) => {
  return {
    type: UserActionTypes.UPDATE_IS_PROCESSING,
    isProcessing,
  };
}
