export interface ITestStartPage {
  createdDate: string;
  noOfUsersTaken: number;
  noOfQuestions: number;
  instructions: Array<string>;
}

export enum TimerType {
  DATE = "DATE",
  TIME_DELAY = "TIME_DELAY",
  TIME_RIGHT = "TIME_RIGHT",
  TIME_OVER = "TIME_OVER",
}
