import { TestActionTypes } from "./test.action-types";
import axios from "axios";
import {errors} from "../../utilities/errors";
import { base_url } from '../../config/constants';

export const addPdfTestDetails = (
  testId,
  examId,
  testName,
  examName
) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.ADD_PDF_TEST_DETAILS,
      testId,
      examId,
      testName,
      examName
    });
  };
}

export const addTestDetails: any = (
  currentTestId,
  currentExamId,
  testName,
  createdDate,
  noOfUsersTaken,
  noOfQuestions,
  timerDate,
  instructions,
  testAmount,
  examName
) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.ADD_TEST_DETAILS,
      currentTestId,
      currentExamId,
      testName,
      createdDate,
      noOfUsersTaken,
      noOfQuestions,
      timerDate,
      instructions,
      testAmount,
      examName
    });
  };
};

export const updateInitialState = (initialSelectedAnswers, initialQuestionsState, initialPerformanceTimer, isLastSession, sessionId) => {
  return (dispatch: any, getState: any) => {
    const {answers, questionState, performanceData} = getState()?.test;
    dispatch({
      type: TestActionTypes.ADD_INITIAL_DATA,
      payload: {
        ...(!answers && {answers: initialSelectedAnswers}),
        ...(!questionState && {questionsState: initialQuestionsState}),
        ...(!performanceData && {performanceData: initialPerformanceTimer}),
        isLastSession,
        sessionId
      }
    })
  }
}

const loopOverQuestions = (questions, answers, questionsState, performanceData) => {
  return questions.forEach(ques => {
    answers.push(ques.selectedOption ? parseInt(ques.selectedOption) : null);
    questionsState.push({
      questionNo: ques.questionNo,
      question: ques.question,
      img: ques.img,
      options: ques.options,
      answered: !!ques.selectedOption,
      visited: !!ques.selectedOption
    });
    const [hh, mm, ss] = ques.timeTakenToAnswer?.split(':');
    const timeTaken = (hh || mm || ss) ? ((parseInt(hh) * 3600) + (parseInt(mm) * 60) + parseFloat(ss)) * 1000 : null;
    performanceData.push(timeTaken)
  })
}

export const resumeTest = (testData: any, isFreeTrial: boolean) => {
  return (dispatch: any) => {
    const testState = Object.keys(testData).reduce((acc, key) => {
      if (typeof testData[key] === 'object' && testData[key] !== null) {
        const subjectName = testData[key].name;
        if (!acc.answers[subjectName]) {
          acc.answers[subjectName] = [];
          acc.questionsState[subjectName] = [];
          acc.performanceData[subjectName] = [];
        }
        loopOverQuestions(testData[key].questions, acc.answers[subjectName], acc.questionsState[subjectName], acc.performanceData[subjectName]);
      } else {
        acc.examTimer = testData.examTimer;
      }
      return acc;
    }, {answers: {}, questionsState: {}, performanceData: {}, examTimer: null, instructions: []})

    if (isFreeTrial) {
      const totalTimeTaken = Object.keys(testState.performanceData).reduce((acc, key) => {
        acc += testState.performanceData[key].reduce((acc2, curr2) => {
           acc2 += curr2 ?? 0
           return acc2;
        }, 0);
        return acc;
     }, 0);

     dispatch(updateTotalTimeTaken(totalTimeTaken));
    }

    dispatch({
      type: TestActionTypes.ADD_RESUME_TEST_DETAILS,
      testState
    })
  }
}

export const updateTotalTimeTaken = (totalTimeTaken: number) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.UPDATE_TOTAL_TIME_TAKEN,
      totalTimeTaken
    });
  };
};

export const updateAnswers = (answers: any, examKey: any) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.ADD_ANSWERS,
      answers,
      examKey,
    });
  };
};

export const updateQuestionsState = (questionsState: any, examKey: any) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.UPDATE_QUESTION_STATE,
      questionsState,
      examKey,
    });
  };
};

export const updateCorrectAnswers = (correctAnswers: any, examKey: any) => {
  return (dispatch: any) => {
    dispatch({
      type: TestActionTypes.UPDATE_CORRECT_ANSWERS,
      correctAnswers,
      examKey,
    });
  };
};

export const updatePerformanceMetrics = (performanceData: any, examKey: any, selectedQuestion: number) => {
  return (dispatch: any, getState: any) => {
    //Add up new time taken to the previous.
    if (getState()?.test?.performanceData?.[examKey] && getState()?.test?.performanceData?.[examKey]?.[selectedQuestion]) {
      const prevTimeTaken = getState()?.test?.performanceData?.[examKey]?.[selectedQuestion];
      const newTimeTaken = performanceData[selectedQuestion];

      performanceData[selectedQuestion] = Number((prevTimeTaken + newTimeTaken).toFixed(2));
    }

    dispatch({
      type: TestActionTypes.UPDATE_PERFORMANCE_DATA,
      performanceData,
      examKey,
    });
  };
};

export const submitTest = (testCompleted = false) => {
  return async (dispatch: any, getState: any) => {
    try {
      const {
        currentTestId: TestId,
        currentExamId: ExamId,
        answers,
        performanceData: performanceTimer,
        sessionId,
        isLastSession
      } = getState().test;
      const {uid: UserId, authToken: AuthToken} = getState().users?.user
      const response = await axios.post(
          `${base_url}/api/Client/UserResponse`,
          {
            AuthToken,
            ExamId,
            TestId,
            UserId,
            answers,
            performanceTimer,
            testCompleted: isLastSession === 1 && testCompleted,
            sessionId,
            isLastSession,
            sessionCompleted: testCompleted
          }
      );

      if (testCompleted) {
        dispatch({
          type: TestActionTypes.SUBMITTED_SUCCESSFULLY,
          response: response
        });
      }
    } catch (error) {
      if (testCompleted) {
        const responseStatus = error?.response?.data?.ResponseStatus ?? null;
        const errorMessage: any = errors.userResponse[responseStatus].msg;
        dispatch({
          type: TestActionTypes.SUBMIT_FAILURE,
          errorMessage
        });
      }
    }
  }
}

export const submitRatings = (ratings: any, comments: any) => {
  return async(dispatch: any, getState: any) => {
    const {currentTestId: TestId, currentExamId: ExamId} = getState().test;
    const {uid: UserId} = getState().users?.user
    await axios.post(
        `${base_url}/api/Client/UserRatings`,
        {
          "userId": UserId,
          "examId": ExamId,
          "testId": TestId,
          ratings,
          comments
        }
    );

    dispatch({
      type: TestActionTypes.RATINGS_SUBMITTED
    });
  }
}

export const resetTestData = () => {
  return {
    type: TestActionTypes.RESET_DATA
  }
}

export const updateFreeTrial = () => {
  return {
    type: TestActionTypes.UPDATE_IS_FREE_TRIAL,
    isFreeTrial: true
  }
}

export const resetAnswer = (subject: any, quesIndex: any) => {
  return async (dispatch: any, getState: any) => {
    const {answers} = getState().test;
    answers[subject][quesIndex] = null;

    dispatch({
      type: TestActionTypes.RESET_ANSWER,
      answers
    })
  }
}
