import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as testActionCreators from "../../redux/test/test.actions";
import parse from 'html-react-parser';
import "./examStartPage.scss";
import { TimerType } from "./examStartPage.types";
import * as moment from 'moment';

class ExamStartPageComponent extends React.Component<any> {
  public state: any = {};
  public timer: any = null;
  public timeout: any = null;

  constructor(props) {
    super(props);
    this.countDown = this.countDown.bind(this);
    this.generateTimerTitle = this.generateTimerTitle.bind(this);
    this.generateTimerString = this.generateTimerString.bind(this);
    this.timer = 0;
    this.timeout = null;

    if (!this.props.isFreeTrial) {
      const currentDate: any = moment();
      const [date, rest] = this.props.timerDate?.split('T');
      const [time] = rest?.split('+')

      const testStartTime: any = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');

      const diffDays = testStartTime.diff(currentDate, 'days');
      const diffTimeInMs = Math.abs(testStartTime - currentDate);

      const [hh, mm, ss] = new Date(diffTimeInMs)
          .toISOString()
          .slice(11, 19)
          .split(":");

      if (currentDate > testStartTime) {
        this.state = {
          time: "EXAM STARTED!",
          showTimer: TimerType.TIME_OVER,
          enableStartBtn: true
        };
      } else if (diffDays > 0) {
        const date = testStartTime.format('DD MMM YYYY');

        this.state = {
          time: date,
          showTimer: TimerType.DATE,
          enableStartBtn: false
        };
      } else if (parseInt(hh) >= 1 || parseInt(mm) >= 60) {
        const time = testStartTime.format('hh:mm A')

        this.state = {
          time: time,
          showTimer: TimerType.TIME_DELAY,
          enableStartBtn: false
        };
      } else {
        this.state = {
          time: {
            hh: parseInt(hh),
            mm: parseInt(mm),
            ss: parseInt(ss),
          },
          showTimer: TimerType.TIME_RIGHT,
          enableStartBtn: false
        };
      }
    } else {
      this.state = {
        time: "EXAM STARTED!",
        showTimer: TimerType.TIME_OVER,
        enableStartBtn: true
      };
    }
  }

  createdDateTime = this.props.createdDate ? new Date(this.props.createdDate) : new Date();

  date = this.createdDateTime.toLocaleString("default", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  countDown() {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.time.ss - 1;
    this.setState({
      time: {
        ...this.state.time,
        ss: seconds,
      },
    });

    // Check if we're at zero.
    if (seconds === -1 && this.state.time.mm > 0) {
      this.setState({
        time: {
          ...this.state.time,
          mm: this.state.time.mm - 1,
          ss: 59,
        },
      });
    } else if (this.state.time.mm === 0 && seconds === -1) {
      this.setState({
        time: {
          ...this.state.time,
          mm: 0,
          ss: 0,
        },
      });
      clearInterval(this.timer);
      this.setState({
        time: "EXAM STARTED!",
        showTimer: TimerType.TIME_OVER,
        enableStartBtn: true
      });
      const {
        history: { push },
      } = this.props;
      this.timeout = setTimeout(() => push("/examStart"), 120000);
    }
  }

  componentDidMount() {
    if (this.state.showTimer == TimerType.TIME_RIGHT) {
      this.timer = setInterval(this.countDown, 1000);
    } else if (this.state.showTimer == TimerType.TIME_OVER) {
      const {
        history: { push },
      } = this.props;
      this.timeout = setTimeout(() => push("/examStart"), 120000);
    }
  }

  onClickStartBtn = () => {
    clearTimeout(this.timeout);
    const {
      history: { push },
    } = this.props;

    push("/examStart")
  }

  generateTimerTitle = () => {
    if (this.state.showTimer === TimerType.TIME_OVER) {
      return " ";
    } else if (this.state.showTimer === TimerType.DATE) {
      return "Exam Starts on";
    } else if (this.state.showTimer === TimerType.TIME_DELAY) {
      return "Exam Starts at";
    } else {
      return "Exam Starts in";
    }
  };

  generateTimerString = () => {
    if (
      this.state.time.mm.toString().length === 1 &&
      this.state.time.ss.toString().length === 1
    ) {
      return `0${this.state.time.mm} :0${this.state.time.ss}`;
    }
    if (this.state.time.mm.toString().length === 1) {
      return `0${this.state.time.mm} : ${this.state.time.ss}`;
    }
    if (this.state.time.ss.toString().length === 1) {
      return `${this.state.time.mm} : 0${this.state.time.ss}`;
    } else {
      return `${this.state.time.mm} : ${this.state.time.ss}`;
    }
  };

  render(): JSX.Element {
    return (
      <div
        className="container-fluid examStartPage"
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="container-fluid examStartPage-content-container">
          <div className="test-details-container">
            <h2 className="exam-title">{this.props.examName} - {this.props.testName}</h2>
            <div className="test-details">
              <div className="test-detail">
                 {/* <h6 className="test-detail-title">Created Date :</h6> */}
                 {/*<p className="test-detail-value">{this.date}</p>*/}
              </div>
              <div className="test-detail">
                <h6 className="test-detail-title">No. of Users Taken :</h6>
                <p className="test-detail-value">{this.props.noOfUsersTaken}</p>
              </div>
              <div className="test-detail">
                <h6 className="test-detail-title">No. of Questions :</h6>
                <p className="test-detail-value">{this.props.noOfQuestions}</p>
              </div>
            </div>
          </div>

          <div className="timer-container">
            <p className="timer-title">{this.generateTimerTitle()}</p>
            <h1 className="timer-text">
              {this.state.showTimer === TimerType.TIME_RIGHT
                ? this.generateTimerString()
                : `${this.state.time}`}
            </h1>
          </div>

          <div className="instructions-container">
            <h3 className="instructions-title">Instructions</h3>
            <div className="d-flex flex-column text-left">
              {this.props.instructions && parse(this.props.instructions)}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
                type="button"
                className="btn btn-lg btn-success button return-button"
                onClick={this.onClickStartBtn}
                disabled={!this.state.enableStartBtn}
            >
              Start Test
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const ExamStartPage = connect(
  (state: any) => {
    return {
      currentTestId:state.test.currentTestId,
      currentExamId:state.test.currentExamId,
      testName: state.test.testName,
      examName: state.test.examName,
      createdDate: state.test.createdDate,
      noOfUsersTaken: state.test.noOfUsersTaken,
      noOfQuestions: state.test.noOfQuestions,
      timerDate: state.test.timerDate,
      instructions: state.test.instructions,
      user: state.users.user,
      isFreeTrial: state.test.isFreeTrial
    };
  },
  (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(ExamStartPageComponent);

export { ExamStartPage };
