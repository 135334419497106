import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as testActionCreators from "../../redux/test/test.actions";
import DonutChart from "react-donut-chart";
import { MdKeyboardArrowDown } from "react-icons/md";
import { base_url } from '../../config/constants';

import "./results.scss";
import MathJax from "react-mathjax-preview";
import { millisToMinutesAndSeconds } from "../../utilities/time";
import {formatResponseData} from "../../utilities/helper";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";

class ResultsComponent extends React.Component<any> {
  state = {
    selectedQuestion: 1,
    results: null,
    questionsState: null,
    apiError: ''
  };

  async componentDidMount() {
    const examId: any = this.props.isPdfView ? this.props.pdfExamId : this.props.currentExamId;
    const testId: any = this.props.isPdfView ? this.props.pdfTestId : this.props.currentTestId;

    const results = await axios.post(
        `${base_url}/api/Client/GetResults`,
        {
          AuthToken: this.props.user.authToken,
          UserId: this.props.user.uid,
          ExamId: examId,
          TestId: testId,
        }
    );
    
    if (results.status === 200) {
      this.setState({
        results: formatResponseData(results),
      });
    } else {
      this.setState({
        apiError: results.data.Message
      })
    }
  }

  checkIfWrongOption = (optionNumber, idx, subject) => {
    if (this.state.results.correctAnswers[subject][idx]) {
      if (
        this.state.results.correctAnswers[subject][idx].option !==
          this.state.results.answers[subject][idx] &&
        this.state.results.answers[subject][idx] === optionNumber
      ) {
        return "wrong-option";
      } else return "";
    } else {
      return "";
    }
  };

  checkIfWrongAnswer = (idx, subject) => {
    if (this.state.results.correctAnswers[subject][idx]) {
      if (
        this.state.results.correctAnswers[subject][idx].option ===
        this.state.results.answers[subject][idx]
      ) {
        return "correct";
      } else if (this.state.results.answers[subject][idx] === null) {
        return "unattended";
      } else {
        return "wrong";
      }
    } else {
      return "";
    }
  };

  checkRadioButtonChecked = (optionNumber, idx, subject) => {
    if (this.state.results.correctAnswers[subject][idx]) {
      if (this.state.results.answers[subject][idx] === null) {
        return false;
      } else if (
        optionNumber ===
          this.state.results.correctAnswers[subject][idx].option ||
        optionNumber === this.state.results.answers[subject][idx]
      )
        return true;
      else {
        return false;
      }
    } else {
      return false;
    }
  };

  calcTimeTaken = (subject, idx) => {
    if (this.state.results.performanceTimer[subject][idx] !== null) {
      return (
        millisToMinutesAndSeconds(this.state.results.performanceTimer[subject][idx]) +
        " m"
      );
    } else {
      return "Not Attended";
    }
  };

  calcAvgTimeTaken = (subject, idx) => {
    if (this.state.results.correctAnswers[subject][idx]?.avgTimeTaken !== null) {
      return (
        millisToMinutesAndSeconds(this.state.results.correctAnswers[subject][idx]?.avgTimeTaken) +
        " m"
      );
    } else {
      return "Not Attended";
    }
  };

/*   getElaborationHtml = (correctAnswer, subject, index) => {
    const hasData = false && correctAnswer && subject && index !== undefined;
    let elaboration: any = "";
    if (hasData) {
      const data = correctAnswer[subject][index].elaboration;
      // elaboration = parse(data);
    }
    return elaboration;
  }; */

  getYourAnswer = (subject, idx) => {
    const selectedOption = this.state.results.answers[subject][idx];
    if (selectedOption !== null) {
      switch (selectedOption) {
        case 1:
          return 'A';
        case 2:
          return 'B';
        case 3:
          return 'C';    
        case 4:
          return 'D';
      }
    } else {
      return "Not Attended";
    }
  };

  getCorrectAnswer = (subject, idx) => {
    const selectedOption = this.state.results.correctAnswers[subject][idx]?.option;
    if (selectedOption !== null) {
      switch (selectedOption) {
        case 1:
          return 'A';
        case 2:
          return 'B';
        case 3:
          return 'C';    
        case 4:
          return 'D';
      }
    } else {
      return "Not Attended";
    }
  };

  renderQuestions = (subject) => {
    return this.state.results.questionsState[subject]?.map((questionState, idx) => {
      const isQuestionImg = questionState.question?.indexOf('<img') !== -1;
      const isQuestionBlank = questionState.question === '';
      const doesQuestionHaveMathType=questionState.question.includes("<math");
      const doesOption1HaveMathType=questionState.options[0].option;
      const doesOption2HaveMathType=questionState.options[1].option;
      const doesOption3HaveMathType=questionState.options[2].option;
      const doesOption4HaveMathType=questionState.options[3].option;

      const isOption1Image=questionState.options[0].option?.indexOf('<img')!==-1;
      const isOption2Image=questionState.options[1].option?.indexOf('<img')!==-1;
      const isOption3Image=questionState.options[2].option?.indexOf('<img')!==-1;
      const isOption4Image=questionState.options[3].option?.indexOf('<img')!==-1;

      const doesAnswerHaveMathType=this.state.results.correctAnswers[subject][idx].elaboration.includes('<math');
                    
      
      return (
        <div
          className={`answer-container`}
          key={idx}
        >
          <div className="container-fluid questions-container">
            <div className="question-number" style={{ fontWeight: 600}}>{`${idx + 1})`}</div>
            <div className="question-title">
              {!isQuestionImg && !isQuestionBlank && doesQuestionHaveMathType &&
              
              <MathJax math={String.raw`${questionState.question}`} />
              
              
              }

            {!isQuestionImg && !isQuestionBlank && !doesQuestionHaveMathType &&
              
              <p dangerouslySetInnerHTML={{__html: questionState.question}}>
                </p>
              
              
              }

              {(isQuestionBlank || isQuestionImg) && <div className="container-fluid img-container">
                {questionState.img ? (
                    <img
                        className="img-fluid"
                        src={questionState.img}
                        alt="Question Image"
                    />
                ) : null}
              </div>}
            </div>
            <MdKeyboardArrowDown
              className="collapse-button"
              data-toggle="collapse"
              href={`#collapseExample${subject.replace(/\s/g, "")}${idx + 1}`}
              aria-expanded={false}
              aria-controls={`collapseExample${subject}${idx + 1}`}
            />
          </div>
          <div className="container-fluid content-container">
            <div className="container-fluid form-check options-container">
              <div className="options-sub-container-left">
                <div className="options-input-img-container">
                  <div className="option-input-container">
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600}}>
                      A)
                    </div>&nbsp;&nbsp;
                    <div className="d-flex">
                      <input
                        className={`option-input ${this.checkIfWrongOption(
                          1,
                          idx,
                          subject
                        )}`}
                        type="radio"
                        name={`radioOptions1${subject}${idx}`}
                        id="radioOption1"
                        value={1}
                        defaultChecked={this.checkRadioButtonChecked(
                          1,
                          idx,
                          subject
                        )}
                        disabled
                      />
                    </div>
                    <label className="form-check-label" htmlFor="radioOption1">
                      {/* <MathJax
                        math={String.raw`${questionState.options[0].option}`}
                      /> */}

                      {doesOption1HaveMathType && <MathJax
                        math={String.raw`${
                          questionState.options[0].option
                        }`}
                      />}

                      {!isOption1Image && !doesOption1HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: questionState.options[0].option}}>
                    </p>  }


                    </label>
                  </div>
                  {questionState.options[0].img ? (
                    <img
                      className="img-fluid"
                      src={questionState.options[0].img}
                      alt="Question Image"
                    />
                  ) : null}
                </div>
                <div className="options-input-img-container">
                  <div className="option-input-container">
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600}}>
                      B)
                    </div>&nbsp;&nbsp;
                    <div className="d-flex">
                      <input
                        className={`option-input ${this.checkIfWrongOption(
                          2,
                          idx,
                          subject
                        )}`}
                        type="radio"
                        name={`radioOptions2${subject}${idx}`}
                        id="radioOption2"
                        value={2}
                        defaultChecked={this.checkRadioButtonChecked(
                          2,
                          idx,
                          subject
                        )}
                        disabled
                      />
                    </div>
                    <label className="form-check-label" htmlFor="radioOption2">
                    {doesOption2HaveMathType && <MathJax
                        math={String.raw`${
                          questionState.options[1].option
                        }`}
                      />}

                      {!isOption2Image && !doesOption2HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: questionState.options[1].option}}>
                    </p>  }
                    </label>
                  </div>
                  {questionState.options[1].img ? (
                    <img
                      className="img-fluid"
                      src={questionState.options[1].img}
                      alt="Question Image"
                    />
                  ) : null}
                </div>
              </div>

              <div className="options-sub-container-right">
                <div className="options-input-img-container">
                  <div className="option-input-container">
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600}}>
                      C)
                    </div>&nbsp;&nbsp;
                    <div className="d-flex">
                      <input
                        className={`option-input ${this.checkIfWrongOption(
                          3,
                          idx,
                          subject
                        )}`}
                        type="radio"
                        name={`radioOptions3${subject}${idx}`}
                        id="radioOption3"
                        value={3}
                        defaultChecked={this.checkRadioButtonChecked(
                          3,
                          idx,
                          subject
                        )}
                        disabled
                      />
                    </div>
                    <label className="form-check-label" htmlFor="radioOption3">
                    {doesOption3HaveMathType && <MathJax
                        math={String.raw`${
                          questionState.options[2].option
                        }`}
                      />}

                      {!isOption3Image && !doesOption3HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: questionState.options[2].option}}>
                    </p>  }
                    </label>
                  </div>
                  {questionState.options[2].img ? (
                    <img
                      className="img-fluid"
                      src={questionState.options[2].img}
                      alt="Question Image"
                    />
                  ) : null}
                </div>

                <div className="options-input-img-container">
                  <div className="option-input-container">
                    <div className="d-flex" style={{alignItems: 'center', fontWeight: 600}}>
                      D)
                    </div>&nbsp;&nbsp;
                    <div className="d-flex">
                      <input
                        className={`option-input ${this.checkIfWrongOption(
                          4,
                          idx,
                          subject
                        )}`}
                        type="radio"
                        name={`radioOptions4${subject}${idx}`}
                        id="radioOption4"
                        value={4}
                        defaultChecked={this.checkRadioButtonChecked(
                          4,
                          idx,
                          subject
                        )}
                        disabled
                      />
                    </div>
                    <label className="form-check-label" htmlFor="radioOption4">
                    {doesOption4HaveMathType && <MathJax
                        math={String.raw`${
                          questionState.options[3].option
                        }`}
                      />}

                      {!isOption4Image && !doesOption4HaveMathType && 
                      <p dangerouslySetInnerHTML={{__html: questionState.options[3].option}}>
                    </p>  }
                    </label>
                  </div>
                  {questionState.options[3].img ? (
                    <img
                      className="img-fluid"
                      src={questionState.options[3].img}
                      alt="Question Image"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {!isQuestionBlank && !isQuestionImg && <div className="container-fluid img-container">
              {questionState.img ? (
                <img
                  className="img-fluid"
                  src={questionState.img}
                  alt="Question Image"
                />
              ) : null}
            </div>}
          </div>

          <div
            className={`collapse elaboration-container ${this.props.isPdfView ? 'show' : ''}`}
            id={`collapseExample${subject.replace(/\s/g, "")}${idx + 1}`}
          >
            <div className="card card-body elaboration-card" style={{width: '100%'}}>
              <div>
                <span>Your Answer: </span>
                <span>{this.getYourAnswer(subject, idx)}</span>
              </div>
              {this.checkIfWrongAnswer(idx, subject) !== 'correct' && <div>
                <span>Correct Answer: </span>
                <span>{this.getCorrectAnswer(subject, idx)}</span>
              </div>}
              <div>
                <span>Time Taken: </span>
                <span>{this.calcTimeTaken(subject, idx)}</span>
              </div>
              <div>
                <span>Avg Time Taken: </span>
                <span>{this.calcAvgTimeTaken(subject, idx)}</span>
              </div>
              <hr />
               
              <p>Elaboration</p>
              
                {this.state.results.correctAnswers && subject && idx && !doesAnswerHaveMathType ? (
                  
                    <p dangerouslySetInnerHTML={{__html: this.state.results.correctAnswers[subject][idx].elaboration}}>
                    </p>
                ) : ""}

                {this.state.results.correctAnswers && subject && idx && doesAnswerHaveMathType ? (
                  
                  <MathJax
                        math={String.raw`${
                          this.state.results.correctAnswers[subject][idx].elaboration
                        }`}
                      />
              ) : ""}
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (this.state.apiError && !this.props.isPdfView) {
      return (
          <Modal show={true}>
            <Modal.Body>{this.state.apiError}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => {
                const {
                  history: { replace },
                } = this.props;
                this.props.resetTestData();
                replace("/dashboard");
              }
              }>
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
      )
    } else if (
      !this.state.results?.answers ||
      !this.state.results?.questionsState ||
      !this.state.results
    ) {
      return (
        <div className="container-fluid questions-options-container">
          <div className="spinner-table">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>

            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else {
      const showChartsSection = !(this.state.results.isFreeTrialTest || !this.state.results.userSubmitted);
      return (
        <div className="container-fluid results-container">
          <div className="container-fluid congratulations-container">
            <div className="section-top">
              <div className="congratulations-text">Congratulations!</div>
              <h3 className="question-paper-name">
              {this.props.examName}: {this.props.testName}
              </h3>
            </div>
            <div className="section-bottom">
              <img className="congratulations-bg-img" src={require("../../assets/images/congratulations.png")} alt="congratulations bg"></img>
            </div>
          </div>
          {showChartsSection && (
            <>
              <div className="chart">
                <DonutChart
                  startAngle={270}
                  legend={false}
                  width={450}
                  height={450}
                  clickToggle={false}
                  selectedOffset={0}
                  strokeColor="#6CDC8A"
                  colors={["#6CDC8A", "#F2A81D", "#EF0000"]}
                  data={[
                    {
                      label: "Correct Answers",
                      value:
                        this.state.results.noOfCorrectAnswers ?? 0 /
                        this.state.results.totalQuestions,
                    },
                    {
                      label: "Unattended Answers",
                      value:
                        this.state.results.noOfUnattendedAnswers ?? 0 /
                        this.state.results.totalQuestions,
                    },
                    {
                      label: "Wrong Answers",
                      value:
                        this.state.results.noOfWrongAnswers ?? 0 /
                        this.state.results.totalQuestions,
                    },
                  ]}
                />
                <div className="rank-container circle" style={this.props.isPdfView ? {
                    width: '260px',
                    height: "260px",
                    top: "96px",
                    left: "96px",
                    borderRadius: "260px",
                  }: {}
                }>
                  <div className="ranked-text">Ranked</div>
                  <div className="rank-text">{this.state.results.rank}</div>
                </div>
              </div>
              <div className="container-fluid legend-container">
                <div className="legend-content-container">
                  <div className="single-legend green">
                    <div className="legend-title">Correct Answers</div>
                    <div className="legend-value">{`${this.state.results.noOfCorrectAnswers ?? 0}/${this.state.results.totalQuestions}`}</div>
                  </div>
                  <div className="single-legend blue">
                    <div className="legend-title">Unattended Answers</div>
                    <div className="legend-value">{`${this.state.results.noOfUnattendedAnswers ?? 0}/${this.state.results.totalQuestions}`}</div>
                  </div>
                  <div className="single-legend red">
                    <div className="legend-title">Wrong Answers</div>
                    <div className="legend-value">{`${this.state.results.noOfWrongAnswers ?? 0}/${this.state.results.totalQuestions}`}</div>
                  </div>
                </div>
              </div>
              <div className="container-fluid legend-container">
                  <h1 className="average-time-taken">Average Time Taken</h1>
              </div>
              <div className="container-fluid legend-container">
                <div className="legend-content-container">
                  <div className="single-legend">
                    <div className="legend-title">Easy</div>
                    <div className="legend-value">{`${this.state.results.easyAvgTime}sec`}</div>
                  </div>
                  <div className="single-legend">
                    <div className="legend-title">Medium</div>
                    <div className="legend-value">{`${this.state.results.medAvgTime}sec`}</div>
                  </div>
                  <div className="single-legend">
                    <div className="legend-title">Hard</div>
                    <div className="legend-value">{`${this.state.results.hardAvgTime}sec`}</div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="answers-container">
            {this.state.results?.answers && Object.keys(this.state.results.answers).map((subject, idx) => {
              let totalMarks = 0;
              let positiveMarks = 0;
              let negativeMarks = 0;
              if (this.state.results.subjectsChart[subject]?.positiveMarking && this.state.results.subjectsChart[subject]?.noOfCorrectAnswers) {
                positiveMarks = parseInt(this.state.results.subjectsChart[subject]?.positiveMarking, 10) * parseInt(this.state.results.subjectsChart[subject]?.noOfCorrectAnswers, 10)
              }
              if (this.state.results.subjectsChart[subject]?.negativeMarking && this.state.results.subjectsChart[subject]?.noOfWrongAnswers) {
                negativeMarks = parseInt(this.state.results.subjectsChart[subject]?.negativeMarking, 10) * parseInt(this.state.results.subjectsChart[subject]?.noOfWrongAnswers, 10)
              }

              totalMarks = positiveMarks - negativeMarks;

              return (
                <React.Fragment key={idx}>
                  <div className="container-fluid tabs">
                    <button
                      className="btn btn-primary tab-button"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#collapseExample${idx + 1}`}
                      aria-expanded="false"
                      aria-controls={`collapseExample${idx + 1}`}
                    >
                      {subject}
                      <MdKeyboardArrowDown
                        className="collapse-button"
                        data-toggle="collapse"
                        href={`#collapseExample${idx + 1}`}
                        aria-expanded={false}
                        aria-controls={`collapseExample${idx + 1}`}
                      />
                    </button>
                  </div>
                  <div
                    className={`collapse elaboration-container ${this.props.isPdfView ? 'show' : ''}`}
                    id={`collapseExample${idx + 1}`}
                  >
                    {showChartsSection && (
                      <>
                        <div className="chart">
                          <DonutChart
                              startAngle={270}
                              legend={false}
                              width={400}
                              height={400}
                              clickToggle={false}
                              selectedOffset={0}
                              strokeColor="#6CDC8A"
                              colors={["#6CDC8A", "#F2A81D", "#EF0000"]}
                              data={[
                                {
                                  label: "Correct Answers",
                                  value: this.state.results.subjectsChart[subject]?.noOfCorrectAnswers / this.state.results.subjectsChart[subject]?.totalQuestions,
                                },
                                {
                                  label: "Unattended Answers",
                                  value:
                                      this.state.results.subjectsChart[subject]?.noOfUnattendedAnswers /
                                      this.state.results.subjectsChart[subject]?.totalQuestions,
                                },
                                {
                                  label: "Wrong Answers",
                                  value:
                                      this.state.results.subjectsChart[subject]?.noOfWrongAnswers /
                                      this.state.results.subjectsChart[subject]?.totalQuestions,
                                },
                              ]}
                          />
                          <div className="rank-container circle subject" style={this.props.isPdfView ? {
                              borderRadius: "260px",
                            }: {}
                          }>
                            <div className="ranked-text">Total Marks</div>
                            <div className="rank-text">{totalMarks}</div>
                          </div>
                        </div>
                        <div className="container-fluid legend-container">
                          <div className="legend-content-container">
                            <div className="single-legend green">
                              <div className="legend-title">Correct Answers</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.noOfCorrectAnswers}/${this.state.results.subjectsChart[subject]?.totalQuestions}`}</div>
                            </div>
                            <div className="single-legend blue">
                              <div className="legend-title">Unattended Answers</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.noOfUnattendedAnswers}/${this.state.results.subjectsChart[subject]?.totalQuestions}`}</div>
                            </div>
                            <div className="single-legend red">
                              <div className="legend-title">Wrong Answers</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.noOfWrongAnswers}/${this.state.results.subjectsChart[subject]?.totalQuestions}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className="container-fluid legend-container">
                            <h1 className="average-time-taken">Average Time Taken</h1>
                        </div>
                        <div className="container-fluid legend-container subject-legend">
                          <div className="legend-content-container">
                            <div className="single-legend">
                              <div className="legend-title">Easy</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.easyAvgTime}sec`}</div>
                            </div>
                            <div className="single-legend">
                              <div className="legend-title">Medium</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.medAvgTime}sec`}</div>
                            </div>
                            <div className="single-legend">
                              <div className="legend-title">Hard</div>
                              <div className="legend-value">{`${this.state.results.subjectsChart[subject]?.hardAvgTime}sec`}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this.renderQuestions(subject)}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {!this.props?.isPdfView && (
            <div className="return-button-container">
              <button
                type="button"
                className="btn btn-lg btn-success button return-button"
                onClick={() => {
                  const {
                    history: { replace },
                  } = this.props;
                  this.props.resetTestData();
                  replace("/dashboard");
                }}
              >
                Return
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

const Results = connect(
  (state: any) => {
    return {
      testName: state.test.testName,
      examName: state.test.examName,
      createdDate: state.test.createdDate,
      noOfUsersTaken: state.test.noOfUsersTaken,
      noOfQuestions: state.test.noOfQuestions,
      currentExamId: state.test.currentExamId,
      currentTestId: state.test.currentTestId,
      timerDate: state.test.timerDate,
      instructions: state.test.instructions,
      answers: state.test.answers,
      questionsState: state.test.questionsState,
      correctAnswers: state.test.correctAnswers,
      performanceTimer: state.test.performanceData,
      user: state.users.user,
      pdfExamId: state.test?.pdf?.examId || null,
      pdfTestId: state.test?.pdf?.testId || null,
      pdfTestName: state.test?.pdf?.testName || null,
    };
  },
  (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(ResultsComponent);

export { Results };
