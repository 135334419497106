import { TestActionTypes } from "./test.action-types";

const initialState = {
  currentTestId:null,
  currentExamId:null,
  examName: null,
  testName: null,
  createdDate: "",
  noOfUsersTaken: null,
  noOfQuestions: null,
  timerDate: null,
  instructions: null,
  answers: null,
  questionsState: null,
  correctAnswers: null,
  performanceData: null,
  submitted: false,
  ratingsSubmitted: false,
  isLastSession: 1,
  isFreeTrial: false,
  sessionId: null,
  isTestResumed: false,
  testAmount: 0,
  submitFailed: '',
  totalTimeTaken: 0
};

export const test = (state = initialState, action: any) => {
  switch (action.type) {
    case TestActionTypes.ADD_TEST_DETAILS:
      return {
        ...state,
        currentTestId:action.currentTestId,
        currentExamId:action.currentExamId,
        testName: action.testName,
        examName: action.examName,
        createdDate: action.createdDate,
        noOfUsersTaken: action.noOfUsersTaken,
        noOfQuestions: action.noOfQuestions,
        timerDate: action.timerDate,
        instructions: action.instructions,
        testAmount: action.testAmount,
      };
    case TestActionTypes.ADD_PDF_TEST_DETAILS:
      return {
        ...state,
        pdf: {
          testId: action.testId,
          examId: action.examId,
          testName: action.testName,
          examName: action.examName,
        },
      };
    case TestActionTypes.ADD_RESUME_TEST_DETAILS:
      return {
        ...state,
        ...action.testState,
        isTestResumed: true
      }
    case TestActionTypes.ADD_INITIAL_DATA:
      return {
        ...state,
        ...action.payload
      }
    case TestActionTypes.ADD_ANSWERS:
      if (action.answers === null) {
        return {
          ...state,
          answers: {
            ...state.answers,
            [action.examKey]: null,
          },
        };
      } else {
        return {
          ...state,
          answers: {
            ...state.answers,
            [action.examKey]: [...action.answers],
          },
        };
      }

    case TestActionTypes.UPDATE_QUESTION_STATE:
      if (action.questionsState === null) {
        return {
          ...state,
          questionsState: {
            ...state.questionsState,
            [action.examKey]: null,
          },
        };
      } else {
        return {
          ...state,
          questionsState: {
            ...state.questionsState,
            [action.examKey]: [...action.questionsState],
          },
        };
      }

    case TestActionTypes.UPDATE_CORRECT_ANSWERS:
      if (action.correctAnswers === null) {
        return {
          ...state,
          correctAnswers: {
            ...state.correctAnswers,
            [action.examKey]: null,
          },
        };
      } else {
        return {
          ...state,
          correctAnswers: {
            ...state.correctAnswers,
            [action.examKey]: [...action.correctAnswers],
          },
        };
      }
    case TestActionTypes.UPDATE_PERFORMANCE_DATA:
      if (action.performanceData === null) {
        return {
          ...state,
          performanceData: {
            ...state.performanceData,
            [action.examKey]: null,
          },
        };
      } else {
        return {
          ...state,
          performanceData: {
            ...state.performanceData,
            [action.examKey]: [...action.performanceData],
          },
        };
      }
    case TestActionTypes.SUBMITTED_SUCCESSFULLY:
      return {...state, submitted: true, submitFailed: false}
    case TestActionTypes.SUBMIT_FAILURE:
      return {...state, submitFailedMsg: action.errorMessage}
    case TestActionTypes.RATINGS_SUBMITTED:
      return {...state, ratingsSubmitted: true}
    case TestActionTypes.RESET_DATA:
      return {...initialState, testName: state.testName, examName: state.examName, currentTestId: state.currentTestId, currentExamId: state.currentExamId}
    case TestActionTypes.RESET_ANSWER:
      return {...state, answers: state.answers}
    case TestActionTypes.UPDATE_IS_FREE_TRIAL:
      return {...state, isFreeTrial: action.isFreeTrial}
    case TestActionTypes.UPDATE_TOTAL_TIME_TAKEN:
      return {...state, totalTimeTaken: action.totalTimeTaken}  
    default:
      return state;
  }
};
