import * as React from "react";
import "./login.scss";
import { Dropdown } from "./Dropdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
import axios from "axios";
import jwt_decode from "jwt-decode";
import classNames from "classnames";
import { base_url } from "../../config/constants";
import {
  MdEmail,
  MdVpnKey,
  MdPerson,
  MdCheck,
  MdVisibility,
  MdPhone,
} from "react-icons/md";
// import { ILoginComponentProps, ILoginComponentState } from "./login.types";

import { Link } from "react-router-dom";
import { errors } from "../../utilities/errors";

class LoginComponent extends React.Component<any> {
  public push: any;
  public state: any = {};
  constructor(props: any) {
    super(props);
    this.state = {
      emailID: "",
      password: "",
      hidden: true,
      isShowPassword: false,
      isShowConfirmPassword: false,
      isToggleOn: false,
      rememberMe: false,
      loginButtonDisabled: true,
      errorMessage: "",
      errors: "",
      ApiErrors: "",
      registerErrors: {
        emailID: false,
        firstName: false,
        lastName: false,
        mobileNumber: false,
        password: false,
        confirmPassword: false,
        selectedState: false,
        selectedDistrict: false,
      },
      forgotpassword: {
        email: "",
        disableSubmit: false,
      },
      register: {
        Backenderror: "",
        checked: false,
        firstName: "",
        lastName: "",
        emailID: "",
        mobileNumber: "",
        password: "",
        confirmPassword: "",
        countries: [
          {
            name: "Andhra Pradesh",
            states: [
              "Anantapur",
              "Chittoor",
              "East Godavari",
              "Guntur",
              "Krishna",
              "Kurnool",
              "Nellore",
              "Prakasam",
              "Srikakulam",
              "Visakhapatnam",
              "Vizianagaram",
              "West Godavari",
              "YSR Kadapa",
            ],
          },
          {
            name: "Arunachal Pradesh",
            states: [
              "Anjaw",
              "Siang",
              "Changlang",
              "Dibang Valley",
              "East Kameng",
              "East Siang",
              "Kamle",
              "Kra Daadi",
              "Kurung Kumey",
              "Lepa Rada",
              "Lohit",
              "Longding",
              "Lower Dibang Valley",
              "Lower Siang",
              "Lower Subansiri",
              "Namsai",
              "Pakke Kessang",
              "Papum Pare",
              "Shi Yomi",
              "Tawang",
              "Tirap",
              "Upper Siang",
              "Upper Subansiri",
              "West Kameng",
              "West Siang",
            ],
          },
          {
            name: "Assam",
            states: [
              "Bajali",
              "Baksa",
              "Barpeta",
              "Biswanath",
              "Bongaigaon",
              "Cachar",
              "Charaideo",
              "Chirang",
              "Darrang",
              "Dhemaji",
              "Dhubri",
              "Dibrugarh",
              "Goalpara",
              "Golaghat",
              "Hailakandi",
              "Hojai",
              "Jorhat",
              "Kamrup Metropolitan",
              "Kamrup",
              "Karbi Anglong",
              "Karimganj",
              "Kokrajhar",
              "Lakhimpur",
              "Majuli",
              "Morigaon",
              "Nagaon",
              "Nalbari",
              "Dima Hasao",
              "Sivasagar",
              "Sonitpur",
              "South Salmara-Mankachar",
              "Tinsukia",
              "Udalguri",
              "West Karbi Anglong",
            ],
          },
          {
            name: "Bihar",
            states: [
              "Araria",
              "Arwal",
              "Aurangabad",
              "Banka",
              "Begusarai",
              "Bhagalpur",
              "Bhojpur",
              "Buxar",
              "Darbhanga",
              "East Champaran (Motihari)",
              "Gaya",
              "Gopalganj",
              "Jamui",
              "Jehanabad",
              "Kaimur (Bhabua)",
              "Katihar",
              "Khagaria",
              "Kishanganj",
              "Lakhisarai",
              "Madhepura",
              "Madhubani",
              "Munger (Monghyr)",
              "Muzaffarpur",
              "Nalanda",
              "Nawada",
              "Patna",
              "Purnia (Purnea)",
              "Rohtas",
              "Saharsa",
              "Samastipur",
              "Saran",
              "Sheikhpura",
              "Sheohar",
              "Sitamarhi",
              "Siwan",
              "Supaul",
              "Vaishali",
              "West Champaran",
            ],
          },
          {
            name: "Chandigarh (UT)",
            states: ["Chandigarh"],
          },
          {
            name: "Chhattisgarh",
            states: [
              "Balod",
              "Baloda Bazar",
              "Balrampur",
              "Bastar",
              "Bemetara",
              "Bijapur",
              "Bilaspur",
              "Balrampur Ramanujganj*",
              "Dantewada (South Bastar)",
              "Dhamtari",
              "Durg",
              "Gariyaband",
              "Gaurela Pendra Marwahi",
              "Janjgir-Champa",
              "Jashpur",
              "Kabirdham (Kawardha)",
              "Kanker (North Bastar)",
              "Kondagaon",
              "Korba",
              "Korea (Koriya)",
              "Mahasamund",
              "Mungeli",
              "Narayanpur",
              "Raigarh",
              "Raipur",
              "Rajnandgaon",
              "Sukma",
              "Surajpur  ",
              "Surguja",
            ],
          },
          {
            name: "Dadra and Nagar Haveli (UT)",
            states: ["Dadra & Nagar Haveli"],
          },
          {
            name: "Daman and Diu (UT)",
            states: ["Daman", "Diu"],
          },
          {
            name: "Delhi (NCT)",
            states: [
              "Central Delhi",
              "East Delhi",
              "New Delhi",
              "North Delhi",
              "North East  Delhi",
              "North West  Delhi",
              "Shahdara",
              "South Delhi",
              "South East Delhi",
              "South West  Delhi",
              "West Delhi",
            ],
          },
          {
            name: "Goa",
            states: ["North Goa", "South Goa"],
          },
          {
            name: "Gujarat",
            states: [
              "Ahmedabad",
              "Amreli",
              "Anand",
              "Aravalli",
              "Banaskantha (Palanpur)",
              "Bharuch",
              "Bhavnagar",
              "Botad",
              "Chhota Udepur",
              "Dahod",
              "Dangs (Ahwa)",
              "Devbhoomi Dwarka",
              "Gandhinagar",
              "Gir Somnath",
              "Jamnagar",
              "Junagadh",
              "Kachchh",
              "Kheda (Nadiad)",
              "Mahisagar",
              "Mehsana",
              "Morbi",
              "Narmada (Rajpipla)",
              "Navsari",
              "Panchmahal (Godhra)",
              "Patan",
              "Porbandar",
              "Rajkot",
              "Sabarkantha (Himmatnagar)",
              "Surat",
              "Surendranagar",
              "Tapi (Vyara)",
              "Vadodara",
              "Valsad",
            ],
          },
          {
            name: "Haryana",
            states: [
              "Ambala",
              "Bhiwani",
              "Charkhi Dadri",
              "Faridabad",
              "Fatehabad",
              "Gurgaon",
              "Gurugram",
              "Hisar",
              "Jhajjar",
              "Jind",
              "Kaithal",
              "Karnal",
              "Kurukshetra",
              "Mahendragarh",
              "Mewat",
              "Palwal",
              "Panchkula",
              "Panipat",
              "Rewari",
              "Rohtak",
              "Sirsa",
              "Sonipat",
              "Yamunanagar",
            ],
          },
          {
            name: "Himachal Pradesh",
            states: [
              "Bilaspur",
              "Chamba",
              "Hamirpur",
              "Kangra",
              "Kinnaur",
              "Kullu",
              "Lahaul &amp; Spiti",
              "Mandi",
              "Shimla",
              "Sirmaur (Sirmour)",
              "Solan",
              "Una",
            ],
          },
          {
            name: "Jammu and Kashmir",
            states: [
              "Anantnag",
              "Bandipore",
              "Baramulla",
              "Budgam",
              "Doda",
              "Ganderbal",
              "Jammu",
              "Kargil",
              "Kathua",
              "Kishtwar",
              "Kulgam",
              "Kupwara",
              "Leh",
              "Poonch",
              "Pulwama",
              "Rajouri",
              "Ramban",
              "Reasi",
              "Samba",
              "Shopian",
              "Srinagar",
              "Udhampur",
            ],
          },
          {
            name: "Jharkhand",
            states: [
              "Bokaro",
              "Chatra",
              "Deoghar",
              "Dhanbad",
              "Dumka",
              "East Singhbhum",
              "Garhwa",
              "Giridih",
              "Godda",
              "Gumla",
              "Hazaribag",
              "Jamtara",
              "Khunti",
              "Koderma",
              "Latehar",
              "Lohardaga",
              "Pakur",
              "Palamu",
              "Ramgarh",
              "Ranchi",
              "Sahibganj",
              "Seraikela-Kharsawan",
              "Simdega",
              "West Singhbhum",
            ],
          },
          {
            name: "Karnataka",
            states: [
              "Bagalkot",
              "Ballari (Bellary)",
              "Belagavi (Belgaum)",
              "Bengaluru (Bangalore) Rural",
              "Bengaluru (Bangalore) Urban",
              "Bidar",
              "Chamarajanagar",
              "Chikballapur",
              "Chikkamagaluru (Chikmagalur)",
              "Chitradurga",
              "Dakshina Kannada",
              "Davangere",
              "Dharwad",
              "Gadag",
              "Gulbarga",
              "Hassan",
              "Haveri",
              "Kalaburagi (Gulbarga)",
              "Kodagu",
              "Kolar",
              "Koppal",
              "Mandya",
              "Mysuru (Mysore)",
              "Raichur",
              "Ramanagara",
              "Shivamogga (Shimoga)",
              "Tumakuru (Tumkur)",
              "Udupi",
              "Uttara Kannada (Karwar)",
              "Vijayapura (Bijapur)",
              "Vijayanagara",
              "Vijayapura",
              "Yadgir",
            ],
          },
          {
            name: "Kerala",
            states: [
              "Alappuzha",
              "Ernakulam",
              "Idukki",
              "Kannur",
              "Kasaragod",
              "Kollam",
              "Kottayam",
              "Kozhikode",
              "Malappuram",
              "Palakkad",
              "Pathanamthitta",
              "Thiruvananthapuram",
              "Thrissur",
              "Wayanad",
            ],
          },
          {
            name: "Lakshadweep (UT)",
            states: [
              "Agatti",
              "Amini",
              "Androth",
              "Bithra",
              "Chethlath",
              "Kavaratti",
              "Kadmath",
              "Kalpeni",
              "Kilthan",
              "Minicoy",
            ],
          },
          {
            name: "Madhya Pradesh",
            states: [
              "Agar Malwa",
              "Alirajpur",
              "Anuppur",
              "Ashoknagar",
              "Balaghat",
              "Barwani",
              "Betul",
              "Bhind",
              "Bhopal",
              "Burhanpur",
              "Chachaura",
              "Chhatarpur",
              "Chhindwara",
              "Damoh",
              "Datia",
              "Dewas",
              "Dhar",
              "Dindori",
              "Guna",
              "Gwalior",
              "Harda",
              "Hoshangabad",
              "Indore",
              "Jabalpur",
              "Jhabua",
              "Katni",
              "Khandwa",
              "Khargone",
              "Maihar",
              "Mandla",
              "Mandsaur",
              "Morena",
              "Narsinghpur",
              "Nagda",
              "Neemuch",
              "Niwari",
              "Panna",
              "Raisen",
              "Rajgarh",
              "Ratlam",
              "Rewa",
              "Sagar",
              "Satna",
              "Sehore",
              "Seoni",
              "Shahdol",
              "Shajapur",
              "Sheopur",
              "Shivpuri",
              "Sidhi",
              "Singrauli",
              "Tikamgarh",
              "Ujjain",
              "Umaria",
              "Vidisha",
            ],
          },
          {
            name: "Maharashtra",
            states: [
              "Ahmednagar",
              "Akola",
              "Amravati",
              "Aurangabad",
              "Beed",
              "Bhandara",
              "Buldhana",
              "Chandrapur",
              "Dhule",
              "Gadchiroli",
              "Gondia",
              "Hingoli",
              "Jalgaon",
              "Jalna",
              "Kolhapur",
              "Latur",
              "Mumbai City",
              "Mumbai Suburban",
              "Nagpur",
              "Nanded",
              "Nandurbar",
              "Nashik",
              "Osmanabad",
              "Palghar",
              "Parbhani",
              "Pune",
              "Raigad",
              "Ratnagiri",
              "Sangli",
              "Satara",
              "Sindhudurg",
              "Solapur",
              "Thane",
              "Wardha",
              "Washim",
              "Yavatmal",
            ],
          },
          {
            name: "Manipur",
            states: [
              "Bishnupur",
              "Chandel",
              "Churachandpur",
              "Imphal East",
              "Imphal West",
              "Jiribam",
              "Kakching",
              "Kamjong",
              "Kangpokpi",
              "Noney",
              "Pherzawl",
              "Senapati",
              "Tamenglong",
              "Tengnoupal",
              "Thoubal",
              "Ukhrul",
            ],
          },
          {
            name: "Meghalaya",
            states: [
              "East Garo Hills",
              "East Jaintia Hills",
              "East Khasi Hills",
              "North Garo Hills",
              "Ri Bhoi",
              "South Garo Hills",
              "South West Garo Hills ",
              "South West Khasi Hills",
              "West Garo Hills",
              "West Jaintia Hills",
              "West Khasi Hills",
            ],
          },
          {
            name: "Mizoram",
            states: [
              "Aizawl",
              "Champhai",
              "Kolasib",
              "Lawngtlai",
              "Lunglei",
              "Mamit",
              "Saiha",
              "Serchhip",
            ],
          },
          {
            name: "Nagaland",
            states: [
              "Dimapur",
              "Kiphire",
              "Kohima",
              "Longleng",
              "Mokokchung",
              "Mon",
              "Peren",
              "Phek",
              "Tuensang",
              "Wokha",
              "Zunheboto",
            ],
          },
          {
            name: "Odisha",
            states: [
              "Angul",
              "Balangir",
              "Balasore",
              "Bargarh",
              "Bhadrak",
              "Boudh",
              "Cuttack",
              "Deogarh",
              "Dhenkanal",
              "Gajapati",
              "Ganjam",
              "Jagatsinghapur",
              "Jajpur",
              "Jharsuguda",
              "Kalahandi",
              "Kandhamal",
              "Kendrapara",
              "Kendujhar (Keonjhar)",
              "Khordha ",
              "Khordha",
              "Koraput",
              "Malkangiri",
              "Mayurbhanj",
              "Nabarangpur",
              "Nayagarh",
              "Nuapada",
              "Puri",
              "Rayagada",
              "Sambalpur",
              "Sonepur",
              "Sundargarh",
            ],
          },
          {
            name: "Puducherry (UT)",
            states: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
          },
          {
            name: "Punjab",
            states: [
              "Amritsar",
              "Barnala",
              "Bathinda",
              "Faridkot",
              "Fatehgarh Sahib",
              "Fazilka",
              "Ferozepur",
              "Gurdaspur",
              "Hoshiarpur",
              "Jalandhar",
              "Kapurthala",
              "Ludhiana",
              "Mansa",
              "Malerkotla",
              "Moga",
              "Muktsar",
              "Nawanshahr (Shahid Bhagat Singh Nagar)",
              "Pathankot",
              "Patiala",
              "Rupnagar",
              "Sahibzada Ajit Singh Nagar (Mohali)",
              "Sangrur",
              "Tarn Taran",
            ],
          },
          {
            name: "Rajasthan",
            states: [
              "Ajmer",
              "Alwar",
              "Banswara",
              "Baran",
              "Barmer",
              "Bharatpur",
              "Bhilwara",
              "Bikaner",
              "Bundi",
              "Chittorgarh",
              "Churu",
              "Dausa",
              "Dholpur",
              "Dungarpur",
              "Hanumangarh",
              "Jaipur",
              "Jaisalmer",
              "Jalore",
              "Jhalawar",
              "Jhunjhunu",
              "Jodhpur",
              "Karauli",
              "Kota",
              "Nagaur",
              "Pali",
              "Pratapgarh",
              "Rajsamand",
              "Sawai Madhopur",
              "Sikar",
              "Sirohi",
              "Sri Ganganagar",
              "Tonk",
              "Udaipur",
            ],
          },
          {
            name: "Sikkim",
            states: [
              "East Sikkim",
              "North Sikkim",
              "South Sikkim",
              "West Sikkim",
            ],
          },
          {
            name: "Tamil Nadu",
            states: [
              "Ariyalur",
              "Chennai",
              "Chengalpattu",
              "Coimbatore",
              "Cuddalore",
              "Dharmapuri",
              "Dindigul",
              "Erode",
              "Kanchipuram",
              "Kanyakumari",
              "Karur",
              "Kallakurichi",
              "Krishnagiri",
              "Madurai",
              "Mayiladuthurai",
              "Nagapattinam",
              "Namakkal",
              "Nilgiris",
              "Perambalur",
              "Pudukkottai",
              "Ramanathapuram",
              "Ranipet",
              "Salem",
              "Sivaganga",
              "Thanjavur",
              "Theni",
              "Tenkasi",
              "Tirupattur",
              "Thoothukudi (Tuticorin)",
              "Tiruchirappalli",
              "Tirunelveli",
              "Tiruppur",
              "Tiruvallur",
              "Tiruvannamalai",
              "Tiruvarur",
              "Vellore",
              "Viluppuram",
              "Virudhunagar",
            ],
          },
          {
            name: "Telangana",
            states: [
              "Adilabad",
              "Bhadradri Kothagudem",
              "Hyderabad",
              "Jagtial",
              "Jangaon",
              "Jayashankar Bhoopalpally",
              "Jogulamba Gadwal",
              "Kamareddy",
              "Karimnagar",
              "Khammam",
              "Komaram Bheem Asifabad",
              "Mahabubabad",
              "Mahabubnagar",
              "Mancherial",
              "Medak",
              "Medchal",
              "Mulugu",
              "Narayanpet",
              "Nagarkurnool",
              "Nalgonda",
              "Nirmal",
              "Nizamabad",
              "Peddapalli",
              "Rajanna Sircilla",
              "Rangareddy",
              "Sangareddy",
              "Siddipet",
              "Suryapet",
              "Vikarabad",
              "Wanaparthy",
              "Warangal (Rural)",
              "Warangal (Urban)",
              "Yadadri Bhuvanagiri",
            ],
          },
          {
            name: "Tripura",
            states: [
              "Dhalai",
              "Gomati",
              "Khowai",
              "North Tripura",
              "Sepahijala",
              "South Tripura",
              "Unakoti",
              "West Tripura",
            ],
          },
          {
            name: "Uttarakhand",
            states: [
              "Almora",
              "Bageshwar",
              "Chamoli",
              "Champawat",
              "Dehradun",
              "Haridwar",
              "Nainital",
              "Pauri Garhwal",
              "Pithoragarh",
              "Rudraprayag",
              "Tehri Garhwal",
              "Udham Singh Nagar",
              "Uttarkashi",
            ],
          },
          {
            name: "Uttar Pradesh",
            states: [
              "Agra",
              "Aligarh",
              "Allahabad",
              "Ambedkar Nagar",
              "Amethi (Chatrapati Sahuji Mahraj Nagar)",
              "Amroha (J.P. Nagar)",
              "Auraiya",
              "Ayodhya",
              "Azamgarh",
              "Baghpat",
              "Bahraich",
              "Ballia",
              "Balrampur",
              "Banda",
              "Barabanki",
              "Bareilly",
              "Basti",
              "Bhadohi",
              "Bijnor",
              "Budaun",
              "Bulandshahr",
              "Chandauli",
              "Chitrakoot",
              "Deoria",
              "Etah",
              "Etawah",
              "Faizabad",
              "Farrukhabad",
              "Fatehpur",
              "Firozabad",
              "Gautam Buddha Nagar",
              "Ghaziabad",
              "Ghazipur",
              "Gonda",
              "Gorakhpur",
              "Hamirpur",
              "Hapur (Panchsheel Nagar)",
              "Hardoi",
              "Hathras",
              "Jalaun",
              "Jaunpur",
              "Jhansi",
              "Kannauj",
              "Kanpur Dehat",
              "Kanpur Nagar",
              "Kasganj",
              "Kanshiram Nagar (Kasganj)",
              "Kaushambi",
              "Kushinagar (Padrauna)",
              "Lakhimpur - Kheri",
              "Lalitpur",
              "Lucknow",
              "Maharajganj",
              "Mahoba",
              "Mainpuri",
              "Mathura",
              "Mau",
              "Meerut",
              "Mirzapur",
              "Moradabad",
              "Muzaffarnagar",
              "Pilibhit",
              "Prayagraj",
              "Pratapgarh",
              "RaeBareli",
              "Rampur",
              "Saharanpur",
              "Sambhal (Bhim Nagar)",
              "Sant Kabir Nagar",
              "Shahjahanpur",
              "Shamali (Prabuddh Nagar)",
              "Shravasti",
              "Siddharth Nagar",
              "Sitapur",
              "Sonbhadra",
              "Sultanpur",
              "Unnao",
              "Varanasi",
            ],
          },
          {
            name: "West Bengal",
            states: [
              "Alipurduar",
              "Bankura",
              "Birbhum",
              "Burdwan (Bardhaman)",
              "Cooch Behar",
              "Dakshin Dinajpur (South Dinajpur)",
              "Darjeeling",
              "Hooghly",
              "Howrah",
              "Jalpaiguri",
              "Kalimpong",
              "Kolkata",
              "Malda",
              "Murshidabad",
              "Nadia",
              "North 24 Parganas",
              "Paschim Medinipur (West Medinipur)",
              "Purba Medinipur (East Medinipur)",
              "Purulia",
              "South 24 Parganas",
              "Uttar Dinajpur (North Dinajpur)",
            ],
          },
        ],
        states: [],
        selectedState: "",
        selectedDistrict: "",
      },
    };
  }

  handleToogleClick = () => {
    this.setState({
      isToggleOn: !this.state.isToggleOn,
    });
  };

  componentDidMount() {
    if (this.props.user && this.props.user.authToken !== "") {
      const token = this.props.user.authToken;
      const tokenDecode: any = jwt_decode(token);
      const tokenExpDate = new Date(tokenDecode.exp * 1000);
      const currentDate = new Date();
      if (currentDate < tokenExpDate) {
        this.props.history.replace("/dashboard");
      } else {
        this.props.unauthUser();
      }
    } else {
      this.props.unauthUser();
    }

    const modalDom = document.getElementsByClassName("modal-backdrop");
    modalDom[0]?.remove();
  }

  validateInput = (name: any, value: any) => {
    if (!value) {
      this.setState({
        registerErrors: {
          ...this.state.registerErrors,
          [name]: true,
        },
      });
    } else {
      let isValid = true;
      if (
        name === "confirmPassword" &&
        value !== this.state.register.password
      ) {
        isValid = false;
      } else if (name === "password" && value.length <= 3) {
        isValid = false;
      } else if (
        name === "mobileNumber" &&
        !value.match(/^[2-9]{1}[0-9]{9}$/)
      ) {
        isValid = false;
      } else if (
        name === "emailID" &&
        !value.match(/^[a-zA-Z0-9._:$!%-+]+@+[a-zA-Z0-9.-]+.+[a-zA-Z]$/)
      ) {
        isValid = false;
      }
      if (name === "password" && this.state.register.confirmPassword?.length) {
        const confirmPasswordValid =
          this.state.register.confirmPassword === value;
        this.setState({
          registerErrors: {
            ...this.state.registerErrors,
            confirmPassword: !confirmPasswordValid,
            password: !isValid,
          },
        });
      } else {
        this.setState({
          registerErrors: {
            ...this.state.registerErrors,
            [name]: !isValid,
          },
        });
      }
    }
  };

  selectChangeForDropdown = (e: { target: { value: string } }) => {
    const state = e.target.value;
    if (!state) {
      this.setState({
        registerErrors: {
          ...this.state.registerErrors,
          selectedState: true,
        },
      });
    } else {
      this.setState({
        registerErrors: {
          ...this.state.registerErrors,
          selectedState: false,
        },
      });
    }
    this.setState({
      register: {
        ...this.state.register,
        selectedState: state,
        states: this.state.register.countries.find((x) => x.name == state)
          .states,
      },
    });
  };

  changeSelectedDistrict = (e: { target: { value: any } }) => {
    const district = e.target.value;
    if (!district) {
      this.setState({
        registerErrors: {
          ...this.state.registerErrors,
          selectedDistrict: true,
        },
      });
    } else {
      this.setState({
        registerErrors: {
          ...this.state.registerErrors,
          selectedDistrict: false,
        },
      });
    }
    this.setState({
      register: {
        ...this.state.register,
        selectedDistrict: district,
      },
    });
  };

  togglePasswordVisibility = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
  };
  toggleShowConfirmPasswordVisibility = () => {
    const { isShowConfirmPassword } = this.state;
    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  };
  handleChange = (event: { target: any }) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    this.setState({ [input.name]: value });
  };

  // handleSubmit = () =>
  // {

  //   const email= this.state.forgotpassword.email
  //   const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  //   if(!pattern.test(email))
  //   {
  //     this.setState({errors : <p style={{color:"red"}}>Please enter the valid email address</p>})
  //   }
  //   else{
  //     this.setState({errors : <p style={{color:"green"}}>Successfully submitted the request...!!</p>})

  //   }

  // }

  handleSubmit = async (event) => {
    event.preventDefault();
    const email = this.state.forgotpassword.email;
    this.setState({
      forgotpassword: {
        ...this.state.forgotpassword,
        disableSubmit: true,
      },
    });
    try {
      const response = await axios.post(
        `${base_url}/api/Login/ForgotPassword?UserId=${email}`
      );
      if (response) {
        this.setState({
          errors: (
            <p style={{ color: "green" }}>{errors.forgotPassword[200].msg}</p>
          ),
          forgotpassword: {
            ...this.state.forgotpassword,
            disableSubmit: false,
          },
        });
      }
    } catch (error) {
      this.setState({
        forgotpassword: {
          ...this.state.forgotpassword,
          disableSubmit: false,
        },
      });
      if (error.response) {
        const responseStatus = error.response.data.ResponseStatus;
        const errorMessage: any = errors.forgotPassword[responseStatus].msg;
        this.setState({
          errors: <p style={{ color: "red" }}>{errorMessage}</p>,
        });
      }
    }
  };

  ForgotPassWord = () => {
    return (
      <div
        className="modal fade"
        id="forgotPassword"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabels"
        aria-hidden="true"
        onClick={() => {
          this.setState({
            errors: "",
          });
        }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ background: "#042A9A" }}>
              <h3 className="forgot-text">Forgot Password</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ color: "#fff", opacity: 1 }}
                onClick={() => {
                  this.setState({
                    errors: "",
                    forgotpassword: {
                      email: "",
                      disableSubmit: false,
                    },
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div style={{ height: "16em" }} className="modal-body">
              <form
                className="form-forgot"
                autoComplete="on"
                onSubmit={this.handleSubmit}
              >
                <div className="forgotpasswordcontainer">
                  <div className="ForgotPasswordSubContainer">
                    <label htmlFor="inputEmail" className="forgotlabel">
                      PLEASE ENTER YOUR EMAIL ID
                    </label>
                    <div className="input-container">
                      <MdEmail className="input-icon" />
                      <input
                        type="email"
                        id="inputEmail"
                        name="email"
                        className="form-control"
                        placeholder="Email ID"
                        style={{ backgroundColor: "aliceblue" }}
                        value={this.state.forgotpassword.email}
                        onChange={(e) =>
                          this.setState({
                            forgotpassword: {
                              ...this.state.forgotpassword,
                              email: e.target.value,
                            },
                          })
                        }
                        autoComplete="on"
                        required
                      />
                    </div>
                  </div>
                  <strong>
                    <div className="text-center">
                      <p style={{ textAlign: "left", marginLeft: "10%" }}>
                        {this.state.errors}
                      </p>
                    </div>
                  </strong>

                  <div className="forgotSubmit">
                    <button
                      style={{ fontSize: "1em", width: "30%" }}
                      className="btn btn-lg btn-success btn-block"
                      type="submit"
                      value="submit"
                      disabled={this.state.forgotpassword.disableSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <div className="modal-footer" style={{ borderTop: "none" }}>
                <button
                  style={{ marginRight: "5%", marginTop: "-19%" }}
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.setState({
                      errors: "",
                      forgotpassword: {
                        email: "",
                      },
                    });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  sendRegisterInformation = () => {
    // sign-up started
    this.props.showProcessing(true);
    this.setState({
      register: {
        ...this.state.register,
        Backenderror: "",
      },
    });
    try {
      axios
        .post(`${base_url}/api/Login`, {
          UserId: this.state.register.emailID,
          FirstName: this.state.register.firstName,
          MiddleName: "",
          LastName: this.state.register.lastName,
          Email: this.state.register.emailID,
          MobileNumber: this.state.register.mobileNumber,
          Password: this.state.register.password,
          ProfileImage: "",
          State: this.state.register.selectedState,
          District: this.state.register.selectedDistrict,
          Taluk: this.state.register.selectedDistrict,
          IsActive: true,
        })
        .then((resp) => {
          this.props.showProcessing(false);
          if (
            resp.data.ResponseStatus === 0 ||
            resp.data.ResponseStatus === 200
          ) {
            this.setState({
              register: {
                ...this.state.register,
                Backenderror: (
                  <p style={{ color: "green" }}>{resp.data.Message}</p>
                ),
                checked: !this.state.register.checked,
                firstName: "",
                lastName: "",
                emailID: "",
                mobileNumber: "",
                password: "",
                confirmPassword: "",
                selectedState: "",
                selectedDistrict: "",
              },
              isShowPassword: false,
              isShowConfirmPassword: false,
            });
          } else {
            this.setState({
              register: {
                ...this.state.register,
                Backenderror: (
                  <p style={{ color: "red" }}>{resp.data.Message}</p>
                ),
              },
            });
          }
        })
        .catch((error) => {
          this.props.showProcessing(false);
          const responseStatus = error?.response?.data?.ResponseStatus ?? null;
          this.setState({
            register: {
              ...this.state.register,
              Backenderror: (
                <p style={{ color: "red" }}>
                  {errors.register[responseStatus]?.msg}
                </p>
              ),
            },
          });
          return Promise.reject(error);
        });
    } catch (e) {
      this.props.showProcessing(false);
      console.log(e);
    }
  };

  checkedValidation = () => {
    this.setState({
      register: {
        ...this.state.register,
        checked: !this.state.register.checked,
      },
    });
  };

  RegisterModal = () => {
    const { isShowPassword, isShowConfirmPassword } = this.state;

    return (
      <div
        className="modal fade"
        id="registerModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ margin: "1rem auto" }}
        >
          <div className="modal-content">
            <div className="modal-body register-modal-body">
              <div className="modal-header">
                <h1 className="h1 register-text">Sign-Up</h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={() => {
                    this.setState({
                      register: {
                        ...this.state.register,
                        Backenderror: "",
                        checked: !this.state.register.checked,
                        firstName: "",
                        lastName: "",
                        emailID: "",
                        mobileNumber: "",
                        password: "",
                        confirmPassword: "",
                        selectedState: "",
                        selectedDistrict: "",
                      },
                      isShowPassword: false,
                      isShowConfirmPassword,
                    });
                  }}
                >
                  <p style={{ color: "#fff" }}>×</p>
                </button>
              </div>
              <form
                className="form-register"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="input-container">
                  <label htmlFor="inputFirstName" className="sr-only">
                    First Name
                  </label>
                  <MdPerson className="input-icon" />
                  <input
                    type="text"
                    id="inputFirstName"
                    className={classNames("form-control register-input", {
                      error: this.state.registerErrors.firstName,
                    })}
                    placeholder="First Name"
                    value={this.state.register.firstName}
                    onBlur={(e) => {
                      this.validateInput("firstName", e.target.value);
                    }}
                    onChange={(e) =>
                      this.setState({
                        register: {
                          ...this.state.register,
                          firstName: e.target.value,
                        },
                      })
                    }
                    required
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="inputLastName" className="sr-only">
                    Last Name
                  </label>
                  <MdPerson className="input-icon" />
                  <input
                    type="text"
                    id="inputLastName"
                    className={classNames("form-control register-input", {
                      error: this.state.registerErrors.lastName,
                    })}
                    placeholder="Last Name"
                    value={this.state.register.lastName}
                    onBlur={(e) => {
                      this.validateInput("lastName", e.target.value);
                    }}
                    onChange={(e) =>
                      this.setState({
                        register: {
                          ...this.state.register,
                          lastName: e.target.value,
                        },
                      })
                    }
                    required
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="inputEmail" className="sr-only">
                    EMAIL ID
                  </label>
                  <MdEmail className="input-icon" />
                  <input
                    type="email"
                    id="inputEmail"
                    className={classNames("form-control register-input", {
                      error: this.state.registerErrors.emailID,
                    })}
                    placeholder="Email ID"
                    value={this.state.register.emailID}
                    onBlur={(e) => {
                      this.validateInput("emailID", e.target.value);
                    }}
                    onChange={(e) => {
                      this.setState({
                        register: {
                          ...this.state.register,
                          emailID: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="inputState" className="sr-only">
                    Phone
                  </label>
                  <MdPhone className="input-icon" />
                  <input
                    className={classNames("form-control register-input", {
                      error: this.state.registerErrors.mobileNumber,
                    })}
                    type="phone"
                    id="inputMobile"
                    placeholder="Phone number Eg: 9999999999"
                    value={this.state.register.mobileNumber}
                    onBlur={(e) => {
                      this.validateInput("mobileNumber", e.target.value);
                    }}
                    onChange={(e) =>
                      this.setState({
                        register: {
                          ...this.state.register,
                          mobileNumber: e.target.value,
                        },
                      })
                    }
                    required
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="inputPassword" className="sr-only">
                    Password
                  </label>
                  <MdVpnKey className="input-icon" />
                  <input
                    type={isShowPassword ? "text" : "password"}
                    id="inputPassword"
                    className={classNames("form-control register-input", {
                      error: this.state.registerErrors.password,
                    })}
                    placeholder="Password (min. 4 characters)"
                    value={this.state.register.password}
                    onBlur={(e) => {
                      this.validateInput("password", e.target.value);
                    }}
                    onChange={(e) =>
                      this.setState({
                        register: {
                          ...this.state.register,
                          password: e.target.value,
                        },
                      })
                    }
                    required
                  />
                  <MdVisibility
                    className="input-icons"
                    onClick={this.togglePasswordVisibility}
                  ></MdVisibility>
                </div>

                <div className="input-container">
                  <label htmlFor="inputConfirmPassword" className="sr-only">
                    Confirm Password
                  </label>
                  <MdCheck className="input-icon" />
                  <input
                    type={isShowConfirmPassword ? "text" : "password"}
                    id="inputConfirmPassword"
                    className={classNames("form-control register-input", {
                      error:
                        this.state.registerErrors.confirmPassword ||
                        this.state.register.password !==
                          this.state.register.confirmPassword,
                    })}
                    placeholder="Confirm Password"
                    value={this.state.register.confirmPassword}
                    onBlur={(e) => {
                      this.validateInput("confirmPassword", e.target.value);
                    }}
                    onChange={(e) =>
                      this.setState({
                        register: {
                          ...this.state.register,
                          confirmPassword: e.target.value,
                        },
                      })
                    }
                    required
                  />
                  <MdVisibility
                    className="input-icons"
                    onClick={this.toggleShowConfirmPasswordVisibility}
                  ></MdVisibility>
                </div>
                <Dropdown
                  dropdownState={this.state.register}
                  errorState={this.state.registerErrors}
                  selectChange={this.selectChangeForDropdown}
                  changeSelectedDistrict={this.changeSelectedDistrict}
                />
                <div className="input-container" style={{ marginLeft: "10%" }}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    value={this.state.register.checked}
                    onChange={this.checkedValidation}
                    id="defaultUnchecked"
                    required
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked"
                  >
                    <a
                      href="https://prolaskool.com/termsandcondition.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Please accept terms and condition
                    </a>
                  </label>
                </div>

                <div className="error-container">
                  <strong
                    className="weberrorboxApi"
                    style={{ marginLeft: "0%" }}
                  >
                    {this.state.register.Backenderror}
                  </strong>
                </div>
                {this.props.isProcessing && (
                  <div className="register spinner-table">
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>

                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <button
                  style={{
                    height: "2.7em",
                    width: "30%",
                    fontSize: "1em",
                    marginRight: "53%",
                  }}
                  className={`btn btn-lg btn-success btn-block ${
                    this.props.isProcessing ? "loading" : ""
                  } ${
                    this.state.register.firstName === "" ||
                    this.state.register.lastName === "" ||
                    this.state.register.emailID === "" ||
                    this.state.register.mobileNumber === "" ||
                    this.state.register.selectedState === "" ||
                    this.state.register.selectedDistrict === "" ||
                    this.state.register.password === "" ||
                    this.state.register.confirmPassword === "" ||
                    this.state.register.checked === false
                      ? "disabled"
                      : "" ||
                        this.state.register.password !==
                          this.state.register.confirmPassword ||
                        this.state.register.password.length <= 3 ||
                        !this.state.register.mobileNumber.match(
                          /^[2-9]{1}[0-9]{9}$/
                        ) ||
                        !this.state.register.emailID.match(
                          /^[a-zA-Z0-9._:$!%-+]+@+[a-zA-Z0-9.-]+.+[a-zA-Z]$/
                        )
                      ? "disabled"
                      : ""
                  }`}
                  type="submit"
                  disabled={
                    this.state.register.firstName === "" ||
                    this.state.register.lastName === "" ||
                    this.state.register.emailID === "" ||
                    this.state.register.mobileNumber === "" ||
                    this.state.register.selectedState === "" ||
                    this.state.register.selectedDistrict === "" ||
                    this.state.register.password === "" ||
                    this.state.register.confirmPassword === "" ||
                    this.state.register.checked === false ||
                    this.state.register.password !==
                      this.state.register.confirmPassword ||
                    this.state.register.password.length <= 3 ||
                    !this.state.register.emailID.match(
                      /^[a-zA-Z0-9._:$!%-+]+@+[a-zA-Z0-9.-]+.+[a-zA-Z]$/
                    ) ||
                    !this.state.register.mobileNumber.match(
                      /^[2-9]{1}[0-9]{9}$/
                    )
                  }
                  onClick={this.sendRegisterInformation}
                >
                  REGISTER
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  this.setState({
                    register: {
                      firstName: "",
                      checked: false,
                      lastName: "",
                      emailID: "",
                      mobileNumber: "",
                      password: "",
                      confirmPassword: "",
                      countries: [
                        {
                          name: "Andhra Pradesh",
                          states: [
                            "Anantapur",
                            "Chittoor",
                            "East Godavari",
                            "Guntur",
                            "Krishna",
                            "Kurnool",
                            "Nellore",
                            "Prakasam",
                            "Srikakulam",
                            "Visakhapatnam",
                            "Vizianagaram",
                            "West Godavari",
                            "YSR Kadapa",
                          ],
                        },
                        {
                          name: "Arunachal Pradesh",
                          states: [
                            "Anjaw",
                            "Siang",
                            "Changlang",
                            "Dibang Valley",
                            "East Kameng",
                            "East Siang",
                            "Kamle",
                            "Kra Daadi",
                            "Kurung Kumey",
                            "Lepa Rada",
                            "Lohit",
                            "Longding",
                            "Lower Dibang Valley",
                            "Lower Siang",
                            "Lower Subansiri",
                            "Namsai",
                            "Pakke Kessang",
                            "Papum Pare",
                            "Shi Yomi",
                            "Tawang",
                            "Tirap",
                            "Upper Siang",
                            "Upper Subansiri",
                            "West Kameng",
                            "West Siang",
                          ],
                        },
                        {
                          name: "Assam",
                          states: [
                            "Bajali",
                            "Baksa",
                            "Barpeta",
                            "Biswanath",
                            "Bongaigaon",
                            "Cachar",
                            "Charaideo",
                            "Chirang",
                            "Darrang",
                            "Dhemaji",
                            "Dhubri",
                            "Dibrugarh",
                            "Goalpara",
                            "Golaghat",
                            "Hailakandi",
                            "Hojai",
                            "Jorhat",
                            "Kamrup Metropolitan",
                            "Kamrup",
                            "Karbi Anglong",
                            "Karimganj",
                            "Kokrajhar",
                            "Lakhimpur",
                            "Majuli",
                            "Morigaon",
                            "Nagaon",
                            "Nalbari",
                            "Dima Hasao",
                            "Sivasagar",
                            "Sonitpur",
                            "South Salmara-Mankachar",
                            "Tinsukia",
                            "Udalguri",
                            "West Karbi Anglong",
                          ],
                        },
                        {
                          name: "Bihar",
                          states: [
                            "Araria",
                            "Arwal",
                            "Aurangabad",
                            "Banka",
                            "Begusarai",
                            "Bhagalpur",
                            "Bhojpur",
                            "Buxar",
                            "Darbhanga",
                            "East Champaran (Motihari)",
                            "Gaya",
                            "Gopalganj",
                            "Jamui",
                            "Jehanabad",
                            "Kaimur (Bhabua)",
                            "Katihar",
                            "Khagaria",
                            "Kishanganj",
                            "Lakhisarai",
                            "Madhepura",
                            "Madhubani",
                            "Munger (Monghyr)",
                            "Muzaffarpur",
                            "Nalanda",
                            "Nawada",
                            "Patna",
                            "Purnia (Purnea)",
                            "Rohtas",
                            "Saharsa",
                            "Samastipur",
                            "Saran",
                            "Sheikhpura",
                            "Sheohar",
                            "Sitamarhi",
                            "Siwan",
                            "Supaul",
                            "Vaishali",
                            "West Champaran",
                          ],
                        },
                        {
                          name: "Chandigarh (UT)",
                          states: ["Chandigarh"],
                        },
                        {
                          name: "Chhattisgarh",
                          states: [
                            "Balod",
                            "Baloda Bazar",
                            "Balrampur",
                            "Bastar",
                            "Bemetara",
                            "Bijapur",
                            "Bilaspur",
                            "Balrampur Ramanujganj*",
                            "Dantewada (South Bastar)",
                            "Dhamtari",
                            "Durg",
                            "Gariyaband",
                            "Gaurela Pendra Marwahi",
                            "Janjgir-Champa",
                            "Jashpur",
                            "Kabirdham (Kawardha)",
                            "Kanker (North Bastar)",
                            "Kondagaon",
                            "Korba",
                            "Korea (Koriya)",
                            "Mahasamund",
                            "Mungeli",
                            "Narayanpur",
                            "Raigarh",
                            "Raipur",
                            "Rajnandgaon",
                            "Sukma",
                            "Surajpur  ",
                            "Surguja",
                          ],
                        },
                        {
                          name: "Dadra and Nagar Haveli (UT)",
                          states: ["Dadra & Nagar Haveli"],
                        },
                        {
                          name: "Daman and Diu (UT)",
                          states: ["Daman", "Diu"],
                        },
                        {
                          name: "Delhi (NCT)",
                          states: [
                            "Central Delhi",
                            "East Delhi",
                            "New Delhi",
                            "North Delhi",
                            "North East  Delhi",
                            "North West  Delhi",
                            "Shahdara",
                            "South Delhi",
                            "South East Delhi",
                            "South West  Delhi",
                            "West Delhi",
                          ],
                        },
                        {
                          name: "Goa",
                          states: ["North Goa", "South Goa"],
                        },
                        {
                          name: "Gujarat",
                          states: [
                            "Ahmedabad",
                            "Amreli",
                            "Anand",
                            "Aravalli",
                            "Banaskantha (Palanpur)",
                            "Bharuch",
                            "Bhavnagar",
                            "Botad",
                            "Chhota Udepur",
                            "Dahod",
                            "Dangs (Ahwa)",
                            "Devbhoomi Dwarka",
                            "Gandhinagar",
                            "Gir Somnath",
                            "Jamnagar",
                            "Junagadh",
                            "Kachchh",
                            "Kheda (Nadiad)",
                            "Mahisagar",
                            "Mehsana",
                            "Morbi",
                            "Narmada (Rajpipla)",
                            "Navsari",
                            "Panchmahal (Godhra)",
                            "Patan",
                            "Porbandar",
                            "Rajkot",
                            "Sabarkantha (Himmatnagar)",
                            "Surat",
                            "Surendranagar",
                            "Tapi (Vyara)",
                            "Vadodara",
                            "Valsad",
                          ],
                        },
                        {
                          name: "Haryana",
                          states: [
                            "Ambala",
                            "Bhiwani",
                            "Charkhi Dadri",
                            "Faridabad",
                            "Fatehabad",
                            "Gurgaon",
                            "Gurugram",
                            "Hisar",
                            "Jhajjar",
                            "Jind",
                            "Kaithal",
                            "Karnal",
                            "Kurukshetra",
                            "Mahendragarh",
                            "Mewat",
                            "Palwal",
                            "Panchkula",
                            "Panipat",
                            "Rewari",
                            "Rohtak",
                            "Sirsa",
                            "Sonipat",
                            "Yamunanagar",
                          ],
                        },
                        {
                          name: "Himachal Pradesh",
                          states: [
                            "Bilaspur",
                            "Chamba",
                            "Hamirpur",
                            "Kangra",
                            "Kinnaur",
                            "Kullu",
                            "Lahaul &amp; Spiti",
                            "Mandi",
                            "Shimla",
                            "Sirmaur (Sirmour)",
                            "Solan",
                            "Una",
                          ],
                        },
                        {
                          name: "Jammu and Kashmir",
                          states: [
                            "Anantnag",
                            "Bandipore",
                            "Baramulla",
                            "Budgam",
                            "Doda",
                            "Ganderbal",
                            "Jammu",
                            "Kargil",
                            "Kathua",
                            "Kishtwar",
                            "Kulgam",
                            "Kupwara",
                            "Leh",
                            "Poonch",
                            "Pulwama",
                            "Rajouri",
                            "Ramban",
                            "Reasi",
                            "Samba",
                            "Shopian",
                            "Srinagar",
                            "Udhampur",
                          ],
                        },
                        {
                          name: "Jharkhand",
                          states: [
                            "Bokaro",
                            "Chatra",
                            "Deoghar",
                            "Dhanbad",
                            "Dumka",
                            "East Singhbhum",
                            "Garhwa",
                            "Giridih",
                            "Godda",
                            "Gumla",
                            "Hazaribag",
                            "Jamtara",
                            "Khunti",
                            "Koderma",
                            "Latehar",
                            "Lohardaga",
                            "Pakur",
                            "Palamu",
                            "Ramgarh",
                            "Ranchi",
                            "Sahibganj",
                            "Seraikela-Kharsawan",
                            "Simdega",
                            "West Singhbhum",
                          ],
                        },
                        {
                          name: "Karnataka",
                          states: [
                            "Bagalkot",
                            "Ballari (Bellary)",
                            "Belagavi (Belgaum)",
                            "Bengaluru (Bangalore) Rural",
                            "Bengaluru (Bangalore) Urban",
                            "Bidar",
                            "Chamarajanagar",
                            "Chikballapur",
                            "Chikkamagaluru (Chikmagalur)",
                            "Chitradurga",
                            "Dakshina Kannada",
                            "Davangere",
                            "Dharwad",
                            "Gadag",
                            "Gulbarga",
                            "Hassan",
                            "Haveri",
                            "Kalaburagi (Gulbarga)",
                            "Kodagu",
                            "Kolar",
                            "Koppal",
                            "Mandya",
                            "Mysuru (Mysore)",
                            "Raichur",
                            "Ramanagara",
                            "Shivamogga (Shimoga)",
                            "Tumakuru (Tumkur)",
                            "Udupi",
                            "Uttara Kannada (Karwar)",
                            "Vijayapura (Bijapur)",
                            "Vijayanagara",
                            "Vijayapura",
                            "Yadgir",
                          ],
                        },
                        {
                          name: "Kerala",
                          states: [
                            "Alappuzha",
                            "Ernakulam",
                            "Idukki",
                            "Kannur",
                            "Kasaragod",
                            "Kollam",
                            "Kottayam",
                            "Kozhikode",
                            "Malappuram",
                            "Palakkad",
                            "Pathanamthitta",
                            "Thiruvananthapuram",
                            "Thrissur",
                            "Wayanad",
                          ],
                        },
                        {
                          name: "Lakshadweep (UT)",
                          states: [
                            "Agatti",
                            "Amini",
                            "Androth",
                            "Bithra",
                            "Chethlath",
                            "Kavaratti",
                            "Kadmath",
                            "Kalpeni",
                            "Kilthan",
                            "Minicoy",
                          ],
                        },
                        {
                          name: "Madhya Pradesh",
                          states: [
                            "Agar Malwa",
                            "Alirajpur",
                            "Anuppur",
                            "Ashoknagar",
                            "Balaghat",
                            "Barwani",
                            "Betul",
                            "Bhind",
                            "Bhopal",
                            "Burhanpur",
                            "Chachaura",
                            "Chhatarpur",
                            "Chhindwara",
                            "Damoh",
                            "Datia",
                            "Dewas",
                            "Dhar",
                            "Dindori",
                            "Guna",
                            "Gwalior",
                            "Harda",
                            "Hoshangabad",
                            "Indore",
                            "Jabalpur",
                            "Jhabua",
                            "Katni",
                            "Khandwa",
                            "Khargone",
                            "Maihar",
                            "Mandla",
                            "Mandsaur",
                            "Morena",
                            "Narsinghpur",
                            "Nagda",
                            "Neemuch",
                            "Niwari",
                            "Panna",
                            "Raisen",
                            "Rajgarh",
                            "Ratlam",
                            "Rewa",
                            "Sagar",
                            "Satna",
                            "Sehore",
                            "Seoni",
                            "Shahdol",
                            "Shajapur",
                            "Sheopur",
                            "Shivpuri",
                            "Sidhi",
                            "Singrauli",
                            "Tikamgarh",
                            "Ujjain",
                            "Umaria",
                            "Vidisha",
                          ],
                        },
                        {
                          name: "Maharashtra",
                          states: [
                            "Ahmednagar",
                            "Akola",
                            "Amravati",
                            "Aurangabad",
                            "Beed",
                            "Bhandara",
                            "Buldhana",
                            "Chandrapur",
                            "Dhule",
                            "Gadchiroli",
                            "Gondia",
                            "Hingoli",
                            "Jalgaon",
                            "Jalna",
                            "Kolhapur",
                            "Latur",
                            "Mumbai City",
                            "Mumbai Suburban",
                            "Nagpur",
                            "Nanded",
                            "Nandurbar",
                            "Nashik",
                            "Osmanabad",
                            "Palghar",
                            "Parbhani",
                            "Pune",
                            "Raigad",
                            "Ratnagiri",
                            "Sangli",
                            "Satara",
                            "Sindhudurg",
                            "Solapur",
                            "Thane",
                            "Wardha",
                            "Washim",
                            "Yavatmal",
                          ],
                        },
                        {
                          name: "Manipur",
                          states: [
                            "Bishnupur",
                            "Chandel",
                            "Churachandpur",
                            "Imphal East",
                            "Imphal West",
                            "Jiribam",
                            "Kakching",
                            "Kamjong",
                            "Kangpokpi",
                            "Noney",
                            "Pherzawl",
                            "Senapati",
                            "Tamenglong",
                            "Tengnoupal",
                            "Thoubal",
                            "Ukhrul",
                          ],
                        },
                        {
                          name: "Meghalaya",
                          states: [
                            "East Garo Hills",
                            "East Jaintia Hills",
                            "East Khasi Hills",
                            "North Garo Hills",
                            "Ri Bhoi",
                            "South Garo Hills",
                            "South West Garo Hills ",
                            "South West Khasi Hills",
                            "West Garo Hills",
                            "West Jaintia Hills",
                            "West Khasi Hills",
                          ],
                        },
                        {
                          name: "Mizoram",
                          states: [
                            "Aizawl",
                            "Champhai",
                            "Kolasib",
                            "Lawngtlai",
                            "Lunglei",
                            "Mamit",
                            "Saiha",
                            "Serchhip",
                          ],
                        },
                        {
                          name: "Nagaland",
                          states: [
                            "Dimapur",
                            "Kiphire",
                            "Kohima",
                            "Longleng",
                            "Mokokchung",
                            "Mon",
                            "Peren",
                            "Phek",
                            "Tuensang",
                            "Wokha",
                            "Zunheboto",
                          ],
                        },
                        {
                          name: "Odisha",
                          states: [
                            "Angul",
                            "Balangir",
                            "Balasore",
                            "Bargarh",
                            "Bhadrak",
                            "Boudh",
                            "Cuttack",
                            "Deogarh",
                            "Dhenkanal",
                            "Gajapati",
                            "Ganjam",
                            "Jagatsinghapur",
                            "Jajpur",
                            "Jharsuguda",
                            "Kalahandi",
                            "Kandhamal",
                            "Kendrapara",
                            "Kendujhar (Keonjhar)",
                            "Khordha ",
                            "Khordha",
                            "Koraput",
                            "Malkangiri",
                            "Mayurbhanj",
                            "Nabarangpur",
                            "Nayagarh",
                            "Nuapada",
                            "Puri",
                            "Rayagada",
                            "Sambalpur",
                            "Sonepur",
                            "Sundargarh",
                          ],
                        },
                        {
                          name: "Puducherry (UT)",
                          states: ["Karaikal", "Mahe", "Pondicherry", "Yanam"],
                        },
                        {
                          name: "Punjab",
                          states: [
                            "Amritsar",
                            "Barnala",
                            "Bathinda",
                            "Faridkot",
                            "Fatehgarh Sahib",
                            "Fazilka",
                            "Ferozepur",
                            "Gurdaspur",
                            "Hoshiarpur",
                            "Jalandhar",
                            "Kapurthala",
                            "Ludhiana",
                            "Mansa",
                            "Malerkotla",
                            "Moga",
                            "Muktsar",
                            "Nawanshahr (Shahid Bhagat Singh Nagar)",
                            "Pathankot",
                            "Patiala",
                            "Rupnagar",
                            "Sahibzada Ajit Singh Nagar (Mohali)",
                            "Sangrur",
                            "Tarn Taran",
                          ],
                        },
                        {
                          name: "Rajasthan",
                          states: [
                            "Ajmer",
                            "Alwar",
                            "Banswara",
                            "Baran",
                            "Barmer",
                            "Bharatpur",
                            "Bhilwara",
                            "Bikaner",
                            "Bundi",
                            "Chittorgarh",
                            "Churu",
                            "Dausa",
                            "Dholpur",
                            "Dungarpur",
                            "Hanumangarh",
                            "Jaipur",
                            "Jaisalmer",
                            "Jalore",
                            "Jhalawar",
                            "Jhunjhunu",
                            "Jodhpur",
                            "Karauli",
                            "Kota",
                            "Nagaur",
                            "Pali",
                            "Pratapgarh",
                            "Rajsamand",
                            "Sawai Madhopur",
                            "Sikar",
                            "Sirohi",
                            "Sri Ganganagar",
                            "Tonk",
                            "Udaipur",
                          ],
                        },
                        {
                          name: "Sikkim",
                          states: [
                            "East Sikkim",
                            "North Sikkim",
                            "South Sikkim",
                            "West Sikkim",
                          ],
                        },
                        {
                          name: "Tamil Nadu",
                          states: [
                            "Ariyalur",
                            "Chennai",
                            "Chengalpattu",
                            "Coimbatore",
                            "Cuddalore",
                            "Dharmapuri",
                            "Dindigul",
                            "Erode",
                            "Kanchipuram",
                            "Kanyakumari",
                            "Karur",
                            "Kallakurichi",
                            "Krishnagiri",
                            "Madurai",
                            "Mayiladuthurai",
                            "Nagapattinam",
                            "Namakkal",
                            "Nilgiris",
                            "Perambalur",
                            "Pudukkottai",
                            "Ramanathapuram",
                            "Ranipet",
                            "Salem",
                            "Sivaganga",
                            "Thanjavur",
                            "Theni",
                            "Tenkasi",
                            "Tirupattur",
                            "Thoothukudi (Tuticorin)",
                            "Tiruchirappalli",
                            "Tirunelveli",
                            "Tiruppur",
                            "Tiruvallur",
                            "Tiruvannamalai",
                            "Tiruvarur",
                            "Vellore",
                            "Viluppuram",
                            "Virudhunagar",
                          ],
                        },
                        {
                          name: "Telangana",
                          states: [
                            "Adilabad",
                            "Bhadradri Kothagudem",
                            "Hyderabad",
                            "Jagtial",
                            "Jangaon",
                            "Jayashankar Bhoopalpally",
                            "Jogulamba Gadwal",
                            "Kamareddy",
                            "Karimnagar",
                            "Khammam",
                            "Komaram Bheem Asifabad",
                            "Mahabubabad",
                            "Mahabubnagar",
                            "Mancherial",
                            "Medak",
                            "Medchal",
                            "Mulugu",
                            "Narayanpet",
                            "Nagarkurnool",
                            "Nalgonda",
                            "Nirmal",
                            "Nizamabad",
                            "Peddapalli",
                            "Rajanna Sircilla",
                            "Rangareddy",
                            "Sangareddy",
                            "Siddipet",
                            "Suryapet",
                            "Vikarabad",
                            "Wanaparthy",
                            "Warangal (Rural)",
                            "Warangal (Urban)",
                            "Yadadri Bhuvanagiri",
                          ],
                        },
                        {
                          name: "Tripura",
                          states: [
                            "Dhalai",
                            "Gomati",
                            "Khowai",
                            "North Tripura",
                            "Sepahijala",
                            "South Tripura",
                            "Unakoti",
                            "West Tripura",
                          ],
                        },
                        {
                          name: "Uttarakhand",
                          states: [
                            "Almora",
                            "Bageshwar",
                            "Chamoli",
                            "Champawat",
                            "Dehradun",
                            "Haridwar",
                            "Nainital",
                            "Pauri Garhwal",
                            "Pithoragarh",
                            "Rudraprayag",
                            "Tehri Garhwal",
                            "Udham Singh Nagar",
                            "Uttarkashi",
                          ],
                        },
                        {
                          name: "Uttar Pradesh",
                          states: [
                            "Agra",
                            "Aligarh",
                            "Allahabad",
                            "Ambedkar Nagar",
                            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
                            "Amroha (J.P. Nagar)",
                            "Auraiya",
                            "Ayodhya",
                            "Azamgarh",
                            "Baghpat",
                            "Bahraich",
                            "Ballia",
                            "Balrampur",
                            "Banda",
                            "Barabanki",
                            "Bareilly",
                            "Basti",
                            "Bhadohi",
                            "Bijnor",
                            "Budaun",
                            "Bulandshahr",
                            "Chandauli",
                            "Chitrakoot",
                            "Deoria",
                            "Etah",
                            "Etawah",
                            "Faizabad",
                            "Farrukhabad",
                            "Fatehpur",
                            "Firozabad",
                            "Gautam Buddha Nagar",
                            "Ghaziabad",
                            "Ghazipur",
                            "Gonda",
                            "Gorakhpur",
                            "Hamirpur",
                            "Hapur (Panchsheel Nagar)",
                            "Hardoi",
                            "Hathras",
                            "Jalaun",
                            "Jaunpur",
                            "Jhansi",
                            "Kannauj",
                            "Kanpur Dehat",
                            "Kanpur Nagar",
                            "Kasganj",
                            "Kanshiram Nagar (Kasganj)",
                            "Kaushambi",
                            "Kushinagar (Padrauna)",
                            "Lakhimpur - Kheri",
                            "Lalitpur",
                            "Lucknow",
                            "Maharajganj",
                            "Mahoba",
                            "Mainpuri",
                            "Mathura",
                            "Mau",
                            "Meerut",
                            "Mirzapur",
                            "Moradabad",
                            "Muzaffarnagar",
                            "Pilibhit",
                            "Prayagraj",
                            "Pratapgarh",
                            "RaeBareli",
                            "Rampur",
                            "Saharanpur",
                            "Sambhal (Bhim Nagar)",
                            "Sant Kabir Nagar",
                            "Shahjahanpur",
                            "Shamali (Prabuddh Nagar)",
                            "Shravasti",
                            "Siddharth Nagar",
                            "Sitapur",
                            "Sonbhadra",
                            "Sultanpur",
                            "Unnao",
                            "Varanasi",
                          ],
                        },
                        {
                          name: "West Bengal",
                          states: [
                            "Alipurduar",
                            "Bankura",
                            "Birbhum",
                            "Burdwan (Bardhaman)",
                            "Cooch Behar",
                            "Dakshin Dinajpur (South Dinajpur)",
                            "Darjeeling",
                            "Hooghly",
                            "Howrah",
                            "Jalpaiguri",
                            "Kalimpong",
                            "Kolkata",
                            "Malda",
                            "Murshidabad",
                            "Nadia",
                            "North 24 Parganas",
                            "Paschim Medinipur (West Medinipur)",
                            "Purba Medinipur (East Medinipur)",
                            "Purulia",
                            "South 24 Parganas",
                            "Uttar Dinajpur (North Dinajpur)",
                          ],
                        },
                      ],
                      states: [],
                      selectedState: "",
                      selectedDistrict: "",
                    },
                    isShowPassword: false,
                    isShowConfirmPassword,
                  });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isShowPassword } = this.state;
    return (
      <div className="container-fluid backgroundnav">
        <header>
          <a href="/" className="logo5" style={{ color: "white" }}>
            <img
              src={require("../../assets/images/site-logo.png").default}
              height="130%"
              alt="logo"
            />
          </a>
          <div className="menuToggle5" onClick={this.handleToogleClick}>
            <img
              className="magese5"
              // eslint-disable-next-line @typescript-eslint/no-var-requires
              src={require("../../assets/images/menu.png").default}
              width="80%"
              style={{ paddingBottom: "10px" }}
            />
          </div>
          <nav className={this.state.isToggleOn ? "active2" : null}>
            <ul>
              <li>
                <a href="#" className="active2"></a>
              </li>
              <li>
                <Link
                  to="/home"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Contact Us
                </Link>
              </li>

              {/* <Link to="/mobileapp" style={{ textDecoration: "none" }}>
                <li>
                  <a href="#" style={{ color: "white" }}>
                    Mobile App
                  </a>
                </li>
              </Link> */}
              {/* <Link to="/" style={{ textDecoration: "none" }}>
                <li>
                  <a href="#" style={{ color: "white" }}>
                    Contact Us
                  </a>
                </li>
              </Link> */}
            </ul>
          </nav>
          <div className="clearfix5"></div>
        </header>
        <div className=" login-container">
          {[this.RegisterModal(), this.ForgotPassWord()]}

          <div className="text-container">
            <div>
              <h1
                className="main-text"
                style={{
                  color: "darkblue",
                  fontWeight: "bolder",
                  textShadow: "0 0 aquamarine",
                }}
              >
                Lets get into real <br />
                <span style={{ color: "black", fontWeight: "bolder" }}>
                  competition...
                </span>
              </h1>
            </div>
          </div>
          <div className="card">
            <div className="card-body login-card">
              <div className="signin-header">
                <h1 className="h1 login-text">Sign-In</h1>
              </div>
              <form
                className="form-signin"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="input-container">
                  <label htmlFor="inputEmail" className="sr-only">
                    EMAIL ID
                  </label>
                  <MdEmail className="input-icon" />
                  <input
                    type="email"
                    id="inputEmail"
                    name="emailId"
                    className="form-control"
                    placeholder="Email ID"
                    value={this.state.emailID}
                    onChange={(e) =>
                      this.setState({
                        emailID: e.target.value,
                      })
                    }
                    required
                  />
                </div>

                <div className="input-container">
                  <label htmlFor="inputPassword" className="sr-only">
                    Password
                  </label>
                  <MdVpnKey className="input-icon" />
                  <input
                    type={isShowPassword ? "text" : "password"}
                    id="inputPassword"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                    required
                  />
                  <MdVisibility
                    className="input-icons"
                    onClick={this.togglePasswordVisibility}
                  ></MdVisibility>
                </div>
                {this.props.error && (
                  <strong className="errorboxApi">
                    <div className="alert alert-danger" role="alert">
                      {this.props.error}
                    </div>
                  </strong>
                )}

                <button
                  className={`btn btn-lg btn-success btn-block ${
                    this.state.emailID === "" ||
                    this.state.password === "" ||
                    this.props.isFetching
                      ? "disabled"
                      : ""
                  }`}
                  type="submit"
                  style={{ width: "75%" }}
                  disabled={
                    this.state.emailID === "" ||
                    this.state.password === "" ||
                    this.props.isFetching
                  }
                  onClick={() =>
                    this.props.fetchAndHandleAuthentication(
                      this.props.history,
                      this.state.emailID,
                      this.state.password
                    )
                  }
                >
                  {this.props.isFetching ? "Logging In.." : "Login"}
                </button>

                <div className="forgotPassAndRemember">
                  <div className="rembermerMe">
                    <label>
                      <input
                        type="checkbox"
                        name="rememberMe"
                        checked={this.state.rememberMe}
                        onChange={this.handleChange}
                      />{" "}
                      Remember Me!{" "}
                    </label>
                  </div>
                  <div className="forgotPass"></div>

                  <span>
                    <Link to={null}>
                      <a data-toggle="modal" data-target="#forgotPassword">
                        Forgot Password?
                      </a>
                    </Link>
                  </span>
                </div>

                <div className="separator">OR</div>
                <button
                  id="regBtn"
                  type="button"
                  className="btn btn-lg btn-primary btn-block register-button"
                  data-toggle="modal"
                  data-target="#registerModal"
                  style={{ width: "75%" }}
                  onClick={() => {
                    this.setState({
                      registerErrors: {
                        emailID: false,
                        firstName: false,
                        lastName: false,
                        mobileNumber: false,
                        password: false,
                        confirmPassword: false,
                        selectedState: false,
                        selectedDistrict: false,
                      },
                    });
                  }}
                >
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Login = connect(
  (state: any) => {
    return {
      isFetching: state.users.isFetching,
      error: state.users.error,
      isAuthed: state.users.isAuthed,
      user: state.users.user,
      isProcessing: state.users.isProcessing,
    };
  },
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(LoginComponent);

export { Login };
