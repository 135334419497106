import "./profileNavBar.scss";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActionCreators from "../../redux/users/users.actions";
import { MdPowerSettingsNew, MdVisibility } from "react-icons/md";
import { base_url } from '../../config/constants';
// import { INavigationComponentProps } from "../navigation/navigation.types";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {errors} from "../../utilities/errors";
const noUserAvtarImage = require("../../assets/images/no-user-avatar.png");

class ProfileNavBarComponent extends React.Component<any> {
  public state: any = {};
  public newPass: any;
  public confirmNewPass: any;
  constructor(props: any) {
    super(props);
    this.state = {
      currentPass: "",
      newPass: "",
      confirmNewPass: "",
      checkMessage: "",
      isShowPassword: false,
      isShowCurrentPassword:false,
      selectedField: null,
      message: "",
      resetSuccessfully: false
    };
  }

  fileSelectorHandler = event =>
  {
    this.setState({
      selectedField:event.target.files[0]
    })

  }
  handleCurrentPass = (e) => {
    e.preventDefault();
    this.setState({
      currentPass: e.target.value,
    });
  };
  handleNewPass = (e) => {
    e.preventDefault();
    this.setState({
      newPass: e.target.value,
    });
  };
  handleConfirmNewPass = (e) => {
    e.preventDefault();
    this.setState({confirmNewPass:e.target.value,});
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const resetPassResp = await axios.post(
        `${base_url}/api/Login/ResetPassword`,
        {
          AuthToken: this.props.user.authToken,
          UserId: this.props.user.uid,
          OldPassword: this.state.currentPass,
          NewPassword: this.state.confirmNewPass,
        }
      );
     

      this.setState({
        checkMessage: (
          <p style={{ color: "green" }}>{resetPassResp.data.Message}</p>
        ),
        resetSuccessfully: true
      })
    } catch (error) {
      if (error.response) {
        const responseStatus = error.response.data.ResponseStatus;
        const errorMessage: any = errors.resetPassword[responseStatus].msg;
        this.setState({
          checkMessage: <p style={{ color: "red" }}>{errorMessage}</p>,
          resetSuccessfully: false
        });
      }
    }
  };


  togglePasswordVisibility = () => {
    const { isShowPassword } = this.state;
    this.setState({ isShowPassword: !isShowPassword });
  };
   toggleShowConfirmPasswordVisibility = () => {
    const { isShowConfirmPassword } = this.state;
    this.setState({ isShowConfirmPassword: !isShowConfirmPassword });
  };
  toggleCurrentPasswordVisibility = () => {
    const { isShowCurrentPassword } = this.state;
    this.setState({ isShowCurrentPassword: !isShowCurrentPassword });
  }; 
  myHandler = (events) => {
    this.setState({
      selectedField: events.target.files[0],
    });
  };
  submitHandler = (event) => {
    event.preventDefault();
    // console.log(this.state.selectedField);
    if (!this.state.selectedField) {
      this.setState({
        message: <p style={{ color: "red" }}>Invalid Input</p>,
      });
    } else {
      this.setState({
        message: <p style={{ color: "green" }}>Successfully Submitted</p>,
      });
    }
  };

  UploadImageInNav = () => {

    const UploadImageToServer = async (event) => {
      event.preventDefault();
      // console.log(this.state.selectedField);
      if (!this.state.selectedField) {
        this.setState({
          message: <p style={{ color: "red" }}>Invalid Input</p>,
        });
      } else {
        try{
          const url = `${base_url}/api/Login/ProfileImage`;
          const formData = new FormData();
          formData.append('file',this.state.selectedField);
          console.log("njknknk",this.state.selectedField)
          formData.append('Userid',this.props.user.uid);
          formData.append('AuthToken',this.props.user.authToken);
          const config = {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          }
          const uploadImageResponse = await axios.post(url, formData,config);
          if (uploadImageResponse && uploadImageResponse.data) {
            this.setState({
              message:(<p style={{ color: "green" }}>{errors.uploadImg[200].msg}</p>),
            });
            this.props.updateProfilePic(uploadImageResponse?.data?.ProfileImageURL);
            const imageField: any = document.querySelector('#profilePic');
            imageField.value = '';
          }
        } catch(error) {
          if(error.response)
          {
            const responseStatus = error.response.data.ResponseStatus;
            const errorMessage: any = errors.uploadImg[responseStatus].msg;
            this.setState({
              message:(<p style={{ color: "red" }}>{errorMessage}</p>),
            })
          }
        }
      }
  };

    return (
      <div>
        <div
          className="modal"
          id="uploadImage"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload Profile</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.setState({
                      selectedField: "",
                    });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                  <div className="form-group">
                    <input
                      onChange={this.fileSelectorHandler}
                      type="file"
                      name="pic"
                      id="profilePic"
                      accept="image/*"
                    />
                  </div>
                  <p>
                    <strong>{this.state.message}</strong>
                  </p>
                  <input
                    type="submit"
                    className="btn btn-success"
                    value="Upload"
                    onClick={UploadImageToServer}
                  />
            
              </div>
              <div className="modal-footer">
                <input
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={()=> {
                    this.setState({
                       currentPass: "",
                              newPass: "",
                              confirmNewPass: "",
                              checkMessage: "",
                      message: "",
                      selectedField: null,
                    });
                  }}
                  value="Cancel"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  ResetPassword = () => {
    const { isShowPassword,  isShowConfirmPassword, isShowCurrentPassword, resetSuccessfully } = this.state;
    return (
        <>
          {!resetSuccessfully && <div
                onClick={() => {
                  this.setState({
                    checkMessage: "",
                  });
                }}
                id="resetPwd"
                className="modal fade"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            currentPass: "",
                            newPass: "",
                            confirmNewPass: "",
                            checkMessage: "",
                            isShowPassword:false,  
                            isShowConfirmPassword:false, 
                            isShowCurrentPassword:false
                          });
                        }}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <div className="panel-body">
                          <div className="text-center">
                            <div className="panel-body">
                              <fieldset>
                                <div className="form-group">
                                  <input
                                      className="form-control input-lg"
                                      placeholder="Enter Current Password"
                                      name="currentPass"
                                      onChange={this.handleCurrentPass}
                                      value={this.state.currentPass}
                                      type={isShowCurrentPassword ? "text" : "password"}
                                      required
                                      data-parsley-type="email"
                                  ></input>
                                  <MdVisibility
                                      style={{ right: "9%", marginTop: "-6%" }}
                                      className="input-icons"
                                      onClick={this.toggleCurrentPasswordVisibility}
                                  ></MdVisibility>
                                </div>
                                <div className="form-group">
                                  <input
                                      className="form-control input-lg"
                                      placeholder="Enter New Password"
                                      name="newPass"
                                      onChange={this.handleNewPass}
                                      value={this.state.newPass}
                                      type={isShowPassword ? "text" : "password"}
                                      required
                                  ></input>
                                  <MdVisibility
                                      style={{ right: "9%", marginTop: "-6%" }}
                                      className="input-icons"
                                      onClick={this.togglePasswordVisibility}
                                  ></MdVisibility>
                                </div>
                                <div className="form-group">
                                  <input
                                      className="form-control input-lg"
                                      placeholder="Enter Confirm New Password"
                                      onChange={this.handleConfirmNewPass}
                                      name="confirmNewPass"
                                      value={this.state.confirmNewPass}
                                      type={isShowConfirmPassword ? "text" : "password"}
                                      required
                                  ></input>
                                  <MdVisibility
                                      style={{ right: "9%", marginTop: "-6%" }}
                                      className="input-icons"
                                      onClick={this.toggleShowConfirmPasswordVisibility}
                                  ></MdVisibility>
                                </div>
                                <strong>
                                  <div className="text-center">
                                    <p>{this.state.checkMessage}</p>
                                  </div>
                                </strong>
                                <button
                                    className={`btn btn-lg btn-success btn-block ${
                                        this.state.newPass !==
                                        this.state.confirmNewPass ? "disabled": ""
                                    }`}
                                    disabled={
                                      this.state.newPass !==
                                      this.state.confirmNewPass
                                    }
                                    onClick={this.handleSubmit}
                                    value="Reset Password"
                                >
                                  Reset Password
                                </button>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                   
                      <button
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          aria-hidden="true"
                          onClick={() => {
                            this.setState({
                              currentPass: "",
                              newPass: "",
                              confirmNewPass: "",
                              checkMessage: "",
                              isShowPassword:false,  
                              isShowConfirmPassword:false, 
                              isShowCurrentPassword:false
                            });
                          }}
                      >
                        Cancel
                      </button>
                   
                  </div>
                </div>
              </div>
            </div>
          }
          {resetSuccessfully && <Modal show={true}>
            <Modal.Body>Password Reset Successfully</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => {
                  this.props.unauthUser();
                  const {
                    history: { replace },
                  } = this.props;

                  replace("/dashboard");
                }
              }>
                Okay
              </Button>
            </Modal.Footer>
          </Modal>}
        </>
    );
  };

  render() {
    const {firstName, lastName, ProfileImageURL} = this.props.user;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light profile-nav"
        onContextMenu={(e) => e.preventDefault()}
      >
        {" "}
        {[this.ResetPassword(), this.UploadImageInNav()]}
        <div className="container brand-container">
          <a className="navbar-brand" href="#">
            <img src={require("../../assets/images/site-logo.png").default} height="130%" alt="logo" />
          </a>
        </div>
        <div className="container profile-container">
          <div className="profile-name">{firstName} {lastName}</div>
          <div className="profile-image dropdown">
            <img
              className="rounded-circle ppointerAdding"
              alt="100x100"
              src={ProfileImageURL || noUserAvtarImage.default}
              data-holder-rendered="true"
              data-toggle="dropdown"
              aria-expanded="false"
              id="dropdownMenu2"
              aria-haspopup="true"
            />
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenu2"
            >
              <button
                className="dropdown-item"
                type="button"
                data-target="#uploadImage"
                data-toggle="modal"
              >
                Change Profile Image
              </button>
              <button
                className="dropdown-item"
                type="button"
                data-target="#resetPwd"
                data-toggle="modal"
              >
                Reset Password
              </button>
            </div>
          </div>
{/*           <div className="dropdown">
            <MdEdit
              className="ppointerAdding"
              data-toggle="dropdown"
              aria-expanded="false"
              id="dropdownMenu2"
              aria-haspopup="true"
            ></MdEdit>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenu2"
            >
              <button
                className="dropdown-item"
                type="button"
                data-target="#uploadImage"
                data-toggle="modal"
              >
                Change Profile Image
              </button>
              <button
                className="dropdown-item"
                type="button"
                data-target="#resetPwd"
                data-toggle="modal"
              >
                Reset Password
              </button>
            </div>
          </div> */}

          <div className="logout-button-container">
            <MdPowerSettingsNew
              className="logout-button"
              data-toggle="modal"
              data-target="#logoutModal"
            />
          </div>
        </div>
      </nav>
    );
  }
}

const ProfileNavBar = connect(
  (state: any) => {
    return {
      isAuthed: state.users.isAuthed,
      isFetching: state.users.isFetching,
      error: state.users.error,
      user: state.users.user,
      ProfileImageURL: state.users?.ProfileImageURL ?? state.users.user.ProfileImageURL
    };
  },
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(ProfileNavBarComponent);

export { ProfileNavBar };
