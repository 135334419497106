import * as React from "react";
// import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import {IntrestSubmition} from '../OnlineMockupMobileAppComponents/submitionform'
import * as userActionCreators from "../../redux/users/users.actions";
import "./main.scss";
function MainBodyAbout() {
    const [foundersShow, setFoundersShow] = React.useState(true)
    const [coFoundersShow, setCoFoundersShow] = React.useState(false)
    const [marketterShow, setMarketterShow] = React.useState(false)
    const [technicalShow, setTechnicalShow] = React.useState(false)
    const [designShow, setDesignShow] = React.useState(false)
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         foundersShow: true,
    //         coFoundersShow:false
    //     };

    //     this.founders = this.founders.bind(this);
    //     this.coFounders = this.coFounders.bind(this);
    //   }

    function founders() {
        setCoFoundersShow(false)
        setFoundersShow(true)
        setMarketterShow(false)
        setTechnicalShow(false)
        setDesignShow(false)
    }
    function coFounders() {

        setFoundersShow(false)
        setCoFoundersShow(true)
        setMarketterShow(false)
        setTechnicalShow(false)
        setDesignShow(false)
    }
    // function marketting() {

    //     setFoundersShow(false)
    //     setCoFoundersShow(false)
    //     setMarketterShow(true)
    //     setTechnicalShow(false)
    //     setDesignShow(false)
    // }

    function technical() {
        setFoundersShow(false)
        setCoFoundersShow(false)
        setMarketterShow(false)
        setTechnicalShow(true)
        setDesignShow(false)
    }
    function design() {
        setFoundersShow(false)
        setCoFoundersShow(false)
        setMarketterShow(false)
        setTechnicalShow(false)
        setDesignShow(true)
    }
    // render() {
    return (
        <div className="mainBodyContent">
            <div>
                <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto" }}>
                    <img id="myTeam" width="100%" height="100%" src={require("./5C4A0126.jpg").default} alt="Our team" />
                </div>

                <div className="'='lw p2">
                    <div className="cw">
                        {/* <div style={{ marginLeft: "10%" }}>
                            <div style={{ height: "5px", backgroundColor: "blue", width: "100px", marginTop: "20px", borderRadius: "15px" }}>
                            </div>
                            <p className="title relative" style={{ marginTop: "-10px" }}>ABOUT US</p>
                        </div> */}
                        {/* <p className="title relative" style={{ marginTop: "-10px" }}>ABOUT US</p> */}

                        <div className="flex gridlgrowsmcol">
                            {/* <p style={{fontFamily:"Quicksand"}} className="flex-1"><b>Prola Tech is an IT services company providing specialized services in mobile application development, web application development, Internet of Things (IoT), web services, and cloud based solutions. Prola Tech provides end-to-end services from planning, development, implementation to maintenance.</b></p>
                    <p style={{fontFamily:"Quicksand"}} className="flex-1">All startups have a directional goal, vision, and passion to achieve the best in its area of expertise. Prola Tech is the brainchild of a group of like-minded technology specialists with more than 2 decades of IT experience and expertise.
                        We collaborate with a network of engineers, technical consultants, and subject matter experts to deliver services on par with CMMI standards.</p>
                    <p style={{fontFamily:"Quicksand"}} className="flex-1">Although we are in the nascent stages, we are backed by principal leaders in the IT sector who provide intellectual guidance and advice, and propel us to be the best in the industry.<br/><br/>Our office headquarters is in Bengaluru and
                        close to global village technological park, one of the IT hubs in the Silicon Valley.<br/><br/> So what are you waiting for, call us or write to us and we assure you of our committed services.</p>
                    <div className="flex-1"><img className="flex-1" src={require("./abtquote1.png")} alt="Quote that matters to us"/></div> */}
                            <p className="title relative" style={{ marginTop: "10px" }}>ABOUT US</p>
                            <p style={{ fontFamily: "Quicksand", fontWeight: "bold" }} className="flex-1">Prola Tech is an IT services and product-based company offering a broad spectrum of services, including:<br /> <br />
                                ●	Mobile Application Development<br />
                                ●	Web Application Development<br />
                                ●	Internet of Things (IoT)<br />
                                ●	Web Services and<br />
                                ●	Cloud-based Services<br /><br />
                                Prola Tech provides end-to-end services from planning, developing, implementing, and managing. It helps businesses perform in an efficient, faster, and profitable manner.
                            </p>
                            <p style={{ fontFamily: "Quicksand" }} className="flex-1">
                                We also develop products with cutting edge technologies in various verticals such as education. Currently we have “Prolaskool” digital learning mobile application for all competitive exams and corporate courses.
                                <br /><br />
                                Like all startups, we are fueled with a directional goal, vision, and passion for achieving the best in our area of expertise. Prola Tech is the brainchild of a group of
                                like-minded technology specialists owing more than two decades of IT experience and expertise. We collaborate with a network of engineers, technical consultants, and
                                subject matter experts to deliver services on par with CMMI standards.  </p>
                            <p style={{ fontFamily: "Quicksand" }} className="flex-1">Our end-to-end solutions are hassle-free, cost-effective, and time-saving, making them a best-in-class option for business owners. All this is possible only because we are backed by principal leaders in the IT sector, providing us with intellectual guidance and advice and propelling us to be the best in the industry.
                                <br /><br />
                                We are transparent in all our approaches and communication. So what are you waiting for? Call us or write to us, and we assure you of our committed services.
                            </p>
                            {/* <div className="flex-1"><img className="flex-1" src={require("./abtquote1.png")} alt="Quote that matters to us"/></div> */}
                        </div>
                        <div className="flex-1"><img className="flex-1" style={{border:"10px solid #000"}} src={require("./abtquote1.png").default} alt="Quote that matters to us" /></div>
                    </div>
                </div>
                {/* <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div id="visionContainer">
                        <div id="visionImageContainer">
                            <img id="vision" width="100%" src={require("./vision_mobile.png")} alt="img" />
                        </div>
                        <div id="visionText">
                            <h1 id="visionTexts" style={{ fontFamily: "Quicksand" }}>
                                Our<br />Vision<br />Statement<p />
                            </h1>
                            <p id="visionTexts" style={{ fontSize: "18px", color: "grey" }}>To provide a seamless and <br /> holistic technology solution to <br /> all businesses worldwide.<br />
                                With that in mind, we <br />ventured into this business, aimin <br />to create an exclusive<br />
                                space for ourselves in <br /> the market. We wish to work  <br />with absolute dedication and<br />
                                be our clients&apos; most<br /> trusted organization<br /> and partner.
                            </p>
                        </div>
                    </div>

                    
                </div> */}<br /><br />
                <div className="fw p3" style={{ marginLeft: "auto", marginRight: "auto", backgroundColor: "white" }}>
                    <br /><br />
                    <div className="cw">
                        <div className="flex gridlgrowsmrow">
                            <div className="image" style={{ marginRight: "-5px", marginBottom: "auto" }}>
                                <img className="lgshowsmhide" src={require("./vision.png").default} alt=" Our Vision" />
                                <img className="lghidesmshow" src={require("./vision_mobile.png").default} width="100%" alt="Our Vision (Mobile View)" />
                            </div>
                            <div className=" flex-1">
                                <p className="title" style={{ fontSize: "40px" }}>Our Vision Statement  </p>
                                <p>
                                    To provide a seamless and holistic technology solution to all businesses worldwide. With that in mind, we have ventured into this business and aim to create an exclusive space for ourselves in the market. We wish to work with absolute dedication and be our clients&apos; most trusted organization and partner.
                                </p>
                            </div>
                            <div className="image lghidesmshow">
                                <img className="" src={require("./vision2_mobile.png").default} width="100%" alt="Our vision depicting image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "2%", backgroundColor: "white" }}>
                    <h1 style={{ textAlign: "center", fontFamily: "Quicksand" }}>Our Team Our Pride - Meet Them</h1>
                    <p style={{ textAlign: "center", color: "grey" }}>Developing an organization that runs successfully
                        requires a long-term vision, a powerhouse of<br /> information, and a full-time guiding force. That&apos;s what
                        our founders are all about - Meet our founders!</p>
                </div>
                <div id="memberListContainer">
                    <div id="memberList">
                        <button id={foundersShow ? "memberButtonActive" : "memberButton"} onClick={founders} >FOUNDERS</button>
                        <button id={coFoundersShow ? "memberButtonActive" : "memberButton"} onClick={coFounders} >CO-FOUNDERS</button>
                        <button id={technicalShow ? "memberButtonActive" : "memberButton"} onClick={technical} >TECHNICAL TEAM</button>
                        {/* <button id={marketterShow ? "memberButtonActive" : "memberButton"} onClick={marketting} >MARKETING TEAM</button> */}
                        <button id={designShow ? "memberButtonActive" : "memberButton"} onClick={design} >COMPANY ADVISORS</button>
                        
                    </div>
                </div>

                {foundersShow ?
                    // founders list 
                    <div id="teamMembers">

                        <div id="memberContainer">
                            <div id="memberDetailsContainer">
                                <div id="techteamimage">
                                    <img src={require("./founder.png").default} height="100%" alt="profile" />
                                </div>
                                <div id="membersNameAndDescription">
                                    <h3 id="memerAndDescriptionText" className="aligncenter">Shyam Sundar</h3>
                                    <p className="aligncenter">Founder Director</p>
                                    <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>There&apos;s a time in everyone&apos;s lives when we pause and contemplate on the journey, rethink and reinvent. And he decided to begin a new journey with Prola Tech.<br /> He comes with more than 20 years of experience in the IT
                                        sector with expertize is a plethora of domains, industries and technologies. A go-getter by nature, he derives strength from his vast industry experience and aims to achieve bigger milestones with this venture.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    : coFoundersShow ?
                        // Co-Founders list
                        <div id="teamMembers">
                            <div id="memberContainer">
                                <div id="memberDetailsContainer">
                                    <div id="techteamimage">
                                        <img src={require('./Shubha.png').default} height="100%" alt="profile"/>
                                    </div>
                                    <div id="membersNameAndDescription">
                                        <h3 id="memerAndDescriptionText" className="aligncenter">Mrs Shubha PM</h3>
                                        <h5 className="aligncenter">Bsc, MSc. Entrepreneur, Environmentalist and Active Citizen</h5>
                                        <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Born and educated in mysore, she is an out of box thinker.<br />
                                            She is passionate about education and civic awareness for children and
                                            volunteers with many organisations who
                                            work towards a plasticfree and sustainable living environment. <br/>She is always ready to offer her support
                                            and guidance to promote a cleaner and
                                            greener world.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="teamMembers">
                                <div id="memberContainer">
                                    <div id="memberDetailsContainer">
                                        <div id="techteamimage">
                                            <img src={require("./Sridhar K.png").default} height="100%" alt="profile"/>
                                        </div>
                                        <div id="membersNameAndDescription">
                                            <h3 id="memerAndDescriptionText" className="aligncenter">Sridhar K</h3>
                                            <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Mr. K. Sridhar completed his Bachelor of Engineering in I.P.E from Bangalore Institute of Technologies, Bangalore University. He started his career by working with some of the top Engineering industries. It gave him immense knowledge in capturing excellent managerial capabilities, and his foresight made him a successful businessman. He joined hands with like-minded people to do good work in the education and I.T. sector and wanted to bring in positive societal changes. His vision is to empower urban and rural towns and villages in India by bringing modern technologies and digitally empowered classrooms to their doorstep.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="teamMembers">

                                <div id="memberContainer">
                                    <div id="memberDetailsContainer">
                                        <div id="techteamimage">
                                            <img src={require("./venkat rami reddy.png").default} height="100%" alt="profile" />
                                        </div>
                                        <div id="membersNameAndDescription">
                                            <h3 id="memerAndDescriptionText" className="aligncenter">Venkat Rami Reddy</h3>
                                            <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Mr. Venkat Rami Reddy N, hailing from Kakinada, India, 
                                            completed his Master of Technology from J.N.T.U. and gained tremendous knowledge in 
                                            marketing, sales, and communication. He worked in Deloitte for five years and 
                                            contributed his expertise to Kaktus Info Solution as a Quality Analyst. However, 
                                            he always wanted to come back to India, construct a stage for learners, and make 
                                            noteworthy contributions to the Indian education system.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                {/* <div id="teamMembers" style={{ marginTop: "20px" }}>

                                    <div id="memberContainer">
                                        <div id="memberDetailsContainer">
                                            <div id="memberImage">
                                                <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                            </div>
                                            <div id="membersNameAndDescription">
                                                <h3 id="memerAndDescriptionText">Co-Founder name</h3>
                                                <p id="memerAndDescriptionText">Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet<br />Loreum ipsum dolor sit
                                                    amet
                                                    <br />Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet
                                                </p>
                                            </div>
                                        </div>

                                    </div>


                                </div> */}
                            </div>
                        </div>
                        : marketterShow ?
                            // marketting list
                            <div id="teamMembers">

                                <div id="memberContainer">
                                    <div id="memberDetailsContainer">
                                        <div id="techteamimage">
                                            <img src={require("./ggowda.png").default} height="100%" alt="profile" />
                                        </div>
                                        <div id="membersNameAndDescription">
                                            <h3 id="memerAndDescriptionText" className="aligncenter">GOPAL GOWDA H K</h3>
                                            <h5> Chief of Business and Operations</h5>
                                            <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Mr. GOPAL GOWDA H K, graduated with a Bachelor of 
                                            Engineering from Bangalore University and a Master of Technology from V.T.U., 
                                            Karnataka. He has vast teaching and admin experience with premier educational 
                                            & technical institutions.  He coordinated with many entrepreneurs and research 
                                            organisations in training and personality development as well as R&D. <br/> This experience 
                                            made him passionate about teaching and guiding the engineering and technology aspirants 
                                            to achieve their goals. He also worked for NGOs to establish production and marketing 
                                            with proper SCM by educating and training the human resources involved in the system.
                                            He is personally a patience listener and best counsellor also good negotiator with convincing skills.  
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                {/* <div id="teamMembers">

                                    <div id="memberContainer">
                                        <div id="memberDetailsContainer">
                                            <div id="memberImage">
                                                <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                            </div>
                                            <div id="membersNameAndDescription">
                                                <h3 id="memerAndDescriptionText">Marketter Name</h3>
                                                <p id="memerAndDescriptionText">Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet<br />Loreum ipsum dolor sit amet
                                                    <br />Loreum ipsum dolor sit amet Loreum ipsum dolo
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                    <div id="teamMembers" style={{ marginTop: "20px" }}>

                                        <div id="memberContainer">
                                            <div id="memberDetailsContainer">
                                                <div id="memberImage">
                                                    <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                                </div>
                                                <div id="membersNameAndDescription">
                                                    <h3 id="memerAndDescriptionText">Marketter Name</h3>
                                                    <p id="memerAndDescriptionText">Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet<br />Loreum ipsum dolor sit
                                                        amet
                                                        <br />Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet
                                                    </p>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div> */}
                            </div>
                            : technicalShow ?
                                // technical team list
                                <div id="teamMembers">

                                    <div id="memberContainer">
                                        <div id="memberDetailsContainer">
                                            <div id="techteamimage">
                                                <img src={require("./pranshu jain.png").default} height="100%" alt="profile" />
                                            </div>
                                            <div id="membersNameAndDescription">
                                                <h3 id="memerAndDescriptionText" className="aligncenter"> Pranshu Jain</h3>
                                                <h5 className="aligncenter">Chief Technical Advisor</h5>
                                                <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Pranshu Jain Chief Enterprise Architect at Navikenz, Bengaluru, India
                                                    Electrical Engineering from IIT BHU.<br />
                                                    He is a solution architect and practice leader with over 25 years of experience in providing solution architecture for digital solutions and building technology practices in emerging areas.
                                                    He has supported customers across Travel, Retail, CPG, Media, Technology and Manufacturing industries.
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div id="teamMembers">

                                        <div id="memberContainer">
                                            <div id="memberDetailsContainer">
                                                <div id="techteamimage">
                                                    <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                                </div>
                                                <div id="membersNameAndDescription">
                                                    <h3 id="memerAndDescriptionText">Narayanan Duraiswami</h3>
                                                    <p id="memerAndDescriptionText">Narayanan Duraiswami, is a versatile leader with a proven track record in strategizing organizational objectives of Systems, IT and Operations Management. 

Narayanan has played management roles at multiple organizations like Xoriant, Microsoft, Cisco, Chicago Mercantile Exchange, MyndGenie, Akraya and Ramco Systems, where he was responsible for Program Management, Product Development, building systems to drive business growth and efficiency. Narayanan is a qualified Neuro-linguistic Programming Practitioner and has conducted multiple seminars at schools and colleges to help students improve their learning abilities through NLP techniques. He holds a Mining and Mechanical engineering degree from the College of Engineering, Guindy, Chennai.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>  */}
                                </div>
                                :
                                // designers list
                                <div id="teamMembers">

                                    <div id="memberContainer">
                                        <div id="memberDetailsContainer">
                                            <div id="techteamimage">
                                                <img src={require("./sandeep agarval.png").default} height="100%" alt="profile" />
                                            </div>
                                            <div id="membersNameAndDescription">
                                                <h3 id="memerAndDescriptionText" className="aligncenter"> Sandeep Agarval</h3>
                                                <h5 className="aligncenter">Executive Vice President (EVP) at Visionet 
                                                Systems Inc., Bangalore, India</h5>
                                                <p id="memerAndDescriptionText" style={{textAlign:"justify"}}> Having Industry Experience of 27+ years and worked 
                                                for Happiest Minds Technologies, Mindtree, Lucent Technologies, and Tata Infotech. 
                                                Successfully executed different projects/ programs/ Verticals/ Business Lines and 
                                                helped many fortune 500 companies to do outsourcing and build extended quality teams 
                                                and scale their business.”
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="teamMembers">
                                    <div id="memberContainer">
                                            <div id="memberDetailsContainer">
                                                <div id="techteamimage">
                                                    <img src={require("./Narayanan Duraiswami.png").default} height="100%" alt="profile" />
                                                </div>
                                                <div id="membersNameAndDescription">
                                                    <h3 id="memerAndDescriptionText" className="aligncenter">Narayanan Duraiswami</h3>
                                                    <h5 className="aligncenter">Senior Consultant for Start-Ups</h5>
                                                    <p id="memerAndDescriptionText" style={{textAlign:"justify"}}>Narayanan Duraiswami, is a versatile leader with a proven track record in strategizing organizational objectives of Systems, IT and Operations Management. 

Narayanan has played management roles at multiple organizations like Xoriant, Microsoft, Cisco, Chicago Mercantile Exchange, MyndGenie, Akraya and Ramco Systems, where he was responsible for Program Management, Product Development, building systems to drive business growth and efficiency. Narayanan is a qualified Neuro-linguistic Programming Practitioner and has conducted multiple seminars at schools and colleges to help students improve their learning abilities through NLP techniques. He holds a Mining and Mechanical engineering degree from the College of Engineering, Guindy, Chennai.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div> 

                                    {/* <div id="teamMembers">

                                        <div id="memberContainer">
                                            <div id="memberDetailsContainer">
                                                <div id="memberImage">
                                                    <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                                </div>
                                                <div id="membersNameAndDescription">
                                                    <h3 id="memerAndDescriptionText"> Designer Name</h3>
                                                    <p id="memerAndDescriptionText">Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet<br />Loreum ipsum dolor sit amet
                                                        <br />Loreum ipsum dolor sit amet Loreum ipsum dol
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                        <div id="teamMembers" style={{ marginTop: "20px" }}>

                                            <div id="memberContainer">
                                                <div id="memberDetailsContainer">
                                                    <div id="memberImage">
                                                        <img src={require("./profile.png")} width="100%" height="100%" alt="profile" />
                                                    </div>
                                                    <div id="membersNameAndDescription">
                                                        <h3 id="memerAndDescriptionText"> Designer Name</h3>
                                                        <p id="memerAndDescriptionText">Loreum ipsum dolor sit amet Loreum ipsum dolor sit amet<br />Loreum ipsum dolor sit
                                                            amet
                                                            <br />Loreum ipsum dolor sit amet Loreum ipsum dolor sit am
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div> */}
                                </div>

                }
            </div>
            {/* <div className="WrapTheContent">
          <div className="whatWeDocontent">
            <p>
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
              >
                @Prolaskool
              </span>
              <br />
              <span
                style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
              >
                What{" "}
                <span style={{ color: "#1534E3", fontFamily: "Quicksand" }}>
                  we do
                </span>
              </span>
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "17px",
                  fontFamily: "Quicksand",
                }}
              >
                Prolaskool is a unique offering of ProlaTech, <br />
                an IT company. Prolaskool is a learning platform for
                <br />
                students to prepare for various competitive exams.{" "}
              </span>
              <br />
              <br />
              <span style={{ fontSize: "15px", fontFamily: "Quicksand" }}>
                Students have access to previous years’ question papers of all
                competitive entrance exams in one place. They can focus on
                preparing for the exams instead of spending time in browsing
                various websites, doing multiple registrations and payments, to
                access either learning materials or questions papers of previous
                years.{" "}
              </span>
            </p>
          </div>
          <div className="WhatWeDoImage">
            <img
              src={require("../../assets/images/about_us_1.png")}
              alt="xgfx"
              width="450vw"
            ></img>
          </div>
          <div className="WhatWeDoImage2">
            <img
              src={require("../../assets/images/about_us_2.jpg")}
              alt="dhghyd"
              width="570vw"
            ></img>
          </div>
        </div> */}
            <div className="AmcqueeContainer" style={{ marginBottom: "2%" }}>
                <p>
                    <span
                        style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Quicksand",
                        }}
                    >
                        @Prolaskool
                    </span>
                    <br />
                    <span
                        style={{
                            fontSize: "35px",
                            fontWeight: "bold",
                            fontFamily: "Quicksand",
                        }}
                    >
                        How Well <span style={{ color: "#1534E3" }}>We Do</span>
                    </span>
                    <br />
                    <span
                        style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            fontFamily: "Quicksand",
                            textAlign: "justify"
                        }}
                    >
                        Prolaskool&apos;s strategic vision is strongly driven by our clients&apos; needs and building long-term loyalty with them. That&apos;s why we are committed to performing better with every feedback we get from our clients. <br /><br />


                        Proloskool Mobile App was launched to meet the expectations of institutions, coaching centers, and corporates facing issues with launching their own content on a platform. Our solution surpassed the expectations by being the most trusted digital learning platform that offered various features to our clients and helped them scale their businesses and gain credibility in the market. <br /><br />


                        The Prolaskool Website Application gave a platform for aspiring candidates looking to take real-time mock tests before the main exam. They can register with our website application and access the online mock tests for various competitive exams. <br /><br />


                        We settle for nothing less than the best!
                    </span>{" "}
                </p>
            </div>

            {/* feature box */}

            {/* <div className="FeatureBoxContainer">
                <div className="FeatureBox">
                    <div className="FeatureBox1"></div>
                    <div className="FeatureBox1"></div>
                    <div className="FeatureBox1"></div>
                </div>
                
                <div className="FeatureBoxContent">
                    <div className="support14">
                    <p><span style={{fontSize:"17px", fontWeight:"bolder"}}>24/7 Support</span><br /><br />
                     <span style={{fontSize:"12px", fontWeight:"bold"}}>Lorem ipsum dolor sit amet, consect<br />
                     adipiscing elit, sed do elusmod tempor<br />
                    incididunt ut labore et dolore mango aliqua.</span>
                    </p>
                    </div>
                    <div className="support14">
                    <p><span style={{fontSize:"17px", fontWeight:"bolder"}}>24/7 Support</span><br /><br />
                     <span style={{fontSize:"12px", fontWeight:"bold"}}>Lorem ipsum dolor sit amet, consect<br />
                     adipiscing elit, sed do elusmod tempor<br />
                    incididunt ut labore et dolore mango aliqua.</span>
                    </p>
                    </div>
                    <div className="support14">
                    <p><span style={{fontSize:"17px", fontWeight:"bolder"}}>24/7 Support</span><br /><br />
                     <span style={{fontSize:"12px", fontWeight:"bold"}}>Lorem ipsum dolor sit amet, consect<br />
                     adipiscing elit, sed do elusmod tempor<br />
                    incididunt ut labore et dolore mango aliqua.</span>
                    </p>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
// }
const MainBodyAboutUs = connect(
    (state: any) => {
        return {
            isAuthed: state.users.isAuthed,
            isFetching: state.users.isFetching,
            error: state.users.error,
        };
    },
    (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(MainBodyAbout);
export { MainBodyAboutUs };
