import * as React from "react";
import { PDFExport } from '@progress/kendo-react-pdf';
// import Doc from './DocService';

const PDFContainer = (props: any) => {
  let timer = null;
  const pdfExportComponent = React.useRef(null);

  // const createPdf = (html) => Doc.createPdf(html);

  React.useEffect(() => {
    timer = setTimeout(() => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();  
      }
    }, 5000);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, []);

  return (
    <PDFExport ref={pdfExportComponent} paperSize="Letter" scale={1} fileName='test_results.pdf' margin={1}>
      <section className="pdf-container">
        <section className="pdf-body">
          {props.children}
        </section>
      </section>
    </PDFExport>
  )
}
  
export default PDFContainer;