export const SECTION_DIVISIONS = [{
    examId: 2,
    subjects: {
        Biology: [
            {
                sectionName: 'Botony Section-A',
                from: 1,
                to: 35,
                limit: null
            },
            {
                sectionName: 'Botony Section-B',
                from: 36,
                to: 50,
                limit: 10
            },
            {
                sectionName: 'Zoology Section-A',
                from: 51,
                to: 85,
                limit: null
            },
            {
                sectionName: 'Zoology Section-B',
                from: 86,
                to: 100,
                limit: 10
            }
        ],
        Chemistry: [
            {
                sectionName: 'Section-A',
                from: 1,
                to: 35,
                limit: null
            },
            {
                sectionName: 'Section-B',
                from: 36,
                to: 50,
                limit: 10
            }
        ],
        Physics: [
            {
                sectionName: 'Section-A',
                from: 1,
                to: 35,
                limit: null
            },
            {
                sectionName: 'Section-B',
                from: 36,
                to: 50,
                limit: 10
            }
        ]
    }
}]
