
import * as React from "react";
import "./login.scss";
import classNames from 'classnames';

class Dropdown extends React.Component<any> {
  public state: any = {};
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div className="aligning">
        <div className="input-container">
          <label htmlFor="inputState" className="sr-only">
            State
          </label>
          <select
            className={classNames('form-control register-input', {
              error: this.props?.errorState?.selectedState,
            })}
            name="state"
            id="stateOfStudent"
            placeholder="Country"
            value={this.props.dropdownState.selectedState}
            onChange={(e)=>this.props.selectChange(e)}
          >
            <option value="">--Choose State--</option>
            {this.props.dropdownState.countries.map((x, index) => {
            
              return <option value={x.name} key={`state_${index}`}>{x.name}</option>;
            })}
          </select>
        </div>

        <div className="input-container">
          <label htmlFor="inputState" className="sr-only">
            District
          </label>
          <select
            className={classNames('form-control register-input', {
              error: this.props?.errorState?.selectedDistrict,
            })}
            name="district"
            id="DistrictOfStudent"
            placeholder="State"
            
            value={this.props.dropdownState.selectedDistrict}
            onChange={(e) => this.props.changeSelectedDistrict(e)}
          >
            <option selected disabled={!this.props.dropdownState.selectedState} value="">
              --Choose District--
            </option>
            {this.props.dropdownState.states.map((x, index) => {
              return <option value={x} key={`district_${index}`}>{x}</option>;
            })}
          </select>
        </div>

      </div>
    );
  }
}

export { Dropdown };
