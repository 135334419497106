import * as React from 'react'
import './submition.scss'
import {
    MdNearMe
  } from "react-icons/md";
  import { base_url } from '../../config/constants';

type IComponentState = { 
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    comment: string,
    message: string,
 };  

 class IntrestSubmition extends React.Component<any, IComponentState>  {
    constructor(props: any) {
        super(props)
    
        this.state = {
             firstname:"",
             lastname:"",
             email:"",
             phone:"",
             comment:"",
             message:"",

        }
      
    }
    handleName = (e: any) =>
    {
        this.setState({
            firstname:e.target.value
        })
    }  
    handlelastname = (e: any) =>
    {
        this.setState({
            lastname:e.target.value
        })
    }    
    handleemail = (e: any) =>
    {
        this.setState({
            email:e.target.value
        })
    }  
    handlephone =(e: any) =>
    {
        this.setState({
            phone:e.target.value
        })
    }
    handlecomment = (e: any) =>
    {
        this.setState({
            comment:e.target.value
        })
    }
      submittheform= () => 
      {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: this.state.firstname, emailId:this.state.email, message:this.state.comment })
        };
        const response =  fetch(`${base_url}/api/Email/SendEmailForContactPage`, requestOptions);
        
        const data =   response;
        console.log("data",data);
        alert("Thank you for contacting us. We will get back to you shortly");
        this.setState({
            message:"Successfully submitted"
        })

        
    }
    
    render() {
        return (
            <div className="sendConatiner">
                <p className="contentforcallusTop" style={{fontFamily:"Quicksand"}}>@Prolaskool</p>
                <h1 className="Headderstyle" style={{fontFamily:"Quicksand"}}>Book A Demo Now!</h1>
                <p className="contentstyle" style={{fontFamily:"Quicksand"}}>Please fill out the form to contact us for any queries about our products and services.
                 We promise to get back to you as soon as possible!</p>
                <form onSubmit={this.submittheform}>
            
                    <div  className="loginContainer">
                    <label style={{fontFamily:"Quicksand"}}>Name</label>
                    <input className="inputcontainersubmition  createModal"  name="firstname" placeholder="Enter your name" value={this.state.firstname} onChange={this.handleName} type="text"></input>
                    </div>
                   
                    <div className="loginContainer">
                    <label style={{fontFamily:"Quicksand"}}>E-mail Id</label>
                    <input className="inputcontainersubmition createModal" name="email" value={this.state.email} placeholder="Enter your email" onChange={this.handleemail} type="email"></input>
                    </div>
    
                    <div className="loginContainer">
                    <label style={{fontFamily:"Quicksand"}}>Message</label>
                    <textarea className="inputcontainersubmition createModal"  name="comment" value={this.state.comment}  placeholder="Enter your message" onChange={this.handlecomment}></textarea>
                    </div>
                    <div className="loginContainer">
                    <p className="errormesaage" style={{fontFamily:"Quicksand"}}>{this.state.message}</p>
                    </div>
                    <div className="buttonsend">
                    
                  <button className={`buttonsize  ${
                      this.state.firstname === "" ||
             
                      this.state.email === "" ||
                      this.state.comment === "" ||
              
                    !this.state.email.match(/^[a-zA-Z0-9._:$!%-+]+@+[a-zA-Z0-9.-]+.+[a-zA-Z]$/)
                      ? "disabled"
                      : ""
                  }`}
                  type="submit"
                  disabled={
                    this.state.firstname === "" ||
      
                    this.state.email === "" ||

                    this.state.comment === "" ||
                       !this.state.email.match(/^[a-zA-Z0-9._:$!%-+]+@+[a-zA-Z0-9.-]+.+[a-zA-Z]$/)
                    ? true
                    : false
                  } > Send <MdNearMe/></button>
                    </div>
       
                </form>
   
                    <div style={{marginBottom:"6%"}}>
                    <h3 className="Headderstyle" style={{fontFamily:"Quicksand"}}>Contact Info</h3>
                    <br></br>
                    <h6 style={{fontFamily:"Quicksand", fontWeight:"bold"}}>Toll-Free Number</h6>
                    <p><a href="tel:18008890734">18008890734</a></p>
                    <p style={{fontFamily:"Quicksand", fontWeight:"bold"}}>Address</p> 
                    <p style={{fontFamily:"Quicksand" , marginBottom:"5px"}}> #7, 3rd floor, 1st Phase, 12th Cross Road Ideal Homes Township, Near Global Village Tech Park, Rajarajeshwari Nagar, Bengaluru, Karnataka 560098</p><br/><br/>
                    </div>
                </div>
            
        )
    }
}
export  {IntrestSubmition}

