import * as React from 'react'
// import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './Testimonials.scss'
// import {IntrestSubmition} from '../OnlineMockupMobileAppComponents/submitionform'
import * as userActionCreators from "../../redux/users/users.actions";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import {
//   MdChevronLeft,
//   MdChevronRight
// } from "react-icons/md";
//import { parseClassName } from 'react-toastify/dist/utils';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  }
};

// const arrowStyle = {
//   background: "transparent",
//   border: 0,
//   color: "#000",
//   fontSize: "80px",
//   display: 'flex'
// };

// const CustomRight = (props: any) => (
//   <button type="button" aria-label="Go to next slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right arrow right" onClick={props?.onClick} style={arrowStyle}>
//     <MdChevronRight style={{ fontSize: "50px" }} />
//   </button>
// );

// const CustomLeft = (props: any) => (
//   <button aria-label="Go to previous slide" className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left arrow left" type="button" onClick={props?.onClick} style={arrowStyle}>
//     <MdChevronLeft style={{ fontSize: "50px" }} />
//   </button>
// );
// import './main.scss'
class MainTestimonial extends React.Component {
  render() {
    const imgWidth = "85%";
    return (
      // <div className="mainBodyContent">
      <div id="mainbod" style={{
        // backgroundColor: "whitesmoke",
        padding: "0 10%" 
      }}>
        <div id="firstDiv">
          <div id="clientSays">
            <div id="clientSaysTextDiv">
              <h3><u>Our Clients Our Priority - Know What They Say About Us</u></h3>
              <p style={{ color: "black", fontSize: "18px", width: "80%" }}>
              We focus on delivering client satisfaction, and that reflects in their kind words. Have a look at what our clients have to say about Prolaskool. 
              </p>
            </div>
          </div>
          {/* <div id="testimonialFirstImage" > */}
          <div id="testimonialFirstImageContainer">
            <img id="testimonialFirstImage" src={require("./WhatClientsSaysAboutUs.png").default} alt="img" />
            {/* </div> */}
          </div>
        </div>
        <div>
          <div style={{ marginTop: "50px" }}>
            <p style={{ textAlign: "center", fontSize: "18px", fontFamily: "Quicksand" }}>India&apos;s leading digital learning app, <br/>trusted by institutions and corporates across the world.</p>
          </div>
          <div style={{ marginBottom: "40px" }}>

{/* Pareeksha Manch Start */}
            {/* <div id="instituteLogo" style={{}}>
              <img src={require("./pareeksha manch logo.jpg").default} width="120%" height="140%" alt="logo" />
            </div>

            <Carousel
              swipeable={true}
              draggable={true}
              showDots
              responsive={responsive}
              centerMode={false}
              // arrows
              additionalTransfrom={10}
              infinite={true}
              autoPlay={true}
              keyBoardControl
              autoPlaySpeed={1000}
              transitionDuration={1000}
              containerClass="carousel-container-1"
              minimumTouchDrag={80}
              // renderButtonGroupOutside={false}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              // customRightArrow={<CustomRight />}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              // customLeftArrow={<CustomLeft />}
            >

              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./kunalsinha.jpg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}>Pareeksha Manch, Pune </div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Kunal Sinha</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Director</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>We were looking for a reliable, stable, and affordable platform to launch our competitive courses online. After trying multiple apps, we learned about Prolaskool. The Prokaskool app has an easy-to-operate interface and features like scheduling MCQs, getting extensive performance reports, and launching courses in institute-owned brand names. Prolaskook is not only affordable but also a practical solution. We love the platform.   </p>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="carousel-item active">
              <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./rameshchandra.jpg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}> Pareeksha Manch, Pune</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Ramesh Chandra</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Faculty</div> 
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>Teaching online is challenging, especially when you don&apos;t have the right platform to use. Kudos to the team of Prolaskool for developing the excellent platform to teach online, prepare test schedules, and track students’ progress reports. They have created a compelling GUI flow for better communication with students. It really made our work seamless and effective using the mobile app and desktop version.  </p>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90" id="bhati" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./pareeksha Student.jpeg").default} width="100%" height="95%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}> Pareeksha Manch, Pune</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Tanisha Bhati</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Student</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>I was preparing for the NEET exam when I came across this platform. I was amazed by the seamless and straightforward structure of the app. I prepared using video lessons, mock tests, preparation tests, and previous years&apos; exam MCQs. I also liked the offline mode option that automatically synced up my learning contents. The app also gave access to detailed performance reports that helped me prepare better in different subjects. I’m happy to use this app anytime! </p>
                  </div>
                </div>
              </div>
            </Carousel> */}
{/* Pareeksha Manch END */}
            <div id="instituteLogo" style={{}}>
            <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto"}} src={require("./AkshayaEducationLogo.png").default} />
            </div>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots
              responsive={responsive}
              centerMode={false}
              arrows
              additionalTransfrom={10}
              infinite={true}
              autoPlay={true}
              keyBoardControl
              autoPlaySpeed={1000}
              transitionDuration={1000}
              containerClass="carousel-container-1"
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              // customRightArrow={<CustomRight />}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              // customLeftArrow={<CustomLeft />}
            >
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90 instihead" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./AkshayaEducation_Thanmay.jpeg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Thanmay</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Student</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>
                    I had trouble getting online because I reside in a distant area. I&apos;m grateful for the Prolaskool platform, which provided me with access to offline learning resources, allowed me to bookmark the MCQs while I studied for the examinations and provided me with performance reports that allowed me to assess my strengths and shortcomings.
                    </p>
                  </div>
                </div>
              </div>
             
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./AkshayaEducation_AnilKumar.png").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>N Anil kumar</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Institute Head</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>
                    We were looking for an economical, dependable venue to introduce our challenging courses online. After experimenting with several apps, we discovered Prolaskool. The Prokaskool app provides an intuitive user interface and functions like scheduling multiple-choice questions, obtaining thorough performance data, and starting courses under institute-owned brand names. Prolaskook is not only an inexpensive but also a useful remedy. The platform is fantastic.   </p>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./AkshayaEducation_LalithDevi.png").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}>Akshaya Educational Foundation</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Lalith Devi</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Faculty</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>
                    The Prolaskool platform&apos;s user-friendly design and reliable app performance are what we appreciate most about it. We have the freedom to assign pupils unit assessments based on their subject-matter expertise to bring out the best in them thanks to the mobile app. Even when they are offline, our students can access recorded films and educational materials. This portal must be used by all students preparing for competitive exams.  
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>

            <div id="instituteLogo" style={{}}>
            <img className="InstituteLogo" style={{ display: "block", marginLeft: "auto", marginRight: "auto", width:'92%'}} src={require("./India 4 IAS ENG.png").default} />
            </div>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots
              responsive={responsive}
              centerMode={false}
              arrows
              additionalTransfrom={10}
              infinite={true}
              autoPlay={true}
              keyBoardControl
              autoPlaySpeed={1000}
              transitionDuration={1000}
              containerClass="carousel-container-1"
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              // customRightArrow={<CustomRight />}
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              // customLeftArrow={<CustomLeft />}
            >
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90 instihead" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./India4_Sandeep.jpg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}> India 4 IAS </div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Sandeep Babu</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Institute Head</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>Our institution had unique needs, and we wanted to provide the best for our students and faculty. We made the right choice of choosing Prolaskool, which is affordable and has intuitive features like offline mode, separate access for students, parents, and faculty, customization, and more. Thank you, Prolaskool, for your best-in-class platform.   </p>
                  </div>
                </div>
              </div>
              {/* <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "250px", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div
                    id='carouselItemContainer' 
                    style={{ display: "flex", flexDirection: "column" }}> */}
                    {/* <div style={{ width:"80px",height:"80px",borderRadius:"40px",backgroundColor:"lightgrey",marginTop:"30px"}}></div> */}
                    {/* <div style={{ textAlign: "center", marginBottom: "10px" }}> IAS coaching center India For IAS</div>
                    <div style={{ width: "80%", height: "200px", backgroundColor: "lightgrey", marginLeft: "auto", marginRight: "auto" }}> */}
                      {/* <video controls width="100%" height="100%">
           <source
            src={require("../../../public/Videos/WIN_20220519_12_07_25_Pro.mp4").default}  type="video/mp4"/>

          </video> */}
                      {/* <iframe width="100%" height="100%" title="embedsPage" className="embed-responsive-item" src="https://player.vimeo.com/video/137857207"
        ></iframe> */}
                      {/* <video >
        <source
          src="https://www.youtube.com/watch?v=PlxWf493en4&ab_channel=freeCodeCamp.org"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
                      {/* <iframe width="100%" height="100%"
                        src="https://www.youtube.com/embed?v=eR-gpNyb0qU&list=RDeR-gpNyb0qU&start_radio=1" allowFullScreen>
                      </iframe>
                    </div>
                  </div>
                </div>
              </div> */}
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "60px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./India4_Rajeev.jpeg").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}> India 4 IAS</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Rajeev Patel</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Faculty</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>What we love the most about the Prolaskool platform is its easy-to-use interface and the stable performance of the app. The mobile app gives us the flexibility to schedule unit tests for students based on their expertise to bring out the best in them. Our students can access recorded videos and learning materials even offline. All students preparing for competitive exams must try this platform.  </p>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px" }} className="carousel-item active">
                <div className="d-block w-90" style={{ height: "auto", backgroundColor: "white", width: imgWidth, borderRadius: "20px", boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" }}  >
                  <div id='carouselItemContainer' style={{}}>
                    <div id='testimonialProfilePicContainer' >
                      <img src={require("./profile.png").default} width="100%" height="100%" style={{ borderRadius: "50%" }} alt="profile" />
                    </div>
                    <div id="InstNameAndDesc">
                      <div style={{ textAlign: "center", marginBottom: "10px" }}> India 4 IAS</div>
                      <div style={{ textAlign: "center", marginBottom: "10px", fontSize: "12px" }}>Student</div>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <p id='instituteTestimonialContent'>I live in a remote place where access to the internet was challenging. Thanks to the Prolaskool platform that gave me access to offline learning materials, bookmarking the MCQs while preparing for the exams, and get the performance reports to evaluate my strengths and weaknesses.</p>
                  </div>
                </div>
              </div>
            </Carousel>



          </div>
        </div>
      </div>


      // </div>

    )
  }
}
const MainTestimo = connect(
  (state: any) => {
    return {
      isAuthed: state.users.isAuthed,
      isFetching: state.users.isFetching,
      error: state.users.error,
    };
  },
  (dispatch) => bindActionCreators(userActionCreators, dispatch)
)(MainTestimonial);
export { MainTestimo }