export const errors = {
    login: {
        400: {msg: 'Invalid Email Id or Password', type: 'error'},
        403: {msg: 'Invalid Email Id or Password', type: 'error'},
        500: {msg: 'Something went wrong. Please try again after some time', type: 'error'},
        200: {msg: 'Login successfull', type: 'success'},
    },
    register: {
        400: {msg: 'Invalid Email Id or Password', type: 'error'},
        200: {msg: 'This Email Id is already in use', type: 'error'},
        500: {msg: 'Something went wrong. Please try again after some time', type: 'error'}
    },
    resetPassword: {
        400: {msg: 'Something went wrong. Please log out and log back in.', type: 'error'},
        403: {msg: 'Session expired.Please login again.', type: 'error'},
        500: {msg: 'Something went wrong. Please try again after some time', type: 'error'},
        200: {msg: 'Password reset successful', type: 'success'}
    },
    forgotPassword: {
        400: {msg: 'Invalid Email Id.', type: 'error'},
        500: {msg: 'Something went wrong. Please try again after some time', type: 'error'},
        200: {msg: 'New password sent to your registered email id.', type: 'success'}
    },
    uploadImg: {
        400: {msg: 'Error while uploading profile image', type: 'error'},
        500: {msg: 'Something went wrong. Please try again after some time', type: 'error'},
        200: {msg: 'Profile image uploaded successfull', type: 'success'}
    },
    dashboard: {
        403: {msg: 'Session expired. Please login again', type: 'error'},
        500: {msg: 'Error while fetching data', type: 'error'}
    },
    testAPIDetails: {
        403: {msg: 'Session expired. Please login again', type: 'error'},
        500: {msg: 'Error while fetching data', type: 'error'}
    },
    startPageData: {
        400: {msg: 'Error while fetching data', type: 'error'},
        403: {msg: 'Session expired. Please login again', type: 'error'},
        500: {msg: 'Error while fetching data', type: 'error'}
    },
    resume: {
        400: {msg: 'Error while fetching data', type: 'error'},
        403: {msg: 'Session expired. Please login again', type: 'error'},
        500: {msg: 'Error while fetching data', type: 'error'}
    },
    testQuestions: {
        400: {msg: 'Error while fetching data', type: 'error'},
        403: {msg: 'Session expired. Please login again', type: 'error'},
        500: {msg: 'Error while fetching data', type: 'error'}
    },
    userResponse: {
        500: {msg: 'Error while fetching data', type: 'error'}
    }
}
